import axios from "axios";
import { SET_ERROR } from "./errorReducer";
import Swal from "sweetalert2";
import { SubmissionError } from "redux-form";
import { objToQuery } from "../util/lib";
import moment from "moment-timezone";
export const reducerName = "programadorReducer";
const INITIAL_STATE = localStorage[reducerName] ?
    (() => {
        const x = JSON.parse(localStorage[reducerName])
        x.fechaInicio = new Date(x.fechaInicio);
        x.fechaInicio.setYear(2024)
        x.fechaFin = new Date(x.fechaFin);
        x.fechaFin.setYear(2024)
        x.fechaProximoRiego= new Date(x.fechaProximoRiego);
        x.fechaUltimoRiego= new Date(x.fechaUltimoRiego);
        x.fechaProximoRiego.setYear(2024);
        x.fechaUltimoRiego.setYear(2024);
        return x;
    })() : {
        
        resultado: {}

    };

const apiUrl = "/services/servicioshidricos/api/public";

function actualizarResultado(state) {
    //SE GENERA LOS 366 DIAS INICIALIZADO TODO CON ZERO
    state.resultado={diasEnDeficit:0}; //Se reinicia el resultado, o se inicia en el caso de que haber un estado guaradado sin el atributo resultado
    const datosXDia = []    
    let date= new Date("2024-01-01T12:00:00.00Z");   
   
    while (date.getFullYear()<2025){
        const fechaSimple = moment(date).format('MM-DD'); 
        datosXDia.push({fechaSimple:fechaSimple, fecha:date,etc:0,kc:0,precipitacion:0,eto:0,nN:0, nBSP:0,perdida:0,laminaDisponible:0})
        date=new Date(date*1+24*60*60*1000)
    }

    
    //SE OBTIENE LOS DIAS DEL PERIODO
    //Se multiplica por 0,6 la fecha superior, porque los dias se han inicializado  con hora igual a 12:00
    var {fechaInicio:fi,fechaFin:fn}=state;
    var filtrar = elem => {
        if (fi > fn){
            const r=!(new Date(fn) < new Date(elem.fecha) && new Date(elem.fecha) < new Date(new Date(fi)*1 - 0.6*24 * 60 * 60 * 1000));
            return r;
        }
        else {
            return new Date(new Date(fi)) < new Date(elem.fecha) && elem.fecha*1 < new Date(fn)*1 + 0.6*24 * 60 * 60 * 1000;
        }
    }
    state.resultado.datosXDiaPeriodo=datosXDia.filter(filtrar);
    const datosXDiaPeriodo=state.resultado.datosXDiaPeriodo;
  
    //Se obtienen los dias del periodo pero de la fase 2
    var {fechaUltimoRiego:fi, fechaProximoRiego:fn}=state;
    state.resultado.datosXDiaPeriodoEntreRiegos=datosXDia.filter(filtrar);

    //SE CALCULA TODO PARA TODOS LOS DIAS
    const total = {
        tipoAnio:0, 
        fechaSimple:0, 
        nN: 0, 
        etc: 0, 
        precipitacion: 0, 
        eto: 0, 
        //usos con preciptiacion
        totalDefensaHelada:0,
        totalPrepTerre:0, 
        totalLexiviacion:0, 
        totalLavadoSuelo:0, 
        //usos sin precipitacion
        totalDefensaHeladaSP:0, 
        totalPrepTerreSP:0, 
        totalLexiviacionSP:0, 
        totalLavadoSueloSP:0, 
        nB: 0,
        nBSP:0,
        perdidaSP:0,
        perdida:0,
        perdidaLixiviacionSP:0,
        perdidaLixiviacion:0,
    };//TOTAL POR AÑO
    const totalPeriodo = {...total};//TOTAL POR PERIODO
    const precipitaciones = state.precipitaciones
    const kcs = state.kcs?.sort((a,b)=>new Date(a.fecha)-new Date(b.fecha))
    
    for (const index in datosXDia) {
        
        if(state.estacionesMeteorologicas) for (const estacionIndex in state.estacionesMeteorologicas) {
            const etoEstacion = state.estacionesMeteorologicas[estacionIndex].lstDatosEstacionMeteorologica;
            datosXDia[index].eto += etoEstacion[index].eto * state.estacionesMeteorologicas[estacionIndex].proporcion / 100;
        }
        if (kcs){
            datosXDia[index].kc=kcs[index].kc;
            datosXDia[index].kcObj=kcs[index];
        }
        if (kcs && state.estacionesMeteorologicas) {
            datosXDia[index].etc = datosXDia[index].eto * kcs[index].kc
        }
        if (precipitaciones && precipitaciones[index]) {            
            datosXDia[index].precipitacion = precipitaciones[index].precipitacion * 0.8
        }
        if (precipitaciones && precipitaciones[index] && kcs && state.estacionesMeteorologicas){ 

            datosXDia[index].nN =(datosXDia[index].etc -datosXDia[index].precipitacion)
            datosXDia[index].nBSP = datosXDia[index].etc / state.eficiencia; 
            if (datosXDia[index].nN<0){
                datosXDia[index].nN=0;
                datosXDia[index].nB = datosXDia[index].nN;
            }
            else {
                datosXDia[index].nB = (datosXDia[index].etc - datosXDia[index].precipitacion) / state.eficiencia  
            }
            datosXDia[index].tipoAnio = state.precipitaciones.tipoAnio; 
            datosXDia[index].perdida = datosXDia[index].nB - datosXDia[index].nN 
            datosXDia[index].perdidaSP =    datosXDia[index].nBSP - datosXDia[index].etc;      
        }
    }
    

    //dias entre riegos
    if (state.tipoSuelo && state.estacionesMeteorologicas && kcs && precipitaciones){
    let laminaDisonible;
    state.resultado.humedadUtil=state.tipoSuelo?.densidad*10*state.kcs[0].profundidadRadicular*state.proporcionSueloMojado/100;


    state.resultado.A=state.tipoSuelo.wc*state.tipoSuelo.pea*10*state.proporcionSueloMojado/100*state.kcs[0].profundidadRadicular;
    state.resultado.B=state.tipoSuelo.wm*state.tipoSuelo.pea*10*state.proporcionSueloMojado/100*state.kcs[0].profundidadRadicular;
    state.resultado.C=state.resultado.B +( state.resultado.A- state.resultado.B)*(1-state.umbralRiego / 100)
    state.resultado.laminaEntreURyPMP=state.resultado.A-state.resultado.B - (state.resultado.A - state.resultado.C);
    const calcDiaEntreRiegos=(index,day)=>{
            
            const dia=new Date(day.fecha);
            dia.setHours(0,0,0,0);
            const inicio=new Date(state.fechaUltimoRiego);
            inicio.setHours(0,0,0,0);
            const fin= new Date(state.fechaProximoRiego);
            fin.setHours(0,0,0,0);
            day.A=state.tipoSuelo.wc*state.tipoSuelo.pea*10*state.proporcionSueloMojado/100*state.kcs[0].profundidadRadicular;
            day.B=state.tipoSuelo.wm*state.tipoSuelo.pea*10*state.proporcionSueloMojado/100*state.kcs[0].profundidadRadicular;
            day.C=day.B +( day.A- day.B)*(1-state.umbralRiego / 100)
            day.laminaEntreURyPMP=day.A-day.B - (day.A - day.C);
            if (!laminaDisonible){ 
                laminaDisonible=state.resultado.humedadUtil
                day.laminaDisponible=laminaDisonible;
                if (day.laminaDisponible<day.laminaEntreURyPMP)state.resultado.diasEnDeficit++
            }
            else {
                laminaDisonible+= - day.etc + day.precipitacion
                day.laminaDisponible=laminaDisonible;
                if (day.laminaDisponible<day.laminaEntreURyPMP)state.resultado.diasEnDeficit++
            }
            
    }
        if (state.fechaUltimoRiego>state.fechaProximoRiego) {
            const array=state.resultado.datosXDiaPeriodoEntreRiegos;
            const days=Math.round((new Date("2025-01-01")- state.fechaUltimoRiego)/1000/60/60/24)
            array.unshift(...array.splice(array.length-days-1,days+1))
        }
        for (let i=0;i<state.resultado.datosXDiaPeriodoEntreRiegos.length;i++){
            calcDiaEntreRiegos(i,state.resultado.datosXDiaPeriodoEntreRiegos[i])
        }
    }


    
    let listaOrdenadaXDia = []
    listaOrdenadaXDia = datosXDia.filter(li => new Date(new Date(li.fecha)).getMonth() >= 7 && new Date(new Date(li.fecha)).getMonth() <= 12);
    listaOrdenadaXDia = listaOrdenadaXDia.concat(datosXDia.filter(li => new Date(new Date(li.fecha)).getMonth() >=  0  && new Date(new Date(li.fecha)).getMonth() <= 6));
  
    state.resultado.totalAnual = total
    state.resultado.datosXDia = listaOrdenadaXDia
    state.resultado.totalPeriodo=totalPeriodo;
    
    //USOS CULTURALES
     total.tipoAnio = state.precipitaciones?.tipoAnio;
     

     
    //SE AGRUPA LOS DIAS EN MESES
    
    const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    function reducerMes(r, a) {        
        const mes = new Date(new Date(a.fecha)).getMonth()       
        r[mes] = r[mes] || { perdida:0, etc:0,kc:0,precipitacion:0,eto:0,nB:0, nN:0, nBSP:0, tipoAnio:0, perdidaSP:0 }
        r[mes].kc = a.kc
        r[mes].mes = monthNames[mes]
        r[mes].fechaSimple = monthNames[mes]
        r[mes].precipitacion+=a.precipitacion;
        r[mes].tipoAnio+=a.tipoAnio;        
        r[mes].eto+=a.eto;
        r[mes].etc+=a.etc;
        r[mes].nB+=a.nB;
        r[mes].nBSP+=a.nBSP;
        r[mes].nN+=a.nN;
        r[mes].mesNum = mes;
        r[mes].perdida += a.perdida;
        r[mes].perdidaSP += a.perdidaSP;
        return r;     
           
    }
    const result = datosXDia?.reduce(reducerMes,[]);
    const resultPeriodoMes = datosXDiaPeriodo?.reduce(reducerMes,[]);
    
    
    
    //CALCULO DE USOS MENSUALES

    const usoCulturalDefensaHelada=state.usosCulturales?.find(e=>e.usoCultural.nombre.toLowerCase().includes("lucha"));
    const usoCulturalPrepTerre=state.usosCulturales?.find(e=>e.usoCultural.nombre.toLowerCase().includes("prepara"));
    const usoCulturalLexiviacion=state.usosCulturales?.find(e=>e.usoCultural.nombre.toLowerCase().includes("lixi"));
    const usoCulturalLavado=state.usosCulturales?.find(e=>e.usoCultural.nombre.toLowerCase().includes("lavado")); 
    function CalculoUsoMensual(objMes){
        const mes=objMes.mesNum;
        // ---- 
        let laminaDefensaHeladaSP=0;
        let laminaDefensaHelada=0;
        if (usoCulturalDefensaHelada && objMes.kc>0){
           
            //Sin Precipitacion
            laminaDefensaHeladaSP =  laminaDefensaHelada = (usoCulturalDefensaHelada["riegosMes"+mes] || 0 )*(usoCulturalDefensaHelada["laminaMes"+mes] || 0);    
            if (laminaDefensaHeladaSP < objMes.nBSP ){
                laminaDefensaHeladaSP=0;
            }
            else {
                laminaDefensaHeladaSP-=objMes.nBSP ;
            }
            //Con precipitacion
            if (laminaDefensaHelada< objMes.nB ){
                laminaDefensaHelada = 0;
            }
            else {
                laminaDefensaHelada-=objMes.nB ;
            }
        }
        objMes.totalDefensaHelada = laminaDefensaHelada;
        objMes.totalDefensaHeladaSP = laminaDefensaHeladaSP;

        // ---- 
        let  laminaLavadoSuelo=0;
        let laminaLavadoSueloSP=0;
        if (usoCulturalLavado && usoCulturalLavado.mes == mes + 1){
            laminaLavadoSueloSP = laminaLavadoSuelo= Math.round((1/(5*usoCulturalLavado.conductividadFinal/usoCulturalLavado.conductividadInicial)+0.15)*kcs[0]?.profundidadRadicular*1000)*10/10;
            //Con precipitacion
            if (laminaLavadoSuelo < objMes.nB + laminaDefensaHelada){
                laminaLavadoSuelo=0;
            }
            else {
                laminaLavadoSuelo= laminaLavadoSuelo- objMes.nB - laminaDefensaHelada;
            }
            //Sin precipitacion
            if (laminaLavadoSueloSP < objMes.nBSP + laminaDefensaHeladaSP){
                laminaLavadoSueloSP=0;
            }
            else {
                laminaLavadoSueloSP= laminaLavadoSueloSP- objMes.nBSP - laminaDefensaHeladaSP;
            }
        }   
        objMes.totalLavadoSuelo = laminaLavadoSuelo;
        objMes.totalLavadoSueloSP = laminaLavadoSueloSP;

        // ---- 
        let laminaPreperacionTerreno=0;
        let laminaPreperacionTerrenoSP=0;
        if (usoCulturalPrepTerre && usoCulturalPrepTerre.mes == mes + 1){
            laminaPreperacionTerreno=laminaPreperacionTerrenoSP=usoCulturalPrepTerre.numeroRiegoXMes*usoCulturalPrepTerre.lamina; 
            //Con precipitacion
            if (laminaPreperacionTerreno < objMes.nB + laminaDefensaHelada + laminaLavadoSuelo){
                laminaPreperacionTerreno=0;
            }
            else {
                laminaPreperacionTerreno= laminaPreperacionTerreno- objMes.nB - laminaDefensaHelada - laminaLavadoSuelo;
            }   
            //Sin precipitacion
            if (laminaPreperacionTerrenoSP < objMes.nBSP + laminaDefensaHeladaSP + laminaLavadoSueloSP){
                laminaPreperacionTerrenoSP=0;
            }
            else {
                laminaPreperacionTerrenoSP= laminaPreperacionTerrenoSP- objMes.nBSP - laminaDefensaHeladaSP - laminaLavadoSueloSP;
            }        
        }
        objMes.totalPrepTerre = laminaPreperacionTerreno;
        objMes.totalPrepTerreSP = laminaPreperacionTerrenoSP;

        
        let  lixiviacionSP=0;
        let lixiviacion=0;
        if (usoCulturalLexiviacion){
            const porcentajeLixiviacion=0.3086/(usoCulturalLexiviacion.conductividadInicial/usoCulturalLexiviacion.conductividadFinal)**1.702;
            
           
            lixiviacionSP=objMes.etc*porcentajeLixiviacion ;
            if (lixiviacionSP<0){
                lixiviacionSP=0;
            }   
            
            lixiviacion=(objMes.etc-objMes.precipitacion)*porcentajeLixiviacion;
            if (lixiviacion<0){
                lixiviacion=0;
            }  
        }
        objMes.totalLexiviacion = lixiviacion;
        objMes.totalLexiviacionSP =lixiviacionSP;

        objMes.perdidaLixiviacionSP= objMes.perdidaSP - lixiviacionSP;
        if (objMes.perdidaLixiviacionSP<0){
            objMes.perdidaLixiviacionSP=0;
        }
        objMes.perdidaLixiviacion= objMes.perdida - lixiviacion;
        if (objMes.perdidaLixiviacion<0){
            objMes.perdidaLixiviacion=0;
        }
        


        
    }
    //AGRUPAMIENTO TOTAL
    const agrupamientoTotal=(total,mes)=>{
        total.nB+=mes.nB;
        total.nN+=mes.nN;
        total.perdida+=mes.perdida || 0
        total.totalDefensaHelada+=mes.totalDefensaHelada;
        total.totalDefensaHeladaSP+=mes.totalDefensaHeladaSP;

        total.totalLavadoSuelo+=mes.totalLavadoSuelo;
        total.totalLavadoSueloSP+=mes.totalLavadoSueloSP;

        total.totalLexiviacionSP+=mes.totalLexiviacionSP;
        total.totalLexiviacion+=mes.totalLexiviacion;

        total.totalPrepTerre+=mes.totalPrepTerre;
        total.totalPrepTerreSP+=mes.totalPrepTerreSP;

        total.nBSP+=mes.nBSP;
        total.precipitacion+=mes.precipitacion;
        total.perdidaSP+=mes.perdidaSP;
        total.perdidaLixiviacionSP+=mes.perdidaLixiviacionSP;
        total.perdidaLixiviacion+=mes.perdidaLixiviacion;
        total.etc+=mes.etc;
        total.eto+=mes.eto;
    }
    resultPeriodoMes.forEach((mes)=>{
        CalculoUsoMensual(mes);
        agrupamientoTotal(totalPeriodo,mes)
    })
    result.forEach((mes)=>{
        CalculoUsoMensual(mes);
        agrupamientoTotal(total,mes)
    })

    state.resultado.totalPeriodoEntreRiegos={nB:0,nN:0,etc:0};


    for (const e of state.resultado.datosXDiaPeriodoEntreRiegos){
        state.resultado.totalPeriodoEntreRiegos.nB+=e.nB;
        state.resultado.totalPeriodoEntreRiegos.nN+=e.nN;
        state.resultado.totalPeriodoEntreRiegos.etc+=e.etc;
    }

    //REORDEN DE MESES
    let listaOrdenada = []
    listaOrdenada = result.filter(li => li.mesNum >= 7 && li.mesNum <= 12);
    listaOrdenada = listaOrdenada.concat(result.filter(li => li.mesNum >=  0  && li.mesNum <= 6));
    state.resultado.datosXMes=listaOrdenada;
   
    let listaOrdenadaMes = []
    listaOrdenadaMes = resultPeriodoMes.filter(li => li.mesNum >= 7 && li.mesNum <= 12);
    listaOrdenadaMes = listaOrdenadaMes.concat(resultPeriodoMes.filter(li => li.mesNum >=  0  && li.mesNum <= 6));
    state.resultado.datosXMesPeriodo=listaOrdenadaMes;


    //Redondear 
    const redondear= (objeto)=> {
        for (const key in objeto){
            if (typeof objeto[key]=="number"){
                objeto[key]=Math.round(objeto[key]);
            }
        }
    }
    const redondearDos= (objeto)=> {
        for (const key in objeto){
            if (typeof objeto[key]=="number"){
                objeto[key]=Math.round((objeto[key])* 100) / 100;
            }
        }
    }
    redondear(total);
    redondear(totalPeriodo);
    state.resultado.datosXMes.forEach(redondear);
    state.resultado.datosXMesPeriodo.forEach(redondear);
    state.resultado.datosXDia.forEach(redondearDos);
    state.resultado.datosXDiaPeriodo.forEach(redondearDos);

    //PONER NUEVAS VARIABLES DE PERIODO
    state.resultado.datosXDiaPeriodo.forEach((e)=>{
        e.periodoEto=e.eto
        e.periodoEtc=e.etc
        e.periodoPrecipitacion= e.precipitacion
    })
    state.resultado.datosXMesPeriodo.forEach((e)=>{
            const mes = state.resultado.datosXMes.find((elem)=>elem.mesNum == e.mesNum)
            mes.periodoEto=e.eto
            mes.periodoEtc=e.etc
            mes.periodoPrecipitacion= e.precipitacion
    })
    console.log(state)
}
actualizarResultado(INITIAL_STATE)

export default function (state = INITIAL_STATE, action) {
    if (reducerName != action.type) return state;
    const newstate = action.fn(state, action);
    const aux=newstate || state;
    if (aux.tipoRiego?.id==5 && aux.usosCulturales){//Tipo de reigo igual goteo entonces  se saca el uso cultural defensa helada
        aux.usosCulturales=aux.usosCulturales.filter(e=> e.usoCultural.id!=1)//que no sea defensa helada
        console.log(state.usosCulturales)
    }
    if (newstate) {
        localStorage.setItem(reducerName, JSON.stringify(newstate))
        actualizarResultado(newstate)
        return newstate;
    }
    !action.noUpdate && actualizarResultado(state)
    return { ...state };
}
export const setClean = () => async (dispatch) => {
    window.localStorage.clear();
    dispatch({
        type: reducerName,
       
        fn: () => ({
                       
        })
    })   
}
export const setUbicacion = (ubicacion) => async (dispatch) => {
    dispatch({
        type: reducerName,
        payload: ubicacion,
        fn: (state, action) => ({
            ...state,
            tipoDeUbicacion: action.payload
        })
    })
}
export const setDepartamento = (departamento) => async (dispatch) => {
    dispatch({
        type: reducerName,
        payload: departamento,
        fn: (state, action) => ({
            ...state,
            departamento: action.payload
        })
    })
}
export const setEstacion = (estaciones) => async (dispatch) => {
    dispatch({
        type: reducerName,
        payload: [estaciones],
        fn: (state, action) => ({
            ...state,
            estacionesMeteorologicas: action.payload
        })
    })
}
export const setCriterioUbicacion = (values) => async (dispatch) => {
    dispatch({
        type: reducerName,
        payload: values,
        fn: (state, action) => ({
            ...state,
            tipoDeUbicacion: values.tipoDeUbicacion,
            entidadUbicacion: values[values.tipoDeUbicacion?.nombre],

        })
    })
}
export const setTipoCultivo = (tipoCultivo) => async (dispatch) => {
    const obj = objToQuery({
        "cultivoId.equals": tipoCultivo.id,
        size: 400,
    })
    const { data: kcs } = await axios.get(`${apiUrl}/datos-cultivos${obj}`);
    dispatch({
        type: reducerName,
        payload: { tipoCultivo, kcs },
        fn: (state, action) => ({
            ...state,
            kcPersonalizado: false,
            ...action.payload
        })
    })
}
export const setEstacionesMeteorologicas = (values) => async (dispatch) => {
    
    const obj = objToQuery({
        "entidad": values.tipoDeUbicacion.enum.toUpperCase(),
        "id": values[values.tipoDeUbicacion?.nombre].id,
    })
    const { data: estacionesMeteorologicas } = await axios.get(`${apiUrl}/estacion-meteorologica-eto/${obj}`);
    dispatch({
        type: reducerName,
        payload: { estacionesMeteorologicas },
        fn: (state, action) => ({
            ...state,
            ...action.payload
        })
    })
    return estacionesMeteorologicas;
}
export const setFechas = (fechaInicio, fechaFin, fechaAnual) => async (dispatch) => {

    if (fechaInicio instanceof Date) {
        fechaInicio.setYear(2024)
    }

    if (fechaFin instanceof Date) {
        fechaFin.setYear(2024)
    }

    dispatch({
        type: reducerName,
        payload: { fechaInicio, fechaFin, fechaAnual },
        fn: (state, action) => ({
            ...state,
            ...action.payload
        })
    })

}

export const setTipoRiego = (tipoRiego, eficiencia) => async (dispatch) => {
    dispatch({
        type: reducerName,
        payload: { eficiencia, tipoRiego },
        fn: (state, action) => ({
            ...state,
            eficiencia: action.payload.eficiencia,
            tipoRiego: action.payload.tipoRiego,
            proporcionSueloMojado: action.payload.tipoRiego.proporcionSueloMojado*100
        })
    })
}

export const setEficiencia = (eficiencia) => async (dispatch) => {
    dispatch({
        type: reducerName,
        payload: eficiencia,
        fn: (state, action) => ({
            ...state,
            eficiencia: action.payload
        })
    })
}

export const setPrecipitacion = (tipoAnio, cuenca) => async (dispatch) => {
    let precipitaciones;
    if (tipoAnio.nombre == "Ninguno"){
        precipitaciones=[]
        let inicio=new Date("2024-01-01");
        for (let i=0;i<366;i++){
           
            precipitaciones.push({fecha:new Date(inicio*1+i*24*60*60*1000),precipitacion:0 })
        }
    }else{
    const obj = objToQuery({
        "tipoAnio.contains": tipoAnio.nombre,
        "cuencaId.equals": cuenca.id,
        size: 400,
    })
    const result= await axios.get(`${apiUrl}/precipitacion-cuencas${obj}`);
    precipitaciones=result.data;
    }
    dispatch({
        type: reducerName,
        payload: { precipitaciones },
        fn: (state, action) => ({
            ...state,
            ...action.payload
        })
    })
}
export const setUsoCultural = (values) => async (dispatch) => {
    dispatch({
        type: reducerName,
        payload: values.usosCulturales,
        fn: (state, action) => ({
            ...state,
            usosCulturales: action.payload
        })
    })
}
export const cleanLocalStroage = () => async (dispatch) => {
    localStorage.clear();
    dispatch({
        type: reducerName,
        fn: (state, action) => ({
          
        })
    })
}
 
export const setInspeccion = (values) => async (dispatch) => {
    dispatch({
        type: reducerName,
        payload: values.inspeccion,
        fn: (state, action) => ({
            ...state,
            inspeccion: action.payload
        })
    })
}
export const setTipoCultivoPersonalizado = (kcs, profundidadRadicular) => async (dispatch) => {
    
    dispatch({
        type: reducerName,
        
        fn: (state, action) => ({
            ...state,
            tipoCultivo: {
                nombre: "Personalizado",
                profundidadRadicular,
            },
            kcPersonalizado: true,
            kcs:state.estacionesMeteorologicas[0].lstDatosEstacionMeteorologica.map((elem)=>{
                const mes=kcs.find((e)=>e.mes.codigo-1 == new Date(elem.fecha).getUTCMonth())
                return{
                    kc:mes?.kc || 0,
                    fecha:elem.fecha,
                    profundidadRadicular
                }
            })
        })
    })
}



