import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Modal} from 'react-bootstrap'
import Form, { Field } from "src/app/util/form/form.jsx"
import { setTipoRiego } from "src/app/reducers/programadorReducer";
import SelectSearchTipoRiego from "../tipoRiego/SelectSearchTipoRiego";
import { useEffect } from "react";
import NumberInput from "src/app/util/numberInput/NumberInput";
function Input(props) {
    const re = /^[+-]?\d*(?:[.]\d*)?$/;
    useEffect(() => {
        if (!props.input.value) {
            props.input.onChange(props.defaultValue);
        }
    }, [props.defaultValue])
    return (
        <div>
            <input
                onKeyPress={(event) => {
                    if (!re.test(event.key)) {
                        event.preventDefault();
                    }
                }}
                onChange={(e) => { props.input.onChange(e.target.value) }} value={props.input.value} />
        </div>
    )
}
export default function (props) {
    const programdorState = useSelector(state => state.programadorReducer);
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = React.useState(false);
    function MyModal(props) {
        return (
            <Modal
                size="md"
                {...props}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <p>
                    Seleccionar el Tipo de riego que posee el cultivo lo que nos definirá la 
                    eficiencia potencial, a partir de la cual se calcula el volumen de riego, 
                    es decir la cantidad de agua útil 
                    para el cultivo que queda en el suelo después de un riego, 
                    en relación al total del agua que se aplicó.

                    El sistema por defecto definirá un valor de eficiencia según 
                    el sistema seleccionado el cual podrá ser modificado por el usuario.
                    </p>
                </Modal.Body>
            </Modal>
        );
    }
    return (
        <div>
            {programdorState && <Form
                onSubmit={(values) => {
                    if (values.sistemaDeRiego) dispatch(setTipoRiego(values.sistemaDeRiego, values.eficiencia / 100))

                }}
                validation={(values, formprops) => {
                    if (values.sistemaDeRiego) {
                        props.setValid(true)
                    }
                    else {
                        props.setValid(false)
                    }
                }}
                saveIn={"formTipoCultivo"}
                changeAndSubmit
                components={(formprops, onChange) =>
                    <div>
                        <div className="main-icon"><i class="fa fa-tint"></i></div>
                        <h1>Tipo de riego
                            <button variant="primary" onClick={() => setModalShow(true)} className="btn-light info btn btn-secondary" >
                                <i className={"fa fa-info-circle "}></i>
                            </button>
                            <MyModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                            />
                        </h1>
                        <Row>
                            <Col lg={2}></Col>
                            <Col lg={4} md={6} sm={12}>
                                <Field
                                    name={"sistemaDeRiego"}
                                    component={SelectSearchTipoRiego}
                                    onChange={(v)=>{
                                        formprops.change("eficiencia",v?.eficiencia*100 || "")
                                    }}
                                />
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <label>Eficiencia potencial %</label>
                                <Field
                                    name={"eficiencia"}
                                    component={Input}
                                    //onChange={handleChange}
                                    defaultValue={programdorState.tipoRiego?.eficiencia * 100 || ""}
                                />

                            </Col>
                            <Col lg={2}></Col>
                        </Row>

                    </div>}>
            </Form>
            }

        </div>
    )
}