import React from 'react'
import {Col, Row} from 'react-bootstrap'
import { useSelector,useDispatch } from "react-redux";


const ResultadoAside = () => {
    const programadorState=useSelector(state=>state.programadorReducer);
    const data=programadorState;
    const estaciones=programadorState.estacionesMeteorologicas;
    const usos = programadorState.usosCulturales;
    const {
        fechaInicio:fi,
        fechaFin:fn
    }=programadorState;  
    const precipitacions=programadorState.precipitaciones;
    const resultTipoAnio= precipitacions?.reduce(function(r, a){
       return  a.tipoAnio 
    },[]);
  return (
    <Row className='barAside mt-3'>
        <Col lg={2}>
            <p><strong>Ubicación:</strong> {data.tipoDeUbicacion?.nombre} - 
            {data.tipoDeUbicacion?.nombre === "Departamento" && data.departamento?.Departamento?.nombre}
            {data.tipoDeUbicacion?.nombre=== "Distrito" &&  data.departamento?.Distrito?.nombre}
            {data.tipoDeUbicacion?.nombre=== "EM" &&  data.departamento?.EstaciónMeteorológica?.nombre}
            {data.tipoDeUbicacion?.nombre=== "Cuenca" &&  data.departamento?.Cuenca?.nombre}
            {data.tipoDeUbicacion?.nombre=== "Inspeccion" &&  data.departamento?.Inspeccion?.nombre}
            </p>
            <p><strong>Estación correspondiente: </strong>{estaciones?.map(d => (
                        <div>{d.nombreEstacion}</div>)) || "-"}</p> 
        </Col>
        <Col lg={3}>
            <p><strong>Fecha:</strong></p>
            <p>Desde: {new Date(fi).toLocaleDateString(undefined,{ month: "numeric", day: "numeric", })}<br>
            </br>Hasta: {new Date(fn).toLocaleDateString(undefined,{ month: "numeric", day: "numeric", })}</p>
            <p><strong>Precipitación: </strong> {resultTipoAnio || "Ninguno"}</p>
            <p><strong>Precip. Anual: </strong> {data.resultado.totalAnual.precipitacion +" "+ "mm"}</p>
            <p><strong>Precip. Período: </strong> {data.resultado.totalPeriodo.precipitacion +" "+ "mm"}</p>
        </Col>
        <Col lg={2}>
            <p><strong>Cultivo:</strong>{data.tipoCultivo?.nombre ? data.tipoCultivo?.nombre: data.kcPersonalizado == true ? "Personalizado": "-"}</p> 
            <p><strong>Tipo de Riego:</strong> {data.tipoRiego?.descripcion || "-"}</p> 
            <p><strong>Eficiencia potencial:</strong> { data.tipoRiego?.eficiencia ? data.tipoRiego?.eficiencia * 100 +" "+ "%"  : "0 %"}</p> 
        </Col>
        <Col lg={3}>
            <p><strong>Eto Anual:</strong> {data.resultado.totalAnual.eto +" "+ "mm"} </p>
            <p><strong>Eto Período:</strong> {data.resultado.totalPeriodo?.eto?.toFixed(0) +" "+ "mm"} </p>
            <p><strong>Etc Anual:</strong> {data.resultado.totalAnual.etc +" "+ "mm"} </p>
            <p><strong>Etc Período:</strong> {data.resultado.totalPeriodo?.etc?.toFixed(0) +" "+ "mm"} </p>
            <p><strong>% de suelo mojado:</strong> {data?.proporcionSueloMojado} </p> 
            <p><strong>Humedad útil según tipo de riego:</strong> {data?.resultado?.humedadUtil ? (data?.resultado?.humedadUtil + " " + "mm") : "0 mm"} </p> 
        </Col>
        <Col lg={2}>            
            <p><strong>Uso Cultural:</strong> {usos?.map(e =>(<div>{e.usoCultural.nombre}</div>)) || "-"}</p> 
            <p><strong>Tipo de Suelo:</strong> {data.tipoSuelo?.nombre} </p> 
            <p><strong>Umbral de Riego:</strong> <br></br> {data?.umbralRiego ? ( data?.umbralRiego + "%"): "0 %"} </p> 
        </Col>
  
    
    </Row>
  )
}

export default ResultadoAside