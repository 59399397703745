import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from 'react-bootstrap'
import { constante, variable, automatizado } from "../frecuenciaRiego/input";
import 'src/styles.css'
import GraficoResultado2 from "./GraficoResultado2";
import { da } from "date-fns/locale";

function CalcVariableAutomatizado(state, programadorState) {
    let laminaDisponible = programadorState.resultado.humedadUtil * programadorState.porcentajePerfilInicio/100;
    let cortainicio = new Date(programadorState.fechaCortaInicio);
    cortainicio.setFullYear(2024)
    let cortafin = new Date(programadorState.fechaCortaFin)
    cortafin.setFullYear(2024)
    programadorState.resultado.datosXDia.forEach((d, i) => {
        d.laminaAplicada = 0;
        d.percolacion = 0;
        d.laminaDeficit=0;
        d.laminaEntreURyPMP = Math.round(programadorState.resultado.laminaEntreURyPMP);
        d.humedadUtil = programadorState.resultado.humedadUtil;
        //laminaDisponible - d.etc + d.precipitacion = lamina aplicada
        if (laminaDisponible - d.etc + d.precipitacion < programadorState.resultado.laminaEntreURyPMP && (programadorState.fuenteExtra ||
            (cortainicio < cortafin && (cortainicio > d.fecha || d.fecha > cortafin) || (cortafin < d.fecha && d.fecha < cortainicio)))) {
            d.autoHuboRiego = true;
            d.laminaAplicada = Math.max(programadorState.resultado.humedadUtil - laminaDisponible - d.precipitacion, 0);
            d.percolacion -= Math.round(laminaDisponible - (programadorState.resultado.A - programadorState.resultado.B) - d.etc + d.precipitacion + d.laminaAplicada);
            if (d.percolacion > 0) d.percolacion = 0;
            d.eficiencia = ((d.laminaAplicada + d.percolacion) / d.laminaAplicada || 0)*100;
            state.eficiencia += d.eficiencia;
            state.cantidadRiegos++;
        }
        laminaDisponible = Math.max(Math.min(laminaDisponible + d.laminaAplicada - d.etc + d.precipitacion, programadorState.resultado.humedadUtil * 1), 0);


    })
    laminaDisponible = programadorState.resultado.humedadUtil*programadorState.porcentajePerfilInicio/100;
    programadorState.resultado.datosXDia.forEach((dia, index) => {

        if (dia.autoHuboRiego) {
            //se riega
            laminaDisponible = Math.min(laminaDisponible + dia.laminaAplicada * 1, programadorState.resultado.humedadUtil);
            state.laminaAnual += dia.laminaAplicada;

        }
        if (laminaDisponible < programadorState.resultado.laminaEntreURyPMP) {
            console.log("deficit", dia)
            state.laminaDeficit += dia.etc;
            state.diasDeficit++;
        }


        laminaDisponible = Math.max(Math.min(laminaDisponible - dia.etc + dia.precipitacion, programadorState.resultado.humedadUtil * 1), 0);
        dia.anualLaminaDisponible = laminaDisponible.toFixed();
    })

}
function getProximoRiego(date, riegoMes, programadorState, dia) {
    let cortainicio = new Date(programadorState.fechaCortaInicio);
    cortainicio.setFullYear(2024)
    let cortafin= new Date(programadorState.fechaCortaFin)
    cortafin.setFullYear(2024);
    let proximoRiego = new Date(date * 1 + riegoMes.intervalo * 24 * 60 * 60 * 1000);
    proximoRiego.setYear(2024)
    if (riegoMes.intervalo===0){
        return null;
    }
    if (programadorState.tipoFrecuenciaRiego == variable || programadorState.fuenteExtra || 
        (cortainicio<cortafin && (cortainicio > date || date > cortafin) || (cortafin < date && date < cortainicio)) ){
        return proximoRiego;
    }
    else {
        let proximoRiego = new Date(cortafin * 1 + 24 * 60 * 60 * 1000);
        proximoRiego.setYear(2024)
        return proximoRiego
    }


}
function CalcVariableNoAutomatizado(state, programadorState) {
    let laminaDisponible = programadorState.resultado.humedadUtil * programadorState.porcentajePerfilInicio/100;
    let proximoRiego = null;
    programadorState.resultado.datosXDia.forEach((dia, index) => {
        dia.percolacion = 0;
        let laminaAplicada = 0;
        dia.laminaDeficit=0;
        const date = new Date(dia.fecha)
        const riegoMes = programadorState.RiegoMeses[date.getMonth()];
        if (!proximoRiego) {
            proximoRiego=getProximoRiego(date,riegoMes,programadorState,dia)
            //state.cantidadRiegos++;
        }
        else if (proximoRiego.getMonth()==date.getMonth() && proximoRiego.getDate()==date.getDate() ) {
            console.log("se rego", date)
            proximoRiego=getProximoRiego(date,riegoMes,programadorState,dia)
            laminaAplicada = riegoMes.lamina * 1
            state.laminaAnual += riegoMes.lamina * 1;
            state.cantidadRiegos++;
            dia.percolacion -= Math.round(laminaDisponible - (programadorState.resultado.A - programadorState.resultado.B) - dia.etc + dia.precipitacion + laminaAplicada);

            if (dia.percolacion > 0) dia.percolacion = 0;
            dia.eficiencia = Math.round(((laminaAplicada + dia.percolacion) / laminaAplicada || 0) * 100);
            state.eficiencia += dia.eficiencia        
        }

        laminaDisponible = Math.max(Math.min(laminaDisponible - dia.etc + dia.precipitacion + laminaAplicada, programadorState.resultado.humedadUtil * 1), 0);
        dia.anualLaminaDisponible = laminaDisponible.toFixed(0);
        //console.log(programadorState.resultado.laminaEntreURyPMP)
        dia.laminaEntreURyPMP = Math.round(programadorState.resultado.laminaEntreURyPMP);
        dia.humedadUtil = programadorState.resultado.humedadUtil;
        dia.laminaAplicada = laminaAplicada;
        if (laminaDisponible < programadorState.resultado.laminaEntreURyPMP) {

            state.laminaDeficit += dia.etc;
            dia.laminaDeficit= dia.etc;
            state.diasDeficit++;
        }

    })
}
export default function (props) {
    const programadorState = useSelector(state => state.programadorReducer);
    const [state, setState] = useState({ diasDeficit: 0, laminaDeficit: 0, laminaAnual: 0, eficiencia: 0, cantidadRiegos: 0 });
    useEffect(() => {
        if (!programadorState.resultado) {
            return null;
        }
        if (programadorState.tipoFrecuenciaRiego == automatizado) {
            CalcVariableAutomatizado(state, programadorState);
        }
        else {
            CalcVariableNoAutomatizado(state, programadorState)
        }
        let etcAcumulada=0;
        let deficitAcumulada=0;
        programadorState.resultado.datosXDia.filter((dia)=>{
            etcAcumulada+=dia.etc;
            dia.etcAcumulada=Math.round(etcAcumulada/programadorState.resultado.totalAnual.etc*100);
            deficitAcumulada+=dia.laminaDeficit;
            dia.deficitAcumulada=Math.round(deficitAcumulada/programadorState.resultado.totalAnual.etc*100);
        })
        console.log(etcAcumulada,deficitAcumulada);
        setState({ ...state })
    }, [programadorState.resultado]);
    return state.cantidadRiegos ?
        <div className="res">
            <Row>
                <Col lg={3} md={12} className="result-list">
                    <h4>Resultado Calendario</h4>
                    <div className="resultCalendario">
                    <ul>
                        <li><strong>Demanda Total: </strong>{programadorState.resultado.totalAnual.etc.toFixed(0) + " " + "mm"}</li>
                        <li><strong>Lámina Anual Aplicada (Cultivada): <br></br></strong>{state.laminaAnual.toFixed(0)+ " " + "mm"}</li>
                        { programadorState.tipoFrecuenciaRiego != automatizado && <li><strong>Lámina Anual Aplicada(Con Derecho): </strong>{
                        (state.laminaAnual*programadorState.superficieCultivada/programadorState.superficieDerecho).toFixed(0)+ " " + "mm"}
                        </li>}
                        <li><strong>Días Déficit: </strong>{state.diasDeficit}</li>
                        <li><strong>Déficit: </strong>{state.laminaDeficit.toFixed(0)+ " " + "mm"}</li>
                        <li><strong>Cobertura de demanda: </strong>{Math.round((1 - state.laminaDeficit / programadorState.resultado.totalAnual.nB) * 100) + " " + "%"}</li>
                        <li><strong>Eficiencia de uso: </strong>{Math.round(state.eficiencia / state.cantidadRiegos) + " " + "%"}</li>
                    </ul>
                    </div>
                </Col>
                <Col lg={9} md={12}>
                    <GraficoResultado2 programadorState={programadorState} />
                </Col>
            </Row>
        </div> : null


}
