import React,{useState, useEffect} from 'react';
import { BrowserRouter, Route, Switch,Redirect } from 'react-router-dom';
import './App.scss';
import StepsButtons from './app/components/programador/StepsButtons';
import Home from '../src/app/components/home/home'
import ReactGA from "react-ga";
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
export const App = () => {

  useEffect(() => {
    ReactGA.initialize('UA-225067868-1');
    ReactGA.pageview('/');
  }, [])


    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
              
               <Route path="/:page" name="Inicio" exact={true} render={props => <Home {...props} />} /> 
               <Route path="/:page/:subpage" name="Inicio" exact={true} render={props => <Home {...props} />} /> 
               <Route path="/" name="Inicio" exact={true} render={props => <Home {...props} />} />
               <Redirect to={"/"} />
              {/*<Route path="/" name="Inicio" render={props => <DefaultLayout {...props} />} />*/}
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
}

export default App;

