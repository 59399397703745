import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

export default function (props) {
    useEffect(() => {

        if (!("uncheckedValue" in props) && props.input.value === "") {
            props.input.onChange(false)
        }
        else if ("uncheckedValue" in props && (props.input.value === "" || typeof props.input.value == "undefined")) {
            props.input.onChange(props.uncheckedValue)
        }
    }, [JSON.stringify(props.input.value)])
    function onChange(evt) {
        if (evt.target.checked) {
            props.input.onChange("checkedValue" in props ? props.checkedValue : true);
        }
        else {
            props.input.onChange("uncheckedValue" in props ? props.uncheckedValue : false);
        }
    }
    function getChecked() {
        if (props.uncheckedValue === props.input.value || (!("uncheckedValue" in props) && props.input.value === false)) {
            return (false);
        }
        else if (props.checkedValue === props.input.value || (!("checkedValue" in props) && props.input.value === true)) {
            return (true);
        }
        else {
            return (false)
        }
    }
    if (props.label){
        return <Row>
            <Col>
                {props.label()}
            </Col>
            <Col>
            <input type="checkbox" onChange={onChange} checked={getChecked()} style={props.style} className={props.className} disabled={props.disabled}/>
            </Col>
        </Row>
    }
    else {
        return <input type="checkbox" onChange={onChange} checked={getChecked()} style={props.style} className={props.className} disabled={props.disabled}/>
    }
    
}