import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import NumberInput from "src/app/util/numberInput/NumberInput";
const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];
export default function InputRiegoMesesConstantes(props) {
    const [refreshs, setRefreshs] = useState(0)
    const derecho = props.programador.portentajeDerecho || 100;
    const cultivo = props.programador.portentajeCultivo || 100;
    useEffect(() => {
        if (!props.input.value) {
            props.input.onChange(months.map((e, i) => ({ intervalo: 0, lamina: 0, mes: e, mesIndex: i, inputIntervalo: 0, inputLamina: 0 })));//default value
        }
        else {
            const first = props.input.value[0];
            props.input.onChange(props.input.value.map(e => ({
                ...first, //the same value for all positions
                lamina: first.inputLamina / cultivo / derecho * 100 * 100,
                intervalo: first.inputIntervalo / cultivo * 100
            })))
        }
    }, [derecho, cultivo]);
    const change = () => {

        props.input.onChange([...props.input.value]);//force change event
        setRefreshs(refreshs + 1)
    }

    return (
        <>
            <Row><Col className="resultadoBgGris">
               
                {props.input.value && <Row>
                    <Col lg={6}>
                        <label style={{ textAlign: "center", margin: "0 auto", display: "block" }} className="mb-2">
                            {props.programador.riegoConPozo ?"Datos del riego":"Datos del cuadro de turno"}
                            </label>
                        <hr></hr>
                        <Row>
                            <Col lg={5} align="center" >
                                Frecuencia de riego (días)
                                <NumberInput
                                    type="number"
                                    min={0}
                                    input={{
                                        value: parseInt(props.input.value[0].inputIntervalo) || "",
                                        onChange: (v) => {
                                            props.input.value.forEach((m, i) => {
                                                m.intervalo = v / cultivo * 100;
                                                m.inputIntervalo = v
                                            });
                                            change();
                                        }
                                    }}
                                />
                            </Col>
                            
                            <Col lg={5} align="center">
                                Lámina (mm)
                                <NumberInput
                                    type="number"
                                    min={0}
                                    input={{
                                        value: parseInt(props.input.value[0].inputLamina) || "",
                                        onChange: (v) => {
                                            props.input.value.forEach((m, i) => {
                                                m.lamina = v / derecho / cultivo * 100 * 100;
                                                m.inputLamina = v;
                                            });
                                            change();
                                        }
                                    }}
                                />
                            </Col>
                            <Col lg={2} align="center">
                                    En m3
                              <input value={parseInt(props.input.value[0].inputLamina) * 10} disabled></input>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6}>
                        <label style={{ textAlign: "center", margin: "0 auto", display: "block" }} className="mb-2">Datos resultantes</label>
                        <hr></hr>
                        <Row>
                            <Col lg={5} align="center">
                                Frecuencia Aplicada (días)<br></br>
                                {Math.ceil(props.input.value[0].intervalo)}
                            </Col>
                            <Col lg={5} align="center">
                                Lámina Aplicada (mm)<br></br>
                                {Math.ceil(props.input.value[0].lamina)}
                            </Col>
                            <Col lg={1} align="center">
                                m3<br></br>
                                {Math.ceil(props.input.value[0].lamina * 10)}
                            </Col>
                        </Row>
                    </Col>


                </Row>}
            </Col></Row>
        </>
    )
}