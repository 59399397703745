
import React, { useEffect, useState } from "react";
import { Field, reduxForm,FieldArray} from "redux-form";
import { ButtonGroup, Container, Col, Row, Button} from "react-bootstrap";
import { Link } from "react-router-dom";
import './form.css'


function validate(values,props){
    let err;
    if (props.validation) err=props.validation(values,props);
    if (err && Object.keys(err).length){return err;}
    if (props.changeAndSubmit) props.onSubmit(values);
    
}
function Formulario(props){
    //props=>,components*,onSubmit*,submitText,clearButton,hideButton,initialHidding,validation
    //changeAndSubmit, saveIn, submitHide
    const [state]=useState({showing:!props.initialHidding});
    const [refreshs,setRefreshs]=useState(0);
    const reRender=()=>setRefreshs(Math.random())

    useEffect(()=>{
        if (typeof props.onInitialValuesLoad=="function"){
            props.onInitialValuesLoad(props.state.newinitialValues,props)
        }
    },[props.state.newinitialValues])
    async function send(evt){
        try {
            await props.handleSubmit(evt);
            if (props.submitHide){
                state.showing=false;
                reRender();
            }
        }
        catch(e){
            console.error(e);
        }
    }
    function Limpiar(){
        
        if (props.saveIn){
            localStorage.removeItem(props.saveIn);
            props.state.keyForm++;
        }
        if (props.onReset)props.onReset();
        props.destroy();
        reRender();
    }
    return (
        <div>
            <form onSubmit={send}>
            {
                    props.hideButton && 
                    <Button onClick={()=>{state.showing=!state.showing;reRender()}} className="formGeneral-btn-show-hide">
                        {state.showing? props.hideButtonText || "Ocultar": props.showButtonText || "Mostrar"}
                    </Button>
                }
            <Row hidden={!state.showing} > 
                <Col lg={12}>
                {props.components(props,reRender)}
                </Col>
            </Row>
            <Row>
            
                { state.showing && 
                    <Col lg={12} className="formGeneral-btn-submit-reset">
                        {!props.noSubmitButton && <button type="button" className="btn btn-primary" onClick={()=>props.submit()} disabled={props.invalid}>
                            {props.submitText || "Enviar"}
                        </button>}
                        {
                        props.clearButton &&
                        <Button 
                        onClick={Limpiar}
                        type="button">
                            {"Limpiar"}
                        </Button>
                    }
                    {props.backButton &&
                                <Link
                                    className="btn btn-light mr-2"
                                    to={props.backButton}
                                >
                                   {props.backText || "Cancelar"}
                                   {/* Cancelar */}
                                </Link>
                            }
                    </Col>
                }
                
                {/* {
                    props.hideButton && 
                    <Button onClick={()=>{state.showing=!state.showing;reRender()}}>
                        {state.showing? "Ocultar":"Mostrar"}
                    </Button>
                } */}
           
            </Row>
        </form>
        </div>
    )
}


function getInitialValues(props,state){
    let initialValues={}
    if (props.saveIn){
        let data=localStorage.getItem(props.saveIn);
        if (!data)return;
        try {
            data=JSON.parse(data)
        }
        catch(e){
            localStorage.removeItem(props.saveIn);
        }
        initialValues={...initialValues,...data}
    }
    if (props.initialValues){
        initialValues={...initialValues,...props.initialValues}
    }
    state.loadInitialValues=true;
    return initialValues;
}


function wrapName (name){ 
    const FormularioReducer=reduxForm({
        validate,
        form:name || "GenericForm"
    })(Formulario);
    function Intermedio(props){
        const {onSubmit,initialValues,...rest}=props;
        async function send(values){
            try {
                if (props.saveIn)localStorage.setItem(props.saveIn,JSON.stringify(values))
                await onSubmit(values)
            
            }
            catch(e){
                console.error(e)
            }
            
        }

        const [state]=useState({loadInitialValues:false,newinitialValues:null,keyForm:0})
        if (!state.newinitialValues) state.newinitialValues=getInitialValues(props,state);
        return <FormularioReducer  onSubmit={send} initialValues={state.newinitialValues}  state={state} key={state.keyForm} {...rest}/>
    }
    return Intermedio;
}

export default function (props){
    const [state]=useState({});
    if (!state.GenericForm){
        state.GenericForm=wrapName(props.formName || "id-"+ new Date()*1)
    }
   
    return <state.GenericForm {...props} />
}
export {Field,FieldArray};