import React, { useState,useEffect } from "react";
import Pagination from "../Pagination";
import CriteriaForm from "../criterial-form/criterial-form";
import {Button, Col, Row} from 'react-bootstrap'
import { swalFire } from "../swalFire";
import LoadingOverlay from 'react-loading-overlay';
import "../Select.css";
import '../criterial/criterial.css'
export default function (props){
    const [state]=useState({refreshs:0,loading:false,data:null,currentPage:props.initialPage || 0 ,totalPages:0,criteria:props["criteria-initialValues"] || {} });
    const [,setRefrehs]=useState();
    const reRender=()=>setRefrehs(++state.refreshs);
    async function search(){
        state.loading=true;reRender();
        try {
            const result=await props.searchItems(state.currentPage,props.pageSize,{...state.criteria});
            state.currentPage=result.currentPage;
            state.totalPages=result.totalPages;
            state.data=result.data;
        }
        catch(e){
            console.error(e);
        }
        state.loading=false;
        reRender();
    }
    useEffect(()=>{
        state.currentPage=0;
    },[props.resetPage])
    useEffect(()=>{
        search();
    },[props.searchCount,props.resetPage])
    
    const onPagination = (e, i) => {
        e.preventDefault();
        state.currentPage=i;
        search();
    };
    function waitfor(fn,data){
        
        return async ()=>{
            state.loading=true;
            reRender();
            await fn(data);
            state.loading=false;
            reRender();
        }
    }
    function reiniciarFiltros(){
        state.criteria={};
        reRender();
    }
    function filtrarBusqueda(criteria){
        state.currentPage=0;
        state.criteria=criteria;
        search();
        reRender();
    }
    const paginationData = () => {
        const { currentPage, totalPages } = state;
        return {
          pagination: {
            currentPage,
            totalPages,
            onPagination: onPagination,
          },
        };
      };
      
    return (
        <div>
        <LoadingOverlay
            active={state.loading}
            spinner
          text='Cargando contenido...'
        >
            {props["criteria-options"] && 
            <Row className="card-dropdown">
                    <Col>
                        <CriteriaForm
                        onSubmit={filtrarBusqueda} 
                        hideButton
                        showButtonText="Mostrar Filtros"
                        hideButtonText="Ocultar Filtros"
                        initialHidding={props.initialHidding || false}
                        clearButton
                        //initialValues={props.initialValues}
                        //submitHide
                        initialValues={{criteria:props["criteria-initialValues"]}}
                        submitText="Filtrar"
                        saveIn={props["criteria-saveIn"]}
                        onReset={reiniciarFiltros}
                        criteria-deployed
                        criteria-mode
                        criteria-options={props["criteria-options"]}s
                        />
                    </Col>
            </Row>
            }
            {props.title}
            {props.table && <props.table data={state.data} go={props.go} waitFor={waitfor} {...props.tableProps}/>}
            {props.pageSize && <Pagination {...paginationData()}></Pagination>}
        </LoadingOverlay>
        </div>
    )
}