import React, { useEffect,useState} from "react";
import { useDispatch } from "react-redux";
import SelectSearch from "src/app/util/SelectSearch/SelectSearch";
const options=[
  {id:1,nombre:"Alto"},
  {id:2,nombre:"Medio"},
  {id:3,nombre:"Bajo"},
  {id:4,nombre:"Ninguno"}
]
export default function (props){
  const dispatch=useDispatch();
  return (
    <SelectSearch
      input={props.input}
      keyId={"id"}
      label="Precipitación"
      getName={(item)=>item.id+"-"+item.nombre}
      getOptions={async (string)=>{
        return options
      }}
      rescueOption={async (obj)=>{
        return options.find((e)=>e.id==obj.id)
      }}
      placeHolder="Seleccione"
    />
  )
}