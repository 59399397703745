import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { buscarTipoCultivo } from "src/app/reducers/tipoDeCultivoReducer";
import SelectSearch from "src/app/util/SelectSearch/SelectSearch";
import rootspng from "./roots.png";
import dirtpng from "./tierra.png"
import arbolsvg from "./arbol.svg"
import tomate from "./tomate.png"
import pasto from './pasto.png'
import frutal from './frutal.png'
import vid from './vid.png'
import olivo from './olivo.png'
import espinaca from './espinaca.png'
import cesped from './cesped.png'
import './tipoCultivoStyle.css'

const radios={
    1: [1,1.55],
    3: [1,1],
    6: [1,0.5],
    4: [1,0.7],
    5: [1,0.6],
    2: [1,1.20]

}

const cultivoImange = {
  Forestal: arbolsvg,
  undefined: arbolsvg, 
  Personalizado: arbolsvg,
  Frutal: frutal,
  Cesped: cesped,
  Pastura:pasto,
  Vid: vid,
  Olivo: olivo,
  Hortícola: tomate,
  Siembra: espinaca,
}
export default function (props) {
  const dispatch = useDispatch();
  const [state] = useState({ refreshs: 0, loadings: 0, });
  const [, setRefresh] = useState(0);
  const reRender = () => setRefresh(++state.refreshs);
  const refCanvas = useRef();
 
  // if(programdorState.kcPersonalizado){
  //   const cultivoPresonalizado = {      
  //     kcPersonalizado: arbolsvg,     
  //   }
  // }
  useEffect(() => {
    state.roots = new Image();
    state.roots.src = rootspng;
    state.tierra = new Image();
    state.tierra.src = dirtpng;
    state.arbol = new Image();
    state.arbol.src = cultivoImange[props.cultivo?.nombre]
    state.arbol.onload = state.tierra.onload = state.roots.onload = () => {
      state.loadings++;
      if (state.loadings == 3) {
        reRender();
      }
    }
  }, [props.cultivo])
  const w = 180;
  const h = 350;
  function draw(x) {
    const { arbol, tierra, roots } = state;
    const proporcion = props.proporcion || 0.5;
    const ctx = refCanvas.current.getContext("2d");
    const alturaSuelo = 0.6

    //Dibujar suelo
    ctx.drawImage(tierra, x, h * alturaSuelo, w, h * (1 - alturaSuelo));
    ctx.moveTo(x, h * alturaSuelo);
    ctx.lineTo(x + w, h * alturaSuelo)
    ctx.stroke();
    //Dibujar Cielo
    //dibujar arbol
    ctx.drawImage(arbol, x, 0, w, h * alturaSuelo)


    true && ctx.drawImage(roots, x + w * 0.05, h * alturaSuelo, w * 0.9, w / 2 * 0.9);
    //IMPORTANTE ANIMACION DE CRECIMIENTO DE LA RAMA
    false && ctx.drawImage(roots, x + roots.width / 2 - roots.width / 2 * proporcion, 0, roots.width * proporcion, roots.height * proporcion,
      w / 2 - w / 2 * proporcion, h / 2, w * proporcion, w / 2 * proporcion
    );
    ctx.stroke();
    //Dibujar vulvo

    ctx.beginPath();
    //ctx.arc(x + w / 2, h * alturaSuelo, proporcion * w / 2, 0, Math.PI, false);
    ctx.ellipse(
      x+w /2 /*x*/, 
      h* alturaSuelo /*y*/, 
      proporcion * w / 2 * radios[props.suelo.id][0]/*radiusX*/, 
      proporcion * w / 2 * radios[props.suelo.id][1]/*radiusY*/, 
      0 /*rotation*/, 
      0 /*start angle*/, 
      Math.PI /*eng angle*/
      );
    //ctx.closePath();
    ctx.fillStyle = "rgba(0, 80, 156, 0.5)";
    ctx.fill();
    ctx.stroke();

  }
  useEffect(() => {
    refCanvas.current.width += 0;//reset the canvas
    draw(0);
    draw(w);
    draw(w * 2);
  }, [props.proporcion, state.loadings,props.suelo?.id])
  return (


    <canvas width={w * 3} height={h} ref={refCanvas} className="canvas"/>


  )
}