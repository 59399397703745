import React,{useState} from "react";
import SelectSearchEstacion from "../criterioUbicacion/SelectSearchEstacion";
import SelectSearchDepartamento from "../criterioUbicacion/SelectSearchDepartamento";
import SelectSearchDistrito from '../criterioUbicacion/SelectSearchDistrito';
import SelectSearchCuenca from '../criterioUbicacion/SelectSearchCuenca';
import Form,{Field} from "src/app/util/form/form.jsx"

const GroupSelectSearch = (props) => {
   

  return (
    <div>
                        {props.select === "Estacion" &&
                            <Field                    
                                name={`estacionesMeteorologicas`}
                                component={SelectSearchEstacion}                               
                                labelAttr="nombre"
                                //estacion={setEstacion}
                                handleSelectSearch={props.handleSelectSearch}
                            />
                        }
                            <Field                    
                                name={`departamentos`}
                                component={SelectSearchDepartamento}                                
                                labelAttr="nombre"
                                departamento={props.setDepartamento}
                            />
                            <Field                    
                                name={`distrito`}
                                component={SelectSearchDistrito}                                
                                labelAttr="nombre"
                                distrito={props.setDistrito}
                            />
                            <Field                    
                                name={`cuenca`}
                                component={SelectSearchCuenca}                                
                                labelAttr="nombre"
                                select={props.setSelect}
                                cuenca={props.setCuenca}
                            />

    </div>
  )
}

export default GroupSelectSearch