import React from 'react'
import { Card, Row, Col, Container, Button } from "react-bootstrap"

const StepsButtons = (props) => {
  return (
    <Row align="center">
    <Col>
      <Button type="primary" onClick={props.prev} style={{ marginRight: 10 }}>
        Anterior
      </Button>
    </Col>
    <Col>
    <input type="range" class="form-range" id="customRange1"></input>
    </Col>
    <Col>
      <Button type="primary" onClick={props.next}>
        Siguiente
      </Button>
    </Col>
  </Row>
  )
}

export default StepsButtons