import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import dirtpng from "./tierra.png"
import surcopng from "./surco.png"
import farmerpng from "./farmer.png"
import tractorpng from "./tractor1.png"
import dogpng from "./dog.png"
import './tipoCultivoStyle.css'
const radios={
    1: [1,1.55],
    3: [1,1],
    6: [1,0.5],
    4: [1,0.7],
    5: [1,0.6],
    2: [1,1.20]

}


export default function (props) {
  const dispatch = useDispatch();
  const [state] = useState({ refreshs: 0, loadings: 0,clientX:0,clientY:0,x:0,y:0,click:false});
  const [, setRefresh] = useState(0);
  const reRender = () => setRefresh(++state.refreshs);
  const refCanvas = useRef();
  const onClickDown=(e)=>{
    state.clientX=e.clientX;
    state.clientY=e.clientY;
    window.onmousemove=onMouseMove;
    window.onmouseup=()=>{
      window.onmousemove=null;
    }
  }
  const onMouseMove=(e)=>{
    const {clientX,clientY}=state;
    state.clientX=e.clientX;
    state.clientY=e.clientY;
    state.x+=(state.clientX-clientX);
    state.x=Math.min(0,state.x)
    // state.y+=(state.clientY-clientY);
    reRender();

  }
  useEffect(() => {
    state.tierra = new Image();
    state.tierra.src = dirtpng;
    state.surco = new Image();
    state.surco.src = surcopng;
    state.tractor= new Image();
    state.tractor.src=tractorpng;
    state.farmer= new Image();
    state.farmer.src= farmerpng;
    state.dog= new Image();
    state.dog.src= dogpng;
    state.dog.onload= state.surco.onload = state.tierra.onload = state.farmer.onload= state.tractor.onload= () => {
      state.loadings++;
      if (state.loadings == 5) {
        reRender();
      }
    }
  }, [])
  const w = 600;
  const h = 400

  function draw() {
    if (!(props.hileras*1) || !(props.tapadas*1))return;
    const ctx = refCanvas.current.getContext("2d");
    const dx=4;
    const dy=4;
    let corteX=state.x%(w/dx);
    let corteY=state.y%(h/dy);
    for (let i=-1;i<dx+1;i++){
      for (let j=-1;j<dy+1;j++){
        ctx.drawImage(state.tierra,0,0,state.tierra.width,state.tierra.height,w*i/dx+corteX,j/dy*h+corteY,w/dx,h/dy)
      }
      
    }
    ctx.translate(state.x, state.y);
    const separacionHilera=0.05;//20% de width
    const largoHilera=0.6;
    const anchoHilera=0.01;
    let x=0;
    for (let j=0;j<props.tapadas;j++){
      
      for (let i=0;i<props.hileras;i++){
        ctx.fillStyle="cyan";
        for (let y=0;y<largoHilera*h;y+=separacionHilera*w){
          ctx.drawImage(state.surco,(separacionHilera*(i) +anchoHilera*i)*w+x,y,separacionHilera*w,separacionHilera*w)
        }
        ctx.fillRect((separacionHilera*(i+1) +anchoHilera*i)*w+x,0,anchoHilera*w,h*largoHilera);
      }
      ctx.fillRect((separacionHilera)*w+x,largoHilera*h,((props.hileras-1)*(anchoHilera+separacionHilera)+anchoHilera)*w,anchoHilera*w)
      ctx.fillRect((separacionHilera)*w+x,largoHilera*h,anchoHilera*w,largoHilera*0.25*h)
      ctx.stroke();
      
      x+=((props.hileras)*(anchoHilera+separacionHilera) )*w;
    }
    ctx.drawImage(state.tractor,0.4*w, h*0.8, 0.1*w ,0.2*h);
    ctx.fillRect(0,largoHilera*h*1.25,x-((props.hileras)*(anchoHilera+separacionHilera)-separacionHilera-anchoHilera)*w ,anchoHilera*w)
    ctx.drawImage(state.farmer,w*0.02, h*0.6, 0.1*w ,0.15*h);
    ctx.drawImage(state.dog,w*0.1, h*0.8, 0.05*w ,0.05*w);
    ctx.drawImage(state.dog,w*0.15, h*0.85, 0.05*w ,0.05*w);
  }
  useEffect(() => {
    refCanvas.current.width += 0;//reset the canvas
    draw(0);
  }, [props.hileras,props.tapadas,state.loadings,state.x,state.y])
  return (
     <canvas width={w} height={h} ref={refCanvas} className="canvas" onMouseDown={onClickDown}/>
    
  )
}