import { DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";
import React, { useEffect,useState} from "react";
import { Row,Col, Modal } from "react-bootstrap";
import SelectSearch from "react-select-search";
import { FormGroup } from "reactstrap";
import SecureTableGo from "../permisos/SecureTableGo";
import UtilTable from "../UtilTable/UtilTable";
import "./SelectSearchStyle.css";

const BasicTable=(props)=>{
  return (
    <DataGrid dataSource={props.data}>
      <Column dataField={"value"} />
      <Column dataField={"name"} />
      <Column caption="Acciones" align="left" width="100px"
        cellRender={({ data }) => {
            return (
                <SecureTableGo
                    waitFor={props.waitFor}
                    data={data}
                    go={props.go || []}
                />
        )}}
      />
    </DataGrid>
  )
}
export default function(props){
    
    const [state]=useState({refresh:0,value:null,options:[],inputValue:"",focus:false,selected:null,tableMode:false,searchCount:0,lastPromise:null});
    const [,setRefreshs]=useState(0);
    const reRender=(changes)=>{
      if (changes) for (const key in changes){
        state[key]=changes[key];
      }
      setRefreshs(++state.refresh)
    };
    const createOption=(item)=>{
      return {
        value:String(item[props.keyId]),
        name:props.getName(item),
        item,
      }
    }
    const handleChange=(value)=>{
        state.focus=false; 
        if (state.selectprops)state.selectprops.onBlur();
        const option=state.options.find(e=>e.value==value);
        props.input.onChange(option.item);
        state.selected=option.item
        reRender(); 
    }
    const handleChangeInput=async (value)=>{
        state.inputValue=value;
        if (state.tableMode){
          state.searchCount++;
          reRender()
          return ;
        }
        const items=await props.getOptions(value,0);
        if (Array.isArray(items)) state.options=items.map(createOption);
        else state.options=items.data.map(createOption)
        reRender();
    }
    const pipeLineGetOptions=async ()=>{
      const oldpromise=state.lastPromise;
      const newpromise=props.getOptions();
      state.lastPromise=newpromise;
      await oldpromise;
      const items=await newpromise;
      if (Array.isArray(items)) state.options=items.map(createOption);
      else state.options=items.data.map(createOption);
      reRender();
      
    }
    useEffect(()=>{
      pipeLineGetOptions();
    },[props.forceGetOptions]);
    const rescueValueOption=async ()=>{
      if (props.input.value && state.options && props.rescueOption){
        const option=state.options.find(e=>e.value==props.input.value[props.keyId]);
        if (!option){
          const element=await props.rescueOption(props.input.value);
          if (!element) return;
          //state.options.push(createOption(element));
          state.selected=element;
        }
        else {
          state.selected=option.item;
        }
        reRender();
      }
    }
    const cleanValue= ()=>{
      state.selected=null;
      props.input.onChange(null)
      reRender();
    }
    useEffect(()=>{
     rescueValueOption();
     if (!props.input.value){
      state.selected=null;
      reRender();
     }
    },[JSON.stringify(props.input.value)]);
    const renderInput=(inputprops)=>{
        state.selectprops=inputprops;
        return (
            <Row >
             
              <Col>
            <input 
                {...inputprops} 
                onChange={(e)=>{inputprops.onChange(e);handleChangeInput(e.target.value)}} 
                onBlur={()=>{state.focus=false;inputprops.onBlur();reRender();}}
                onFocus={()=>{state.focus=true;inputprops.onFocus();reRender();}}
                value={state.focus?state.inputValue: (state.selected && props.getName(state.selected) || "")}
                className="select-search__input"
            />
            <button hidden={state.focus || !state.selected} type="button" onClick={cleanValue} ><i className="fa fa-times"></i></button>
            </Col>
            </Row>
        )
    }
    const finalOptions=[...state.options];
    if (props.popupButton){
      finalOptions.push({popupButton:true,value:"popupButton",name:"propButton"})
    }
    return (
      <div className="row">
        <div className="col">
        {props.label && <label >{props.label}</label>}
           {!state.tableMode &&  <SelectSearch
            options={finalOptions}
            value={props.input.value?.id?.toString() || ""}
            inputValue={state.inputValue}
            onChange={handleChange}
            onInputChange={handleChangeInput}
            renderValue={renderInput}
            search
            placeholder={props.placeHolder || "Seleccione una opción"}
            renderOption={(domProps,option,snapshot,className)=>{
              if (option.popupButton){
                  return <center><button type="button" className="btn btn-primary" onClick={()=>reRender({tableMode:true})}>
                    Mostrar Mas</button></center>
                  
              }
              else {
                return <div className={className} 
                onClick={()=>{
                  handleChange(option.value)
                }}>{option.name}</div>;
              }
            }}
            ></SelectSearch>}
            {state.tableMode && 
            <Modal size="lg" show={state.tableMode} onHide={()=>reRender({tableMode:false})}>
              <Modal.Header>{props.label}</Modal.Header>
              <Modal.Body>
                <FormGroup>
                <input 
                    className="form-control"
                   onChange={(e)=>{
                     
                    handleChangeInput(e.target.value)}}
                   value={state.inputValue || ""}
                   />
                </FormGroup>
                <UtilTable 
                  searchItems={async (page,size,criteria)=>{
                    const result= await props.getOptions(state.inputValue,page)
                    state.options=result.data.map(createOption)
                    return {
                      ...result,
                      data:state.options,
                    };
                  }}
                  go={[
                    {
                      icon:"fa fa-check",
                      tooltip:"Seleccionar",
                      go:(data)=>{
                        reRender({tableMode:false})
                        handleChange(data.value);
                        
                      }
                    }
                  ]}
                  pageSize={props.pageSize}
                  table={BasicTable}
                  resetPage={state.searchCount}
                />
              </Modal.Body>
            </Modal>
            }
        </div>
      
    </div>
  );
}