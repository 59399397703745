import React, { useEffect,useState} from "react";
import { useDispatch } from "react-redux";
import {Col, Row} from 'react-bootstrap'
import Form,{Field} from "src/app/util/form/form.jsx"
import SelectSearch from "src/app/util/SelectSearch/SelectSearch";

  const options=[
    {id:1,nombre:"Departamento",enum:"Departamento"},
    {id:2,nombre:"Estación Meteorológica",enum:"EM"},
    {id:3,nombre:"Distrito",enum:"Distrito"},
    {id:4,nombre:"Cuenca", enum:"Cuenca"},
    {id:5,nombre:"Inspeccion",enum:"Organismo"},
  ]
export default function (props){
  return (
    <div>
           
        <SelectSearch
            input={props.input}
            keyId={"id"}
            label="Criterio de busqueda"
            getName={(item)=>item.id+"-"+item.nombre}
            getOptions={async (string)=>{
              return options
            }}
            rescueOption={async (obj)=>{
              return options.find((e)=>e.id==obj.id)
            }}
            placeHolder="Seleccione"
                    
            value={(e)=>props.setSelect(e.props.input.value.nombre)}
            //onChange={selectValue}
          />
        
    </div>
   
  )
}