import React, { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";
import {
   Container,
    Col,
    Row,
    Button
  } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './criterial.css'
import { CommonSeriesSettingsHoverStyle } from "devextreme-react/chart";

const translate = {
    "equals" : "igual",
   "lessThan" : "menor que",
   "greaterThan" : "mayor que",
}
function Component(props){
    const {component:Element,...rest}=props;
    return <Element {...rest}/>
}
export default function Criterial(props){
    const [state]=useState({
        filters:[],
        newAttr:props.options?.at(0).name ||  props.options?.at(0) || "",
        optionSelected:props.options?.at(0) || undefined,
        newOpe:"equals",
        indexAttr:0,
        newVal:"",
        advanced:false,
        resets:0,
        ignored:[],
    });
    const [refreshs,setRefreshs]=useState(0);
    const reRender=()=>setRefreshs(Math.random());
    function getFilterByInputValue(){
        const filters=props.input.value;
        if (!filters)return []
        const result=[];
        const lastignored=state.ignored;
        state.ignored=[];
        let setAgain=false;
        for (const ignored of lastignored){
            const option=props.options.filter((e)=> e.name? e.name==ignored.newAttr: e==ignored.newAttr)[0];
           
            if (!option.ignore){
                setAgain=true;
                ignored.option=option;
                result.push(ignored)
            }
        }
        for (const filterkey in filters){
            const filtervalue=filters[filterkey]
            const terms=filterkey.split(".");
            const operator=terms.pop();
            const attr=terms.join(".")
            if (operator=="sort"){
                result.push({
                    newAttr:"sort",
                    newOpe:"",
                    newVal:filtervalue,
                    option:props.options[0],
                })
            }
            else {
                const obj={
                    newAttr:attr,
                    newOpe:operator,
                    newVal:filtervalue,
                    option:props.options.filter((e)=> e.name? e.name==attr: e==attr)[0]
                
                };
                if (!obj.option){
                    //El valor no tiene una correspondencia con una opcion, asi que se ignora.
                    //Pero el componente padre cree que existe un atributo con esa option
                    setAgain=true;
                    continue;
                }
                if (obj.option.ignore){
                    //Hay un valor seteado en una opcion ignorada, asi que hay que setear el input
                    //para que el componente padre sepa de los nuevos valores. 
                    state.ignored.push(obj)
                    setAgain=true;    
                    continue;
                }
                if (obj.option.attrDir){
                    obj.newVal={}
                    obj.newVal[obj.option.attrDir]=filtervalue;
                }
                result.push(obj)
            }
        }

        //Puede que se haya seteado inputs que tiene onchange seteados y hay que ejecutar el evento.
        for (const filter of result){
            if (!state.filters.filter(e=>e.newAttr==filter.newAttr && e.newOpe==filter.newOpe && e.newVal==filter.newVal)[0]){
                if (typeof filter.option.onChange=="function"){
                    try {
                        filter.option.onChange(JSON.parse(JSON.stringify(props.input.value)))
                    }
                    catch(e){
                        console.error(e)
                    }
                }
            }
        }
        state.filters=result;
        if (setAgain){
            createValue();
        }
        else {
            reRender();
        }
        
    }

    useEffect(()=>{
        //El value está vacio y hay filtros seteados significa que hay que limpiar los filtros
        const oldfilters=state.filters;
        const readyOptions=[];
        state.filters=[];
        for (const filter of oldfilters){
            if (!filter.option.onChange)continue;
            if (filter.option.ignore)continue;
            if (readyOptions.find((e)=>e==(filter.option.name || filter.option)))continue;
            try {
                filter.option.onChange(JSON.parse(JSON.stringify(props.input.value)))
            }
            catch(e){
                console.error(e)
            }
            readyOptions.push(filter.option.name || filter.option)
        }
        
        reRender();
    },[Object.keys(props.input.value).length==0 && state.filters.length? ++state.resets:state.resets])
    useEffect(()=>{
        getFilterByInputValue()
        
    },[JSON.stringify(props.input.value)])
    function createValue(){
        DeleteRepeatedElements()
        deleteEmptyFilters()
        const newvalue={};
        for (const filter of state.filters){
            if (filter.option?.ignore)continue;
            if (!filter.newVal)continue;
            const value= filter.option?.attrDir? filter.newVal[filter.option.attrDir]: filter.newVal;
            if (filter.newAttr=="sort"){
               
                newvalue[filter.newAttr]=filter.newVal;
            }
            else if (state.advanced){
                newvalue[filter.newAttr+"."+filter.newOpe]=value;
            }
            else {
                newvalue[filter.newAttr+"."+(filter.option.defaultOpe || "equals")]=value;
            }
        }
        props.input.onChange(newvalue);
        return newvalue;
    }
    function onChangeAttr(event){
        const option=props.options[event.target.value];
        state.newAttr=option.name || option;
        state.indexAttr=event.target.value;
        state.optionSelected=option;
        state.newVal="";
        reRender()

    }
    function DeleteRepeatedElements(){
        for (let i=0;i<state.filters.length;i++){
            const elemi=state.filters[i];
            let repeated=false;
            for (let j=i+1;j<state.filters.length;j++){
                const elemj=state.filters[j];
                if (elemi.newAttr==elemj.newAttr && elemi.newOpe==elemj.newOpe){
                    repeated=true;
                    break;
                }
            }
            if (repeated){
                state.filters.splice(i,1);
                i--;
            }

        }
    }
    function tryToGetADate(){
        
        if (!state.newVal) return null;
        try {
            return new Date(state.newVal)
        }
        catch(e){
            state.newVal="";
            return null;
        }
    }
    function tryToGetADate2(value){
       
        if (!value) return null;
        try {
            return new Date(value)
        }
        catch(e){
            return null;
        }
    }
    function addCriterial(){
        const {newAttr,newOpe,newVal,optionSelected}=state;
        if (newOpe=="sort"){
            let filter=state.filters.find(e=>e.newAttr=="sort");
            if (filter){
                filter.newVal+="&sort="+newAttr+","+newVal;
            }
            else {
                state.filters.push({
                    newAttr:"sort",
                    newOpe:"",
                    newVal:newAttr+","+newVal,
                    option:optionSelected
                });
            }
        }
        else {
            state.filters.push({
                newAttr,
                newOpe:state.advanced?newOpe:(optionSelected.defaultOpe || "equals"),
                newVal,
                option:optionSelected
            });
        }
        
        const newvalue=createValue();
        if (typeof optionSelected.onChange=="function"){
            try {
                optionSelected.onChange(JSON.parse(JSON.stringify(newvalue)))
            }
            catch(e){
                console.error(e)
            }
        }
        reRender();
    }
    function deleteEmptyFilters(){
        state.filters=state.filters.filter((e)=>typeof e.newVal!="string" || e.newVal.length>0)
    }
    function onChangeModoSimple(value,option){
        
        state.filters.push({
            newAttr:option.name || option,
            newOpe:option.defaultOpe || "equals",
            newVal:value,
            option:option
        });
        const newvalue=createValue();
        if (typeof option.onChange=="function"){
            
            try {
                option.onChange(JSON.parse(JSON.stringify(newvalue)))
            }
            catch(e){
                console.error(e)
            }
        }
    }
    function getFilterValueByName(name,ope){
        
        const elem=state.filters.find(e=>e.newAttr==name && e.newOpe==ope);
        if (!elem)return "";
        return elem.newVal;
    }
    function onlyEquals(){
        state.filters=state.filters.filter(e=>e.newOpe==(e.option.defaultOpe || "equals"));
    }
    function changeMode(){
        state.advanced=!state.advanced;
        if (!state.advanced){
            onlyEquals()
            createValue();
        }
        try {
            if (props.onChangeMode){
                props.onChangeMode(state.advanced);
            }
        }
        catch(e){
            console.error(e)
        }
        reRender();
    }
    function getIsoDate(value,time=false){
        const date= new Date(value).toISOString();
        if (time){
          return date
        }
        else {
          return date.split("T")[0];
        }
    }
    function clearInputs(){
        state.filters=[];
        createValue();
        reRender();
    }
    function getFilterValue(elem){
        if (!elem.newOpe){
            return elem.newVal.split("&sort=").join(" ")
        }
        if (elem.option?.type=="date"){
            if (typeof elem.newVal=="string"){
                return new Date(elem.newVal).toLocaleDateString("es-AR");
            }
            else {
                return elem.newVal.toLocaleDateString("es-AR");
            }
        }
        else {
            return elem.option?.attrDir ? elem.newVal[elem.option.attrDir] || elem.newVal : elem.newVal;
        }
    }
    return (
        <div className={props.className}>
            <div className="btn-criterial-modo">  
                <Col className="mt-4">
                    {props.mode &&
                        <button onClick={changeMode} className="btn-outline-advanced mb-2 mt-2" type="button">
                            {state.advanced ? "Modo Simple":"Modo Avanzado"}
                        </button>
                    }
                </Col>
            </div>

            {(!props.deployed || state.advanced) &&
            <>
                <Row>
                    <Col lg={3} md={6} xs={12} className="select-criterial">
                        <label>Seleccionar</label>
                        <select  value={state.indexAttr} 
                        onChange={onChangeAttr}
                        >
                            {props.options?.filter(e=>!e.ignore).map((elem,index)=>
                            <option value={index} key={index}>{elem?.label || elem?.name || elem}</option>) || ""}
                        </select>
                    </Col>
                    {state.advanced &&
                    <Col lg={3} md={6} xs={12} className="select-criterial">
                        <label>Seleccionar</label>{/*esto es igual mayor que*/}
                        <select value={state.newOpe} 
                        onChange={(event)=>{
                            state.newOpe=event.target.value;
                            if (state.newOpe=="sort"){
                                state.newVal="desc"
                            }
                            reRender()}}
                        >
                            
                            <option value="equals" > Igual</option>
                            {["ordinal","date"].includes(state.optionSelected.type) && [
                                <option value="lessThan"  key="lessThan"> Menor que</option>,
                                <option value="greaterThan"  key="greaterThan"> Mayor que</option>,
                            ]}
                            {state.optionSelected.type=="string" && [
                                <option value="contains" key="contains">Contiene</option>,
                                <option value="doesNotContain" key="doesNotContain">No contiene</option>,
                            ]}
                            {[
                                <option key="order" value="sort">Ordenar</option>,
                            ]}
                        </select>
                    </Col>
                    }

                    <Col lg={3} md={6} sm={12}>      
                        {
                        
                        state.newOpe=="sort"?
                        <div>
                            <label>Ordenar:</label><br/>
                            <select value={state.newVal} 
                            onChange={(e)=>{state.newVal=e.target.value;reRender()}}>
                                <option value="desc"> Descendente</option>
                                <option value="asc"> Ascendente </option>
                            </select>
                        </div>
                        :state.optionSelected.component?                
                        <div>
                            {!state.optionSelected.SelectServicio  ? "" : <label>{props.label}</label> }                    
                            <Component 
                                component={state.optionSelected.component}                      
                                input={{
                                    value:state.newVal,
                                    onChange:(value)=>{
                                        state.newVal=value;
                                        reRender();
                                    }
                                }}
                            />                    
                        </div>

                        :state.optionSelected.type=="date"? 
                            <div className="input-only"> 
                                <label>{state.optionSelected.label}</label>
                                <div>
                                <DatePicker  
                                onChange={(value)=>{state.newVal=getIsoDate(value);reRender()}}
                                selected={tryToGetADate()}
                                dateFormat={state.optionSelected.dateFormat || "yyyy-MM-dd"}
                                />
                                </div>
                            </div>
                        :                     
                        <div className="input-only"><label>{props.label}</label><br></br>
                        <input value={state.newVal} 
                        onChange={(event)=>{state.newVal=event.target.value;reRender()}}/>
                        </div>
                        }
                    </Col>
                    <Col lg={2} md={6} xs={12}>
                        <button onClick={addCriterial}
                        type="button"
                        className="btn btn-secondary"
                        disabled={!state.newVal || !state.newAttr || !state.newOpe }
                        > Agregar </button>
                    </Col>
                
                
            </Row>
            <Row>
                <Col className="mt-3">
                { state.filters.map((elem,index)=>{
                    return (
                        <div key={index}>
                            <li>
                            {!elem.newOpe && "Orden" || elem.option?.label || elem.newAttr} {" "}
                            {state.newOpe? 
                                translate[state.advanced ? elem.newOpe: elem.option.defaultOpe || "equals"] 
                                || (state.advanced ? elem.newOpe:  elem.option.defaultOpe ||"equals")
                            :""}
                            {" "}
                            {getFilterValue(elem)}
                            <button className="delete-btn" onClick={()=>{state.filters.splice(index,1);createValue();reRender()}}> 
                            <i class="fa fa-times"></i>
                            </button>
                            </li>
                        </div>
                    )
                })}
                </Col>
            </Row>
        </>}
        {props.deployed && !state.advanced &&
            <Row>
               {props.options.filter(e=>!e.ignore).map((elem,index)=>{
                   
                    return (
                        <Col lg={3} md={6} sm={12} key={index}>
                            {
                            elem.component?                
                            <div>
                                {!elem.SelectServicio  ? "" : <label>{elem.label}</label> }                    
                                <Component 
                                    component={elem.component}                      
                                    input={{
                                        value: getFilterValueByName(elem.name || elem,elem.defaultOpe || "equals"),
                                        onChange:(value)=>{onChangeModoSimple(value,elem)
                                    }}}
                                />                    
                            </div>

                            :elem.type=="date"? 
                                <div className="input-only">
                                    <label>{elem.label}</label>
                                    <div>
                                        <DatePicker 
                                        onChange={(value)=>{onChangeModoSimple(getIsoDate(value),elem)}}
                                        selected={tryToGetADate2(getFilterValueByName(elem.name || elem,elem.defaultOpe || "equals"))}
                                        dateFormat={elem.dateFormat || "yyyy-MM-dd"}
                                        />
                                    </div>
                                </div>
                            :                     
                            <div className="input-only">
                            <label>{elem.label || elem}</label>
                            <br></br>
                            <input 
                                value={getFilterValueByName(elem.name || elem,elem.defaultOpe || "equals")}
                                onChange={(evt)=>{onChangeModoSimple(evt.target.value,elem)}}
                            />
                            </div>
                            } 
                        </Col>
                    )

               })}
            </Row>
        }
        {
            props.clearButton && 
            <Row>
                <Col lg={3} md={6} sm={12} className="btn-limpiar-criterial">  
                    <button 
                    type="button" 
                    className="btn btn-outline-secondary mt-3"
                    onClick={()=>clearInputs()}
                    > <i className="fa fa-eraser" /> Limpiar</button>
                </Col>
            </Row>
        }
    </div>
    )
}