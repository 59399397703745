import React, { useState, useEffect } from 'react'
import 'devextreme/data/odata/store';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import Chart, {
    Point,
    ValueAxis,
    ArgumentAxis,
    CommonPaneSettings,
    Grid,
    Series,
    Legend,
    Size,
    Border,
    Tooltip,
    ScrollBar,
    CommonSeriesSettings,
    ZoomAndPan,
    LoadingIndicator
} from 'devextreme-react/chart';
import PieChart, {
    Connector
} from 'devextreme-react/pie-chart';
import { Button } from 'react-bootstrap'
import DataGrid, { Column, Label, TotalItem, Summary, Export, GroupPanel } from "devextreme-react/data-grid";
import "moment/locale/es";
import { Col } from 'react-bootstrap'

export default function (props) {

    const [state] = useState({ headTitle: "", lista: [], series: [], columns: [], argumentField: "", type: "", selectedListIndex: 0, customizeText: "", title: "" });
    const [show, setShow] = useState(false);
    const [botonMedida, setBotonMedida] = useState(false);
    const [mostrar, setMostrar] = useState(false)
    const handleShow = () => {
        setShow(!show);
    }
    const [listaFiltrada, setlistaFiltrada] = useState(props.lista[0].datos);
    console.log(listaFiltrada)
    //const [onExporting, setOnExporting] = useState("");

    useEffect(() => {
        state.selectedOption=props.lista[state.selectedListIndex];
        setlistaFiltrada(props.lista[state.selectedListIndex].datos)
    }, [props.lista])


    return (
        <div className="row">
            <Col lg={9} md={9} sm={12}>
                {show ? <>
                    <DataGrid
                        width="100%"
                        showBorders={true}
                        dataSource={listaFiltrada}
                        allowColumnReordering={true}
                        columnHidingEnabled={true}
                        wordWrapEnabled={true}
                        noDataText="No hay datos para mostrar"
                        //onExporting={onExporting()}
                        title1={props.title1}
                        tableChart={props.tableChart}
                    >
                        {/* <GroupPanel visible={true} emptyPanelText={props.emptyPanelText} />    */}
                        <Export enabled={true} />
                        {
                            !props.renderGridRow ? props.columns?.map((item) => {

                                return (
                                    <Column alignment="left"
                                        key={item.dataField}
                                        dataField={item.dataField}
                                        caption={item.caption}

                                    />
                                )
                            }) : props.renderGridRow(state)
                        }

                    </DataGrid>
                    {props.tableChart ? <div className="mt-1">
                        <Button onClick={() => props.setUnidad((props.unidad + 1) % 3)} className="btn-resultado">{props.unidad == 0 ? "Convertir a m3/ha" : props.unidad == 1 ? "Convertir a l/m2" : props.unidad == 2 ? "Convertir a mm" : ""}</Button>
                    </div> : ""}
                </>
                    :
                    !props.pieChart ? <Chart
                        title={props.title}
                        dataSource={listaFiltrada}
                        palette="Ocean"
                    >
                        <ScrollBar visible={true} />

                        <LoadingIndicator enabled={true} />

                        <Size height={500} />

                        <ValueAxis valueType="numeric">
                            <Grid opacity={0.2} />
                            <Label customizeText={props.customizeText} />
                        </ValueAxis>

                        <ArgumentAxis type="discrete" >
                            {/* <Label displayMode="stagger"></Label> */}
                            <Grid visible={true} opacity={0.5} />
                        </ArgumentAxis>

                        <CommonPaneSettings>
                            <Border
                                visible={true}
                                width={2}
                                top={false}
                                right={false}
                            />
                        </CommonPaneSettings>

                        <CommonSeriesSettings
                            argumentField={props.argumentField}
                            type={props.type}
                            barPadding={0}
                        />


                        {
                            props.series.map((item) => {
                                return (
                                    <Series
                                        key={item.name}
                                        valueField={item.valueField}
                                        name={item.name}
                                        color={item.color}
                                        type={item.type}
                                        label={item.label}

                                    />
                                )
                            })
                        }

                        <Legend
                            visible={true}
                            verticalAlignment="bottom"
                            horizontalAlignment="center"

                        />
                        <Export enabled={true} />
                        <Tooltip
                            enabled={true}
                        />
                        <LoadingIndicator enabled={true} />
                    </Chart>
                        :
                        <PieChart
                            title={props.title}
                            id="pie"
                            dataSource={listaFiltrada}
                            palette="Ocean"
                        >
                            <Legend
                                orientation="horizontal"
                                itemTextPosition="right"
                                horizontalAlignment="center"
                                verticalAlignment="bottom"
                                columnCount={4} />
                            <Series
                                argumentField={props.argumentField}
                                valueField={props.valueField}
                                name={props.name}
                                // name={item?.maestroSensorFicticio ? 
                                //     (`${item.maestroSensorFicticio?.nombre} 
                                //     : ${item.nombre}  -  U/m:`) : 
                                //     (item.maestro.nombre + ' : ' + item.tipoSensor.nombre + ' -  U/m: ' + item.unidadMedida.nombre)}

                            >
                                <Label visible={true}>
                                    <Connector visible={true} width={1} />
                                </Label>
                            </Series>

                            <Size />
                            <Export enabled={true} />
                        </PieChart>
                }
            </Col>

            {props.lista.length < 1 ?
                <div>
                    {props.mostrar ? <Button onClick={handleShow} className="btn-resultado">{show ? "Ver gráfico" : "Ver tabla"}</Button> : ""}
                </div> :
                <Col lg={3} md={3} sm={12}>
                    <div className="action">

                        {props.mostrar ? <Button onClick={handleShow} className="btn-resultado">{show ? "Ver gráfico" : "Ver tabla"}</Button> : ""}

                        <hr></hr>
                        <div className="label"><p><strong>Elegir visualización:</strong></p>
                        </div>
                        <select onChange={(e) => {
                            state.selectedListIndex = e.target.value;
                            state.selectedOption=props.lista[e.target.value]
                            setlistaFiltrada(props.lista[e.target.value].datos)
                            if (props.onChangeSelect) {
                                props.onChangeSelect(e.target.value)
                            }
                        }} >

                            {props.lista.map((e, index) => {
                                return <option value={index}>{e.name}</option>
                            })}

                        </select>
                    </div>
                </Col>
            }
        </div>
    )
}