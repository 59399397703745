import axios from "axios";
import { SET_ERROR } from "./errorReducer";
import Swal from "sweetalert2";
import { SubmissionError } from "redux-form";
import { objToQuery } from "../util/lib";
export const reducerName="distritoReducer";
export default function (state = INITIAL_STATE, action){
    if (reducerName!=action.type) return state;
    const newstate=action.fn(state,action);
    if (newstate)return newstate;
    return {...state};
}
const INITIAL_STATE = {
    totalPages:0,
    currentPage:0,
    distrito:null,
    state:{criteria:{}},
};
const example=undefined;
const apiUrl = "/services/servicioshidricos/api/public";
export const buscarDistrito = (page,size,searchProps,withOutDispatch) => async (dispatch) => {
    const obj = objToQuery({
        page: page,
        size: size,
        ...searchProps,
    });
    try {
        const res=await axios.get(`${apiUrl}/distritos${obj}`)
        let cantTotal = parseInt(res.headers["x-total-count"]);
        const result={
            distrito: example || res.data,
            currentPage: page,
            totalPages: Math.ceil(cantTotal / size),
        };
        
        if (withOutDispatch){
            return result
        }
        else {
            dispatch({
                type: reducerName,
                payload:result,
                fn: (state,action) => ({
                    ...state,
                    ...action.payload
                })
            })   
        }

    }  
    catch(error){
        console.error(error)
        if (error.response){ 
            dispatch({ type: SET_ERROR, payload: error.response });
        }
        throw error;
    }
}

export const saveDistrito = (distrito) => async (dispatch) => {
    try {
        if (distrito.id){//editar
            const res=await axios.put(`${apiUrl}/distritos/`,distrito);
        }
        else { //nuevo
            const res=await axios.post(`${apiUrl}/distritos/`,distrito);
        }
    }
    catch(error){
        console.error(error)
        if (error.response){ 
            dispatch({ type: SET_ERROR, payload: error.response });
        }
        throw error;
    }
}
export const deleteDistrito = (distrito) => async (dispatch) => {
    try {
        const res=await axios.delete(`${apiUrl}/distritos/${distrito.id}`);
        Swal.fire("Eliminado Correctamente")
        dispatch({
            type: reducerName,
            payload:distrito,
            fn: (state,action) => ({
                ...state,
                distrito:state.distrito.filter(e=>e.id!=action.payload.id)
            })
        })
    }
    catch(error){
        console.error(error)
        if (error.response){ 
            dispatch({ type: SET_ERROR, payload: error.response });
        }
        throw error;
    }
}

