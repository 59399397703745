import React, { useEffect,useState} from "react";
import { useDispatch } from "react-redux";
import {buscarEstacionMetereologica} from "src/app/reducers/estacionesReducer"
import SelectSearch from "src/app/util/SelectSearch/SelectSearch";

export default function (props){
  const dispatch=useDispatch();
  return (
    <SelectSearch
      popupButton
      pageSize={10}
      input={props.input}
      keyId={"id"}
      label="Estacion Metereológica"
      getName={(item)=>item.id+"-"+item.nombre}
      getOptions={async (string,page)=>{
        const result=await dispatch(buscarEstacionMetereologica(page,10,{"nombre.contains":string},true))
        //return result.estacionMetereologica;
        return {
          ...result,
          data:result.estacionMetereologica,
        };
      }}
      rescueOption={async (obj)=>{
        const result=await dispatch(buscarEstacionMetereologica(0,1,{"id.equals":obj.id},true))
        return result.estacionMetereologica[0];
      }}
      placeHolder="Seleccione una Estacion Metereológica"
      onChange={()=>props.handleSelectSearch}
    />
  )
}