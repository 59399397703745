import React, { useState } from "react";
import { Row, Col, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { usePagination } from "react-table";
import FormFechas from "../programador/FormFechas";
import FormPrecipitacion from "../programador/FormPrecipitacion";
import FormTipoCultivo from "../programador/FormTipoCultivo";
import FormUbicacion from "../programador/FormUbicacion";
import FormUsoCultural from "../programador/FormUsoCultural";
import FormSistemaRiego from "../programador/FormSistemaRiego";
import FormTipoSuelo from "../programador/FormTipoSuelo"
import Resultado from "../programador/Resultado";
import '../../util/main.css'
import "bootstrap/dist/css/bootstrap.min.css";
import FormDemandaRiegoDefinido from "../programador/FormDemandaRiegoDefinido";
//import {MultiStep} from './navMultiStep'
//import StepsButtons from "../programador/StepsButtons";


import ResultadoAside from '../programador/ResultadoAside';
import { cleanLocalStroage } from "src/app/reducers/programadorReducer";
import FormCalendario from "../programador/FormCalendario";
import ResultadoParte2 from "../programador/ResultadoParte2";
const pages = [
    FormUbicacion,
    FormFechas,
    FormTipoCultivo,
    FormPrecipitacion,
    FormSistemaRiego,
    FormUsoCultural,
    Resultado,
    FormTipoSuelo,
    FormCalendario,
    ResultadoParte2,
]
export default function (props) {
    const [state]=useState({
        checked:false,
    })
    const history = useHistory();
    const params = useParams();
    const dispatch = useDispatch();
    const [page, setPage] = useState(params.page * 1 || 1)
    const [valid, setValid] = useState(false);
    const programadorState = useSelector(state => state.programadorReducer); console.log(programadorState)
    //const [pages,setPages]= useState(params.subpage || window.riegoFormSubPage || 0);
    function goNext() {
        setPage(page * 1 + 1);
        setValid(false)
        history.push("/" + (page + 1));
    }
    function goFowards() {
        setPage(page * 1 - 1);
        setValid(false)
        history.push("/" + (page - 1));
    }
    function goTo(n) {
        if (page === n) return
        setPage(n);
        setValid(false)
        history.push("/" + (n));
    }
    const PageComponent = pages[page - 1];
    if (!state.checked){
        if (
            params.page==2 && !programadorState.tipoDeUbicacion || 
            params.page==3 && !programadorState.fechaInicio || 
            params.page==4 && (
                !programadorState.tipoCultivo && !programadorState.kcPersonalizado==true ||
                programadorState.kcPersonalizado==true && programadorState.tipoCultivo?.profundidadRadicular
            ) || 
            params.page==5 && !programadorState.precipitaciones || 
            params.page==6 && !programadorState.precipitaciones || 
            params.page==7 && !programadorState.tipoRiego || 
            params.page==8 && !programadorState.tipoRiego
        )
        {
            window.location.href="/"
            return null;
        }
        state.checked=true;
        
    }
    return (

        <div className="bg-card-form" id="multistepsform">
            <PageComponent goTo={goTo} setValid={setValid} page={page} />
            <Row align="center" className="mt-5 d-flex justify-content-around">
                <Col lg={2} className="mb-4">
                    {page > 1 ?
                        <Button type="primary" onClick={goFowards} style={{ marginRight: 10 }}><i class="fa fa-arrow-left"></i> Anterior</Button> :
                        <button className="btn btn-danger" onClick={() => { dispatch(cleanLocalStroage()); window.location.reload(); }}><i className="fa fa-trash" /> Limpiar</button>
                    }
                </Col>
                <Col lg={8} >
                    <progress min="0" max="10" value={page} />
               
                    {/* {programadorState.tipoRiego?.id === 3 ? <progress min="0" max="15" value={page} /> : <progress min="0" max="11" value={page} />} */}
                    <ul className="lststeps">
                        <li><button type="button" onClick={() => goTo(1)} ><i class="fa fa-map-marker"></i></button></li>
                        <li><button type="button" onClick={() => goTo(2)} disabled={!programadorState.tipoDeUbicacion} className={!programadorState.tipoDeUbicacion ? "disabledButton" : "ableButton"}><i class="fa fa-calendar"></i></button></li>
                        <li><button type="button" onClick={() => goTo(3)} disabled={!programadorState.fechaInicio} className={!programadorState.tipoDeUbicacion ? "disabledButton" : "ableButton"}><i class="fa fa-leaf"></i></button></li>
                        <li><button type="button" onClick={() => goTo(4)} 
                        disabled={(!programadorState.tipoCultivo && !programadorState.kcPersonalizado == true) ||
                        (programadorState.kcPersonalizado == true && programadorState.tipoCultivo?.profundidadRadicular) }
                        className={(!programadorState.tipoCultivo && !programadorState.kcPersonalizado == true) ||
                            (programadorState.kcPersonalizado && !programadorState.tipoCultivo?.profundidadRadicular) ? "disabledButton" : "ableButton"}>
                        <i class="fa fa-cloud"></i></button></li>
                        <li><button type="button" onClick={() => goTo(5)} disabled={!programadorState.precipitaciones} className={!programadorState.precipitaciones ? "disabledButton" : "ableButton"}><i class="fa fa-tint"></i></button></li>
                        <li><button type="button" onClick={() => goTo(6)} disabled={!programadorState.precipitaciones} className={!programadorState.precipitaciones ? "disabledButton" : "ableButton"}><i class="fa fa-user"></i></button></li>
                        <li><button type="button" onClick={() => goTo(7)} disabled={!programadorState.tipoRiego} className={!programadorState.tipoRiego ? "disabledButton" : "ableButton"}><i class="fa fa-list"></i></button></li>

                        <li><button type="button" onClick={() => goTo(8)} disabled={!programadorState.tipoRiego} className={!programadorState.tipoRiego ? "disabledButton" : "ableButton"}><i class="fa fa-square"></i></button></li>
                     

                        <li><button type="button" 
                        onClick={() => goTo(9)} disabled={!programadorState.tipoSuelo || !programadorState.umbralRiego || !programadorState.proporcionSueloMojado} 
                        className={(!programadorState.tipoSuelo || !programadorState.umbralRiego || !programadorState.proporcionSueloMojado) ? "disabledButton" : "ableButton"}><i class="fa fa-calendar"></i></button></li>
                        <li><button type="button" onClick={() => goTo(10)} 
                        disabled={(!programadorState.tipoFrecuenciaRiego || !programadorState.portentajeDerecho || !programadorState.portentajeCultivo )} 
                        className={(!programadorState.tipoFrecuenciaRiego || !programadorState.portentajeDerecho || !programadorState.portentajeCultivo ) ? "disabledButton" : "ableButton"}><i class="fa fa-list"></i>
                        </button></li>




                    </ul>
                </Col>
                <Col lg={2} className="mb-4 order-1">
                    {/* {<Button type="primary" onClick={goNext}
                        disabled={!valid}>Siguiente <i class="fa fa-arrow-right"></i> </Button>} */}
                    {page === 10 ? "" : <Button type="primary" onClick={goNext}
                        disabled={!valid}>Siguiente <i class="fa fa-arrow-right"></i> </Button>}

                </Col>
            </Row>

            {page !== 7 && <ResultadoAside />}
        </div>
    )
}