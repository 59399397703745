import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Modal } from 'react-bootstrap'
import Form, { Field } from "src/app/util/form/form.jsx"
import programadorReducer, { setTipoCultivo, setTipoRiego, setEficiencia, setTipoCultivoPersonalizado } from "src/app/reducers/programadorReducer";
import listSelected from "src/app/util/list-selected";
import SelectSearchTipoCultivo from "../tipoCultivo/SelectSearchTipoCultivo";
import SelectSearchTipoRiego from "../tipoRiego/SelectSearchTipoRiego";
import SelectSearchEficiencia from "../eficiencia/SelectSearchEficiencia";
import GraficoCultivo from "../graficos/GraficoCultivo";
import { Button } from "reactstrap";
import { ColCountByScreen } from "devextreme-react/tree-list";
import listSelectedCompuest from "src/app/util/list-selected-compuest";
import InputKcs from "./InputKcs";
import '../../util/Style.css'



export default function (props) {
    const programdorState = useSelector(state => state.programadorReducer);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false)
    const [mostrarPersonalizado, setMostrarPersonalizado] = useState(programdorState.kcPersonalizado)
    const [, setMostrarSelect] = useState(true)
    const [disabledBtn, setdisabledBtn] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);

    const validate = (value) => {
        const error = {}
        if(value.kcPersonalizado && !value?.tipoCultivo?.profundidadRadicular){
            error.profundidadRadicular = "Campo obligatorio"
        }
        }

    
    function MyModal(props) {
        return (
            <Modal
                size="md"
                {...props}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <p>
                    Determinar el cultivo para el cual se requiere conocer su demanda de agua. 
                    La elección del cultivo nos permite conocer el kc (coeficiente del cultivo) del mismo. 
                    Este parámetro representa la relación que existe entre la Evapotranspiración de Referencia (Eto) 
                    y la Evapotranspiración del Cultivo (Etc).
                    Existe la posibilidad de que, si el usuario quiere cargar un nuevo cultivo, 
                    el mismo deberá introducir los diferentes valores de Kc de cultivo y la profundidad radicular que el mismo posea. 
                    Dicha carga se deberá realizar en la sección Otro cultivo.

                    </p>
                </Modal.Body>
            </Modal>
        );
    }
    return (
        <div>
            {programdorState && <Form
                onSubmit={(values) => {
                    if (mostrarPersonalizado && values.kcs) {
                        dispatch(setTipoCultivoPersonalizado(values.kcs, values.profundidadRadicular))
                    }
                    else if (values.tipoCultivo) {
                        dispatch(setTipoCultivo(values.tipoCultivo))
                    }

                }}
                validation={(values, formprops) => {
                    console.log(values,mostrarPersonalizado)
                    if (!mostrarPersonalizado && values.tipoCultivo || mostrarPersonalizado && values.profundidadRadicular ) {
                        props.setValid(true)
                    }
                    // if(values.kcPersonalizado){
                    //     validate()
                    // }
                    else {
                        props.setValid(false)
                    }
                }}
                saveIn={"calculadoraFormTipoCultivo"}
                changeAndSubmit
                components={(formprops) =>
                    <div>
                        <div className="main-icon"><i class="fa fa-leaf"></i></div>
                        <h1>Cultivo *
                            <button variant="primary" onClick={() => setModalShow(true)} className="btn-light info btn btn-secondary" >
                                <i className={"fa fa-info-circle "}></i>
                            </button>
                            <MyModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                            />
                        </h1>
                        <Row>
                            {mostrarPersonalizado ? <InputKcs/> : setMostrarSelect(false)} 
                            {/* validate={validate} */}

                            {!mostrarPersonalizado &&
                                <Col lg={{ span: 4, offset: 3 }} md={{ span: 3, offset: 3 }} sm={{ span: 12, offset: 1 }}>
                                    <Field
                                        name={`tipoCultivo`}
                                        component={SelectSearchTipoCultivo}
                                        onChange={() => setdisabledBtn(!disabledBtn)}
                                    />
                                </Col>
                            }
                            <Col className="btn-personalizado">
                                {!mostrarPersonalizado &&
                                    <><label>Otro Cultivo</label> <br></br>
                                        <button class={"btn btn-primary btn-sm"}
                                            disabled={disabledBtn}
                                            onClick={() => {
                                                setMostrarPersonalizado(mostrarPersonalizado ? false : true);
                                                formprops.change("personalizado",!mostrarPersonalizado)
                                            }}>Personalizar
                                        </button>
                                    </>
                                }
                            </Col>
                        </Row>
                        <Row className="btn-NOpersonalizado">
                            {mostrarPersonalizado &&
                                <button class={"btn btn-primary btn-sm btn-show"}
                                    onClick={() => {
                                        setMostrarPersonalizado(mostrarPersonalizado ? false : true)
                                        formprops.change("personalizado",!mostrarPersonalizado)
                                    }}> No Personalizar
                                </button>
                            }
                        </Row>
                        <button class={show ? "btn btn-outline-primary btn-sm btn-show" : "btn btn-primary btn-sm btn-show"} onClick={() => setShow(show ? false : true)}>
                            {show ? "Ocultar Gráfico" : "Mostrar Gráfico"}
                        </button>
                        {show ? <GraficoCultivo /> : null}
                    </div>}>
            </Form>
            }

        </div>
    )
}