import React, { useState } from "react";
import { Col, Row, Modal, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from "react-redux";
import Form, { Field } from "src/app/util/form/form.jsx"
import SelectSearchEstacion from "../criterioUbicacion/SelectSearchEstacion";
import SelectSearchDepartamento from "../criterioUbicacion/SelectSearchDepartamento";
import SelectSearchDistrito from '../criterioUbicacion/SelectSearchDistrito';
import SelectSearchCuenca from '../criterioUbicacion/SelectSearchCuenca';
import SelectSearchInspeccion from "../criterioUbicacion/SelectSearchInspeccion";

import { setCriterioUbicacion, setEstacion, setUbicacion, setDepartamento, setEstacionesMeteorologicas, setInspeccion, setPrecipitacion } from "src/app/reducers/programadorReducer";
import { buscarEstacion } from "src/app/reducers/estacionesReducer";

//import listSelected from "src/app/util/list-selected";
import SelectSearchTipoUbicacion from "../ubicacion/SelectSearchTipoUbicacion";
import GroupSelectSearch from "../criterioUbicacion/GroupSelectSearch";
import GraficoUbicacion from "../graficos/GraficoUbicacion";


const components = {
    "Estación Meteorológica": SelectSearchEstacion,
    Distrito: SelectSearchDistrito,
    Departamento: SelectSearchDepartamento,
    Cuenca: SelectSearchCuenca,
    Inspeccion: SelectSearchInspeccion,
}

export default function (props) {
    const dispatch = useDispatch();
    const programadorState = useSelector(state => state.programadorReducer);
    const [select, setSelect] = useState(programadorState.tipoDeUbicacion);
    const handleSelectSearch = (value) => {
        setSelect(value)
    }
    const [show, setShow] = useState(false)
    const [modalShow, setModalShow] = React.useState(false);

    function MyModal(props) {
        return (
            <Modal
                size="md"
                {...props}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <p>
                        Seleccionar la ubicación de su predio o parcela a partir de diferentes criterios de búsqueda
                        (Departamento, Distrito, Inspección de Cauce a la que pertenece, Cuenca o Estación Meteorológica).
                        Una vez establecido el criterio de búsqueda, en el siguiente casillero se selecciona, gracias a un menú desplegable,
                        los diferentes distritos, estaciones, departamentos, etc, dependiendo el criterio seleccionado.

                        Con dicha ubicación el sistema seleccionará las Estaciones Meteorológicas que tienen influencia
                        sobre la ubicación determinada y a partir de las mismas se definirá la Evapotranspiración media de referencia (Eto).
                        También existe la posibilidad de seleccionar una Estación Meteorológica determinada, para realizar los cálculos.

                    </p>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <div>
            {programadorState && <Form
                onSubmit={(values) => {
                    dispatch(setCriterioUbicacion(values))
                    dispatch(setEstacionesMeteorologicas(values)).then((estaciones) => {
                        if (programadorState.precipitaciones?.at(0)) {
                            dispatch(setPrecipitacion({ nombre: programadorState.precipitaciones[0].tipoAnio }, values[values.tipoDeUbicacion?.nombre].cuenca || estaciones[0].cuenca));
                        }
                    })
                    dispatch(setDepartamento(values))
                    dispatch(setInspeccion(values))

                }}
                //initialValues={programadorState}
                changeAndSubmit
                saveIn={"calculadoraFormUbicacion"}
                validation={(values, formprops) => {
                    if (values.tipoDeUbicacion?.nombre && values[values.tipoDeUbicacion?.nombre]) {
                        props.setValid(true)
                    }

                    else {
                        props.setValid(false)
                        return { error: 1 }
                    }
                }}
                components={(formprops) =>
                    <div>
                        <div className="main-icon"><i className="fa fa-map-marker"></i></div>
                        <h1>Ubicación *
                            <button variant="primary" onClick={() => setModalShow(true)} className="btn-light info btn btn-secondary" >
                                <i className={"fa fa-info-circle "}></i>
                            </button>
                            <MyModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                            />
                        </h1>

                        <Row>
                            <Col lg={2}></Col>
                            <Col lg={4}>
                                <Field
                                    name={`tipoDeUbicacion`}
                                    component={SelectSearchTipoUbicacion}
                                    labelAttr="nombre"
                                    select={setSelect}
                                    onChange={handleSelectSearch}
                                />
                            </Col>
                            <Col lg={4}>
                                {select && components[select.nombre] && <Field
                                    name={select.nombre}
                                    component={components[select.nombre]}
                                    labelAttr={select.nombre}
                                    select={setSelect}
                                //onChange={handleSelectSearch}
                                />}
                            </Col>


                            <Col lg={2}></Col>
                        </Row>

                        <button class={show ? "btn btn-outline-primary btn-sm btn-show" : "btn btn-primary btn-sm btn-show"} onClick={() => setShow(show ? false : true)}>
                            {show ? "Ocultar Gráfico" : "Mostrar Gráfico"}
                        </button>
                        {show ? <GraficoUbicacion /> : null}

                    </div>
                }
            >
            </Form>}
        </div>
    )
}
