import React, { useState } from 'react'
import { Column } from "devextreme-react/data-grid"
import { useSelector } from 'react-redux';

import 'devextreme/data/odata/store';

import "moment/locale/es";
import { Button, Col } from 'reactstrap';
import GraficoGenerico from "src/app/util/graficoGenerico/GraficoGenerico";
import { renderRoutes } from 'react-router-config';
const ChangeUnidad = (unidad, value) => {
  return unidad == 0 ? value
    : unidad == 1 ? value * 10 : unidad == 2 ? value : ""
}
const GraficoResultado = (props) => {
  const programadorState = useSelector(state => state.programadorReducer);
  const lista0 = programadorState.resultado['datosXDia'];
  const lista1 = programadorState.resultado['datosXMes'];
  const lista2 = programadorState.resultado['datosXMesPeriodo'];
  const totalAnual = programadorState.resultado['totalAnual'];
  const totalPeriodo = programadorState.resultado['totalPeriodo'];
  const [pieChart, setPieChart] = useState();
  const [show, setShow] = useState(false);
  const [showConvertir, setShowConvertir] = useState(false);
  const [unidad, setUnidad] = useState(0);
  const unidadLabel = ["mm", "m3/ha", "l/m2"][unidad];
  const s = show ? "" : "SP";
  const handleShow = () => {
    setShowConvertir(!show);
  }
  // const MostrarBtn = () => {
  //   return <div className="mt-1">
  //     <Button onClick={() => setUnidad((unidad + 1) % 3)} className="btn-resultado">{unidad == 0 ? "Convertir a m3/ha" : unidad == 1 ? "Convertir a l/m2" : unidad == 2 ? "Convertir a mm" : ""}</Button>
  //   </div>
  // }
  const valorPieChart = [
    { codigo: "totalDefensaHelada" + s, label: "Lucha contra helada", name: "Heladas" },
    { codigo: "totalLavadoSuelo" + s, label: "Lavado de suelo", name: "Lavado" },
    { codigo: "totalLexiviacion" + s, label: "Req. Lixiviación", name: "Lexiviación" },
    { codigo: "totalPrepTerre" + s, label: "Prep. terreno", name: "Terreno" },
    { codigo: "perdidaLixiviacion" + s, label: "Perdida", name: "Perdida" },
    show ? { codigo: "nN", name: "Demanda Neta", label: "Demanda" } : { codigo: "etc", name: "Demanda Total", label: "Demanda" }
  ]
  function calcSerie() {
    if (show) {
      return { name: "Requerimiento", valueField: "nN", label: "Demanda" };
    } else {
      return { name: "Demando Total", valueField: "etc", label: "Demanda" };
    }
  }
  const customizeLabel = (e) => {
    return `${e.valueText} mm`
  }

  return (
    <div>
      <Button onClick={() => setShow(!show)} className="btn-resultado">{show ? "Demanda Total " : "Requerimiento"}</Button>
      <GraficoGenerico
        lista={[
          
          { datos: lista2, name: "Meses filtrado por período" },
          { datos: lista0, name: "Diario",diario:true },
          { datos: lista1, name: "Meses"}
          ,
          {
            datos: valorPieChart.map(e => ({
              argumentField: (`${e.label}: <b>${totalAnual[e.codigo]}</b>`),
              valueField: totalAnual[e.codigo],
              label: e.label,              
            })), name: "Total Anual"
          },
          {
            datos: valorPieChart.map(e => ({
              argumentField: (`${e.label}: <b>${totalPeriodo[e.codigo]}</b>`) ,
              valueField: totalPeriodo[e.codigo],
              label: e.label,         
            })), name: "Total Período"
          }
        ]}

        title={show ?  "Requerimiento" : "Demanda Total" }
        customizeText={customizeLabel}
        argumentField={!pieChart ? "fechaSimple" : "argumentField"}
        valueField="valueField"
        type="stackedbar"
        onChangeSelect={(value) => {
          if (value == 2 || value == 3) {
            setPieChart(true)
          } else {
            setPieChart(false)
          }
        }}
        pieChart={pieChart}
        tableChart={true}
        setUnidad={setUnidad}
        unidad={unidad}
        //series={series}
        series={[
          calcSerie(),
          { name: "Heladas", valueField: "totalDefensaHelada" + s },
          { name: "Lavado", valueField: "totalLavadoSuelo" + s },
          { name: "Lixiviación", valueField: "totalLexiviacion" + s },
          { name: "Terreno", valueField: "totalPrepTerre" + s },
          { name: "Perdida", valueField: "perdidaLixiviacion" + s }
        ]
        }
        mostrar={!props.mostrar}
        renderGridRow={(state) => {

          if (pieChart == true) {
            return [<Column width="150" caption="Atributo" dataField="argumentField" cellRender={(row) => row.data.label} />,
            <Column width="150" caption="Valor" dataField="valueField" />,
            ]
          } else {
            return [
              <Column width="120" caption="Fecha" dataField="fechaSimple" />,
              show ? <Column caption={"Demanda Neta " + unidadLabel} dataField="nN" cellRender={(row) => {
                return ChangeUnidad(unidad, row.data.nN)
              }}
              />
                : <Column width="80" caption={"Demanda Total " + unidadLabel} dataField="etc" cellRender={(row) => {
                  return ChangeUnidad(unidad, row.data.etc)
                }}
                />,
              <Column width="80" caption={"Lavado " + unidadLabel} dataField="totalLavadoSuelo" cellRender={(row) => {
                return ChangeUnidad(unidad, row.data["totalLavadoSuelo" + s])
              }} />,
              <Column width="80" caption={"Perdida " + unidadLabel} dataField={state.selectedOption?"perdida":"perdidaLixiviacion"}  cellRender={(row) => {
                return ChangeUnidad(unidad, state.selectedOption.diario? row.data["perdida" + s]:row.data["perdidaLixiviacion" + s]  )
              }} />,
              <Column width="80" caption={"Lixiviación " + unidadLabel} cellRender={(row) => {
                return ChangeUnidad(unidad, row.data["totalLexiviacion" + s])
              }} />,
              <Column width="80" caption={"Prep. Terreno " + unidadLabel} dataField="totalPrepTerre" cellRender={(row) => {
                return ChangeUnidad(unidad, row.data["totalPrepTerre" + s])
              }} />,
              <Column width="80" caption={"Lucha Heladas " + unidadLabel} dataField="totalDefensaHelada" cellRender={(row) => {
                return ChangeUnidad(unidad, row.data["totalDefensaHelada" + s])
              }} />,

            ]

          }
        }}
      >

      </GraficoGenerico>

    </div>
  )
}

export default GraficoResultado