import React, { useEffect, useState } from "react";
import { Col, Row, Modal, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from "react-redux";
import { setFechas, setTipoRiego } from "src/app/reducers/programadorReducer";
import DatePicker from "src/app/util/datepicker/datePicker.jsx";
import Form, { Field } from "src/app/util/form/form.jsx"
import Alert from 'react-bootstrap/Alert';
import Grafico from "../tipoCultivo/Grafico";
import '../tipoSuelo/styleTipoSuelo.css'
const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];

function renderCustomHeaderDatePikcer(props) {
    return (
        <Row >
            <Col>
                <button type="button" className="btn" onClick={props.decreaseMonth} disabled={props.date.getMonth() == 0}>
                    <i class="fa fa-chevron-left" aria-hidden="true"></i>
                </button>
            </Col>
            <Col>
                <button className="btn" type="button" disabled>
                    <b>{months[props.date.getMonth()]}</b>
                </button>
            </Col>
            <Col>
                <button type="button" className="btn" onClick={props.increaseMonth} disabled={props.date.getMonth() == 11}>
                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                </button>
            </Col>
        </Row>
    )
}

export function ValidRiegoDefinido(values) {
    return values.fechaProximoRiego && values.fechaUltimoRiego;
}

export default function (props) {
    const programadorState = useSelector(state => state.programadorReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        props.setValid(true)
    }, [])
    return (
        <div>
            {programadorState && <Form
                initialValues={programadorState}
                //saveIn={"calculadoraFormTipoSuelo"}
                validation={(values, formprops) => {
                    const inicio = new Date(values.fechaUltimoRiego);
                    inicio.setFullYear(2024);
                    const fin = new Date(values.fechaProximoRiego);
                    fin.setFullYear(2024);
                    dispatch({
                        type: "programadorReducer", fn: (state) => ({
                            ...state,
                            fechaUltimoRiego: inicio,
                            fechaProximoRiego: fin
                        })
                    })
                    if (values.fechaProximoRiego && values.fechaProximoRiego) {
                        props.setInnerValid(true)
                    }
                }}

                components={(formprops) =>
                    <div>
                        <div className="main-icon"><i class="fa fa-calendar"></i></div>
                        <h1>Demanda para un Riego Definido</h1>
                        <Row className="justify-content-center">
                            <Col lg={4} sm={12}>

                                <Field
                                    label="Último Riego"
                                    name={`fechaUltimoRiego`}
                                    component={DatePicker}
                                    renderCustomHeader={renderCustomHeaderDatePikcer}

                                />
                            </Col>
                            <Col lg={1} sm={1} />
                            <Col lg={4} sm={12}>

                                <Field
                                    label="Próximo Riego"
                                    name={`fechaProximoRiego`}
                                    component={DatePicker}
                                    renderCustomHeader={renderCustomHeaderDatePikcer}
                                />
                            </Col>

                        </Row>
                    </div>
                }
            >
            </Form>}
            <div>
                <Row>

                    <Col lg={12} sm={12} className="resultadoBgGris  d-flex justify-content-around">
                        {/* separar items en columna */}
                        <li><b>Días del período:</b> {programadorState.resultado.datosXDiaPeriodoEntreRiegos.length}</li>
                        <li><b>Días con déficit:</b> {programadorState.resultado.diasEnDeficit}</li>
                        <li><b>Demanda neta:</b> {Math.round(programadorState.resultado.totalPeriodoEntreRiegos.nN?.toFixed(2)) + " " + "mm"}</li>
                        <li><b>Lámina de reposición: </b>{
                            Math.round(Math.min(programadorState.resultado.totalPeriodoEntreRiegos.nB * programadorState.eficiencia / programadorState.tipoRiego.eficiencia,
                                programadorState.resultado.humedadUtil)) + " " + "mm"}</li>

                    </Col>
                </Row>
            </div>

        </div>
    )
}
