import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Modal } from 'react-bootstrap'
import Form, { Field } from "src/app/util/form/form.jsx"
import { setUsoCultural } from "src/app/reducers/programadorReducer";
import listSelected from "src/app/util/list-selected";
import SelectSearchUsoCultural from "../uso/SelectSearchUsoCultural";
import listSelectedCompuest from "src/app/util/list-selected-compuest";
import ComboMesSelect from "src/app/util/ComboMesSelect";
import NumberInput from "src/app/util/numberInput/NumberInput";

const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];
//import GraficoCultivo from "../graficos/GraficoCultivo";

export default function (props) {
    const programdorState = useSelector(state => state.programadorReducer);
    const dispatch = useDispatch();
    const [uso, setUso] = useState()
    const [modalShow, setModalShow] = React.useState(false);
    function MyModal(props) {
        return (
            <Modal
                size="md"
                {...props}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <p>
                    Cargar los usos culturales que se realizan en el predio, 
                    que demandan una cantidad extra de agua, siempre y cuando estas 
                    demandas superen las demandas fisiológicas propias de la planta. 
                    Es decir, considera que parte del agua necesaria para cubrir estos usos culturales, 
                    ya ha sido provista por el agua de las necesidades de riego del cultivo.
                    </p>
                </Modal.Body>
            </Modal>
        );
    }
    return (
        <div>
            {programdorState && <Form
                onSubmit={(values) => {
                    if (values.usosCulturales) dispatch(setUsoCultural(values))

                }}
                validation={(values, formprops) => {
                    if (values.usosCulturales) {
                        props.setValid(true)
                    }
                    else {
                        props.setValid(true)
                    }
                }}
                initialValues={programdorState}
                changeAndSubmit
                components={(formprops) =>
                    <div>
                        <div className="main-icon"><i className="fa fa-user"></i></div>
                        <h1>Uso Cultural
                            <button variant="primary" onClick={() => setModalShow(true)} className="btn-light info btn btn-secondary" >
                                <i className={"fa fa-info-circle "}></i>
                            </button>
                            <MyModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                            />
                        </h1>
                        <div className="usos">


                            <Field
                                name={`usosCulturales`}
                                component={listSelectedCompuest}
                                renderInput={function (values, onChange, AddToList, added) {
                                    const lucha=programdorState.tipoRiego.id==5 ? [1]:[];
                                    
                                    return <div>
                                        <Row className="usosComponentList mb-3">
                                            <Col lg={4}></Col>
                                            <Col lg={4}><SelectSearchUsoCultural
                                                input={{ value: values.usoCultural, onChange: (value) => onChange("usoCultural", value) }}
                                                criteria={{ "id.notIn":[...added.map(e => e.usoCultural.id),...lucha] }} 
                                                />
                                            </Col>
                                            <Col lg={4}></Col>
                                        </Row>
                                        {values.usoCultural?.nombre?.toLowerCase().includes("requerimiento") ? (<Row>
                                            <Col lg={5} sm={12}>
                                                <label>Conductividad eléctrica del suelo (ds/m)</label>
                                                <NumberInput
                                                    input={{
                                                        value: values.conductividadInicial || "",
                                                        onChange: (value) => onChange("conductividadInicial", value),
                                                    }}
                                                />
                                            </Col>
                                            <Col lg={5} sm={12}>
                                                <label>Conductividad eléctrica del agua (ds/m)</label>
                                                <NumberInput
                                                    input={{
                                                        value: values.conductividadFinal || "",
                                                        onChange: (value) => onChange("conductividadFinal", value),
                                                    }}
                                                />
                                            </Col>
                                            <Col> <button type="button" className="addToList" onClick={AddToList}
                                                disabled={!values.conductividadFinal || !values.conductividadInicial}
                                            >+</button></Col>
                                        </Row>
                                        )
                                            : null
                                        }
                                        {values.usoCultural?.nombre?.toLowerCase().includes("lucha") ? (
                                            <>
                                            <label>Riego por mes </label>
                                            <Row className="justify-content-center">
                                            {Array.from({length:12},(...args)=>(
                                                    <Col sm={4} hidden={args[1]<6 || 10<args[1]}>
                                                        <label>{months[args[1]]} </label>
                                                        <Row>
                                                            <Col sm={6}>
                                                                <NumberInput
                                                                    placeHolder={"Numero"}
                                                                    input={{
                                                                        value: values["riegosMes"+args[1]] || "",
                                                                        onChange: (value) => onChange("riegosMes"+args[1], value),
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col sm={6}>
                                                                <NumberInput
                                                                    placeHolder={"Lámina"}
                                                                    input={{
                                                                        value: values["laminaMes"+args[1]] || "",
                                                                        onChange: (value) => onChange("laminaMes"+args[1], value),
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    
                                            ))}
                                            </Row>
                                            <div align="center">
                                               
                                                    <button
                                                        type="button"
                                                        className="addToList"
                                                        onClick={AddToList}
                                                        >
                                                            +
                                                        </button>
                                                
                                            </div>
                                            </>
                                        )
                                            : null
                                        }
                                        {values.usoCultural?.nombre?.toLowerCase().includes("terreno") ? (<Row>
                                            <Col lg={4} sm={12}>
                                                <label>Riego por mes </label>
                                                <NumberInput
                                                    input={{
                                                        value: values.numeroRiegoXMes || "",
                                                        onChange: (value) => onChange("numeroRiegoXMes", value),
                                                    }}
                                                />
                                            </Col>
                                            <Col lg={3} sm={12}>
                                                <label>Lámina (mm) </label>
                                                <NumberInput
                                                    input={{
                                                        value: values.lamina || "",
                                                        onChange: (value) => onChange("lamina", value),
                                                    }}
                                                />
                                            </Col>
                                            <Col lg={3} sm={12}>
                                                <label>Mes </label>
                                                <ComboMesSelect input={{ value: values.mes, onChange: (value) => onChange("mes", value) }} />
                                            </Col>
                                            <Col> <button type="button" className="addToList" onClick={AddToList}
                                                disabled={!values.numeroRiegoXMes || !values.lamina || !values.mes}
                                            >+</button></Col>
                                        </Row>
                                        )
                                            : null
                                        }
                                        {values.usoCultural?.nombre?.toLowerCase().includes("lavado") ? (<Row>
                                            <Col lg={4} sm={12}>
                                                <label>Conductividad Inicial (ds/m)</label>
                                                <NumberInput
                                                    input={{
                                                        value: values.conductividadInicial || "",
                                                        onChange: (value) => onChange("conductividadInicial", value),
                                                    }}
                                                />
                                                {/* <input onChange={(e) => onChange("conductividadInicial", e.target.value)} value={values.conductividadInicial || ""} /> */}
                                            </Col>
                                            <Col lg={4} sm={12}>
                                                <label>Conductividad Final (ds/m)</label>
                                                <NumberInput
                                                    input={{
                                                        value: values.conductividadFinal || "",
                                                        onChange: (value) => onChange("conductividadFinal", value),
                                                    }}
                                                />
                                                {/* <input onChange={(e) => onChange("conductividadFinal", e.target.value)} value={values.conductividadFinal || ""} /> */}
                                            </Col>
                                            <Col lg={3} sm={12}><label>Mes </label><ComboMesSelect input={{ value: values.mes, onChange: (value) => onChange("mes", value) }} /></Col>
                                            <Col> <button type="button" className="addToList" onClick={AddToList}
                                                disabled={!values.conductividadFinal || !values.conductividadInicial || !values.mes}
                                            >+</button></Col>
                                        </Row>
                                        )
                                            : null
                                        }

                                    </div>
                                }}
                                renderRows={function (rows, borrar, edit) {
                                    console.log(rows)
                                    return rows.map((e, i) => <Row className="row-list">
                                        <Col><strong>{e.usoCultural.nombre}</strong></Col>
                                        {e.usoCultural.nombre.toLowerCase().includes("lavado") ? (
                                            <>
                                                <Col>Cond. Inicial: {e.conductividadInicial}</Col>
                                                <Col>Cond. Final{e.conductividadFinal}</Col>
                                                <Col>Mes: {e.mes}</Col>
                                            </>
                                        )
                                            : e.usoCultural.nombre.toLowerCase().includes("lucha") ? (

                                                <>
                                                    <Col>Lámina: {e.lamina} mm</Col>
                                                </>
                                            )
                                                : e.usoCultural.nombre.toLowerCase().includes("terreno") ? (
                                                    <>
                                                        <Col>Riego por mes: {e.numeroRiegoXMes}</Col>
                                                        <Col>Lámina: {e.lamina}</Col>
                                                        <Col>Mes: {e.mes}</Col>

                                                    </>
                                                )
                                                    : e.usoCultural.nombre.toLowerCase().includes("requerimiento") ? (
                                                        <>
                                                            <Col>Cond. Inicial {e.conductividadInicial}</Col>
                                                            <Col>Cond. Final {e.conductividadFinal}</Col>

                                                        </>
                                                    )
                                                        : null
                                        }
                                        <Col><button className="deleteList" type="button" onClick={() => borrar(i)}><i class="fa fa-times"></i></button></Col>
                                        <Col><button className="deleteList" type="button" onClick={() => edit(i)}><i class="fa fa-pencil"></i></button></Col>
                                        <hr></hr>
                                    </Row>

                                    )
                                }}
                                compare={(a, b) => a.usoCultural.id == b.usoCultural.id/*Esto evita repiticiones en las opciones*/}

                            />


                        </div>
                        {/* <button class={show ? "btn btn-outline-primary btn-sm" : "btn btn-primary btn-sm"} onClick={() => setShow(show ? false : true)}>
                            {show ? "Ocultar Gráfico" : "Mostrar Gráfico"}
                        </button>
                        {show ? <GraficoCultivo /> : null} */}
                    </div>}>
            </Form>
            }

        </div>
    )
}