import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from 'react-bootstrap'
import Form, { Field } from "src/app/util/form/form.jsx"
import hiddenInput from "src/app/util/hidden-input/hiddenInput";

const meses = [
    { codigo: 1, nombre: "Enero" },
    { codigo: 2, nombre: "Febrero" },
    { codigo: 3, nombre: "Marzo" },
    { codigo: 4, nombre: "Abril" },
    { codigo: 5, nombre: "Mayo" },
    { codigo: 6, nombre: "Junio" },
    { codigo: 7, nombre: "Julio" },
    { codigo: 8, nombre: "Agosto" },
    { codigo: 9, nombre: "Septiembre" },
    { codigo: 10, nombre: "Octubre" },
    { codigo: 11, nombre: "Noviembre" },
    { codigo: 12, nombre: "Diciembre" },
];

export default function (props) {
    return (
        <div>
            <Row >
                {meses.map((mes, index) => {
                    return <Col lg={3}>
                        <label>{mes.nombre}</label><br></br>
                        <Field
                            name={`kcs[${index}].kc`}
                            component="input"
                            aligment="right"
                            placeholder="Ingrese kc(mensual)"
                            type="number"
                        />
                        <Field
                            name={`kcs[${index}].mes`}
                            component={hiddenInput}
                            staticValue={mes}
                        />
                    </Col>
                })}
            </Row>
            <hr></hr>
            <Row className="justify-content-md-center">
                <Col lg={3}>
                    <label>Profundidad Radicular *</label><br></br>
                    <Field
                        name={`profundidadRadicular`}
                        component="input"
                        aligment="right"
                        placeholder="Ingrese P.Radicular"
                        type="number"
                        //validate = {props.validate}
                    />
                </Col>
            </Row>
        </div>
    )
}