import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Modal } from 'react-bootstrap'
import Form, { Field } from "src/app/util/form/form.jsx"

import { setFechas } from "src/app/reducers/programadorReducer";
import DatePicker from 'src/app/util/datepicker/datePicker';
import GraficoPeriodo from "../graficos/GraficoPeriodo";
import { CheckBox } from "devextreme-react";
const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];

function renderCustomHeaderDatePikcer(props) {
    return (
        <Row >
            <Col>
                <button type="button" className="btn" onClick={props.decreaseMonth} disabled={props.date.getMonth() == 0}>
                    <i class="fa fa-chevron-left" aria-hidden="true"></i>
                </button>
            </Col>
            <Col>
                <button className="btn" type="button" disabled>
                    <b>{months[props.date.getMonth()]}</b>
                </button>
            </Col>
            <Col>
                <button type="button" className="btn" onClick={props.increaseMonth} disabled={props.date.getMonth() == 11}>
                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                </button>
            </Col>
        </Row>
    )
}
export default function (props, { input, value }) {
    const programadorState = useSelector(state => state.programadorReducer);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false)
    const [checked, setChecked] = useState(programadorState.fechaAnual);
    const handleChecked = (e, formprops) => {
        if (e.target.checked) {
            formprops.change("fechaInicio", new Date("2024-08-01T14:00:00.00Z"))
            formprops.change("fechaFin", new Date("2024-07-31T14:00:00.00Z"))
        } else {
            formprops.change("fechaInicio", new Date())
            formprops.change("fechaFin", new Date())
        }
        setChecked(e.target.checked)

    }
    const [modalShow, setModalShow] = React.useState(false);

    function MyModal(props) {
        return (
            <Modal
                size="md"
                {...props}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <p>
                        Determinar el período en el cual se desea conocer la necesidad hídrica del cultivo.
                        La misma puede ser anual (todo el año) o la de un periodo definido hasta un límite diario.
                        Esto se logra ingresando las fechas, desde/hasta, deseadas.
                    </p>
                </Modal.Body>
            </Modal>
        );
    }
    return (
        <div>
            {programadorState && <Form
                //onSubmit={(values)=>{dispatch(setTipoCultivo(values.tipoCultivo));props.history.push("/programador/StepThreeFormTipoCultivo")}}
                onSubmit={(values, formprops) => {
                    dispatch(setFechas(new Date(values.fechaInicio), new Date(values.fechaFin), checked))
                }}
                saveIn={"calculadoraFormFechas"}
                changeAndSubmit
                validation={(values, formprops) => {
                    if (values.fechaInicio && values.fechaFin) {
                        props.setValid(true)
                    }
                    else {
                        props.setValid(false)
                        return { error: 1 }
                    }
                }}


                //submitText="Siguiente Paso"
                components={(formprops) =>
                    <div>

                        <div className="main-icon"><i class="fa fa-calendar"></i></div>
                        <h1>Periodo *
                            <button variant="primary" onClick={() => setModalShow(true)} className="btn-light info btn btn-secondary" >
                                <i className={"fa fa-info-circle "}></i>
                            </button>
                            <MyModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                            />
                        </h1>
                        <Row>
                            <Col lg={1}></Col>
                            <Col lg={4} sm={12}>

                                <Field
                                    label="Desde"
                                    name={`fechaInicio`}
                                    component={DatePicker}
                                    renderCustomHeader={renderCustomHeaderDatePikcer}
                                    disabled={checked}
                                />
                            </Col>
                            <Col lg={4} sm={12}>

                                <Field
                                    label="Hasta"
                                    name={`fechaFin`}
                                    component={DatePicker}
                                    renderCustomHeader={renderCustomHeaderDatePikcer}
                                    disabled={checked}
                                />
                            </Col>
                            <Col lg={2} sm={12}>

                                <label>Anual</label> <br></br>
                                <input name={`anual`} onChange={(e) => handleChecked(e, formprops)} type="checkbox" checked={checked}>
                                </input>
                            </Col>
                            <Col lg={1}></Col>
                        </Row>
                        <button class={show ? "btn btn-outline-primary btn-sm btn-show" : "btn btn-primary btn-sm btn-show"} onClick={() => setShow(show ? false : true)}>
                            {show ? "Ocultar Gráfico" : "Mostrar Gráfico"}
                        </button>
                        {show ? <GraficoPeriodo /> : null}
                    </div>
                }
            >
            </Form>}
        </div>
    )
}