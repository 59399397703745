import axios from "axios";
import { SET_ERROR } from "./errorReducer";
import Swal from "sweetalert2";
import { SubmissionError } from "redux-form";
import { objToQuery } from "../util/lib";
export const reducerName="sistemaDeRiegoReducer";
export default function (state = INITIAL_STATE, action){
    if (reducerName!=action.type) return state;
    const newstate=action.fn(state,action);
    if (newstate)return newstate;
    return {...state};
}
const INITIAL_STATE = {
    totalPages:0,
    currentPage:0,
    sistemaDeRiego:null,
    state:{criteria:{}},
};
const example=undefined;
const apiUrl = "/services/servicioshidricos/api/public";
export const buscarSistemaDeRiego = (page,size,searchProps,withOutDispatch) => async (dispatch) => {
    const obj = objToQuery({
        page: page,
        size: size,
        ...searchProps,
    });
    try {
        const res=await axios.get(`${apiUrl}/sistema-de-riegos${obj}`)
        let cantTotal = parseInt(res.headers["x-total-count"]);
        const result={
            sistemaDeRiego: example || res.data,
            currentPage: page,
            totalPages: Math.ceil(cantTotal / size),
        };
        
        if (withOutDispatch){
            return result
        }
        else {
            dispatch({
                type: reducerName,
                payload:result,
                fn: (state,action) => ({
                    ...state,
                    ...action.payload
                })
            })   
        }

    }  
    catch(error){
        console.error(error)
        if (error.response){ 
            dispatch({ type: SET_ERROR, payload: error.response });
        }
        throw error;
    }
}

export const saveSistemaDeRiego = (sistemaDeRiego) => async (dispatch) => {
    try {
        if (sistemaDeRiego.id){//editar
            const res=await axios.put(`${apiUrl}/sistemaDeRiego/`,sistemaDeRiego);
        }
        else { //nuevo
            const res=await axios.post(`${apiUrl}/sistemaDeRiego/`,sistemaDeRiego);
        }
    }
    catch(error){
        console.error(error)
        if (error.response){ 
            dispatch({ type: SET_ERROR, payload: error.response });
        }
        throw error;
    }
}
export const deleteSistemaDeRiego = (sistemaDeRiego) => async (dispatch) => {
    try {
        const res=await axios.delete(`${apiUrl}/sistemaDeRiego/${sistemaDeRiego.id}`);
        Swal.fire("Eliminado Correctamente")
        dispatch({
            type: reducerName,
            payload:sistemaDeRiego,
            fn: (state,action) => ({
                ...state,
                sistemaDeRiego:state.sistemaDeRiego.filter(e=>e.id!=action.payload.id)
            })
        })
    }
    catch(error){
        console.error(error)
        if (error.response){ 
            dispatch({ type: SET_ERROR, payload: error.response });
        }
        throw error;
    }
}

