import React, { useEffect,useState} from "react";
import { useDispatch } from "react-redux";
import {buscarCuenca} from "src/app/reducers/cuencaReducer"
import SelectSearch from "src/app/util/SelectSearch/SelectSearch";
export default function (props){
  const dispatch=useDispatch();
  return (
    <SelectSearch
      input={props.input}
      keyId={"id"}
      label="Cuenca"
      getName={(item)=>item.id+"-"+item.nombre}
      getOptions={async (string)=>{
        const result=await dispatch(buscarCuenca(0,10,{"nombre.contains":string},true))
        return result.cuenca;
      }}
      rescueOption={async (obj)=>{
        const result=await dispatch(buscarCuenca(0,1,{"id.equals":obj.id},true))
        return result.cuenca[0];
      }}
      placeHolder="Seleccione un Cuenca"
    />
  )
}