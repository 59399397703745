import React, { useEffect, useState } from "react";
import { Col, Row, Modal, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { reducerName, setTipoRiego } from "src/app/reducers/programadorReducer";
import Form, { Field } from "src/app/util/form/form.jsx"
import NumberInput from "src/app/util/numberInput/NumberInput";
import Grafico from "../tipoCultivo/Grafico";
import DibujoHileras from "../tipoCultivo/DibujoHileras";
import SelectSearchTipoRiego from "../tipoRiego/SelectSearchTipoRiego";
import SelectSearchTipoSuelo from "../tipoSuelo/SelectSearchTipoSuelo";
import '../tipoSuelo/styleTipoSuelo.css'
import FormDemandaRiegoDefinido, { ValidRiegoDefinido } from "./FormDemandaRiegoDefinido";
function LaminaRepocision(programadorState) {
    return Math.min(programadorState.resultado.totalPeriodoEntreRiegos.nB * programadorState.eficiencia / programadorState.tipoRiego.eficiencia,
        programadorState.resultado.humedadUtil);
}
function Superficie(programadorState) {
    return programadorState.extrasTipoSuelo?.numeroHileras *
        programadorState.extrasTipoSuelo?.distanciaHileras *
        programadorState.extrasTipoSuelo?.largoHileras / 10000 || undefined;
}
function Volumen(programadorState) {
    return LaminaRepocision(programadorState) * 10 * Superficie(programadorState)  || undefined
}
function TiempoRiegoTotal(programadorState) {
    return Volumen(programadorState) * 1000 / (programadorState.extrasTipoSuelo?.caudalRiego * 60) || undefined
}
function TiempoRiegoIngresado(programadorState) {
    return programadorState.extrasTipoSuelo?.tiempoRiegoHora * 60 + programadorState.extrasTipoSuelo?.tiempoRiegoMinuto * 1
}
function MinutosXHa(programadorState) {
    return TiempoRiegoTotal(programadorState) / Superficie(programadorState) || undefined
}
function MinutosXHaSegunTurno(programadorState) {
    return TiempoRiegoIngresado(programadorState) /
        programadorState.extrasTipoSuelo?.superficieDerechoRiego || undefined
}
function SuperficieFactibleRegar(programadorState) {
    return programadorState.extrasTipoSuelo?.caudalRiego / 1000 * TiempoRiegoIngresado(programadorState) * 60 / (LaminaRepocision(programadorState) * 10) || undefined
}
function TiempoPorTapada(programadorState) {
    return programadorState.tipoSuelo?.avanceOptimo * programadorState.extrasTipoSuelo?.largoHileras / 100 || undefined
}
function NumeroTapadas(programadorState) {
    return Math.ceil(TiempoRiegoIngresado(programadorState) / TiempoPorTapada(programadorState)) || undefined
}
function NumeroTapadasOpt(programadorState) {
    return Math.ceil(programadorState.extrasTipoSuelo.numeroHileras /
        programadorState.extrasTipoSuelo.numeroHilerasTapadas /
        programadorState.extrasTipoSuelo.numeroTapadasSimultaneas) || undefined
}
function TiempoPorTapadaOpt(programadorState) {
    return TiempoRiegoIngresado(programadorState) / NumeroTapadasOpt(programadorState) || undefined
}
function CaudalPorHilera(programadorState) {
    return programadorState.extrasTipoSuelo?.caudalRiego /
        programadorState.extrasTipoSuelo.numeroHilerasTapadas /
        programadorState.extrasTipoSuelo.numeroTapadasSimultaneas || undefined
}
function CaudalPorSurco(programadorState) {
    return CaudalPorHilera(programadorState) / 2 || undefined;
}
function CaudalPorTapada(programadorState) {
    return programadorState.extrasTipoSuelo?.caudalRiego / programadorState.extrasTipoSuelo.numeroTapadasSimultaneas || undefined
}
function SuperficieTapadaOptima(programadorState) {
    return programadorState.extrasTipoSuelo.numeroHilerasTapadas * programadorState.extrasTipoSuelo.largoHileras * programadorState.extrasTipoSuelo.distanciaHileras || undefined
}
function SuperficieUnidadriego(programadorState) {
    return programadorState.extrasTipoSuelo.numeroTapadasSimultaneas * SuperficieTapadaOptima(programadorState) || undefined
}
function TiempoTotalRiego(programadorState) {
    return TiempoPorTapadaOpt(programadorState) * NumeroTapadasOpt(programadorState) || undefined;
}
function getHoursAndMinutes(minutes) {
    minutes = Math.floor(minutes)
    const minutes2 = minutes % 60;
    const hours = (minutes - minutes2) / 60;
    return (hours || 0).toString().padStart(2, "0") + ":" + (minutes2 || 0).toString().padStart(2, "0");
}
function ValidHilerasForm(values) {
    return values.numeroHileras && values.distanciaHileras && values.largoHileras;
}
function SueloSurcoSinDesagueHilerasForm(props) {
    const programadorState = useSelector(state => state.programadorReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        props.setValid(true)
    }, [])
    return (

        <div>
            {programadorState && <Form
                initialValues={programadorState.extrasTipoSuelo}
                validation={(values, formprops) => {
                    dispatch({
                        type: reducerName,
                        noUpdate: true,
                        fn: (state, action) => ({
                            ...state,
                            extrasTipoSuelo: {
                                ...state.extrasTipoSuelo,
                                ...values
                            }
                        })
                    })
                    props.setInnerValid(ValidHilerasForm(values))
                }}
                components={(formprops) => {
                    return (<>
                        <div className="main-icon"><i class="fa fa-globe"></i></div>
                        <h1>Superficie a regar:</h1>
                        <Row>
                            <Col>
                                <label>Número de Hileras Totales</label>
                                <Field component={NumberInput} name="numeroHileras" type="number" />
                            </Col>
                            <Col>
                                <label>Distancia entre Hileras (m)</label>
                                <Field component={NumberInput} name="distanciaHileras" type="number" />
                            </Col>
                            <Col>
                                <label>Largo de Hilera (m)</label>
                                <Field component={NumberInput} name="largoHileras" type="number" />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} sm={12} className="resultadoBgGris  d-flex justify-content-around">
                                <li><b>Superficie a regar en el turno: </b>   {(Superficie(programadorState) || 0) + " " + "ha"}</li>
                                <li><b>Lámina de Reposición: </b> {Math.round(LaminaRepocision(programadorState) || 0) + " " + "mm"}</li>
                                <li><b>Volumen total de agua necesaria: </b> {Math.round(Volumen(programadorState) || 0) + " " + "m3"}</li>
                            </Col>

                        </Row>


                    </>
                    )
                }}
            >
            </Form>}


        </div>)
}
function ValidSueloSurcoSinDesagueTiemposForm(values) {
    return values.caudalRiego && values.tiempoRiegoHora !== "" && values.tiempoRiegoMinuto !== "" && values.superficieDerechoRiego;
}
function SueloSurcoSinDesagueTiemposForm(props) {
    const programadorState = useSelector(state => state.programadorReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        props.setValid(true)
    }, [])
    return (
        <div className="justify-content-around">
            {programadorState && <Form
                initialValues={programadorState.extrasTipoSuelo}
                validation={(values, formprops) => {
                    dispatch({
                        type: reducerName,
                        noUpdate: true,
                        fn: (state, action) => ({
                            ...state,
                            extrasTipoSuelo: {
                                ...state.extrasTipoSuelo,
                                ...values
                            }
                        })
                    })
                    props.setInnerValid(ValidSueloSurcoSinDesagueTiemposForm(values))
                }}
                components={(formprops) => {
                    return (<>
                        <div className="main-icon"><i class="fa fa-history"></i></div>
                        <h1>Caudal y tiempo de riego</h1>
                        <Row>
                            <Col lg={4}></Col>
                            <Col lg={4}>
                                <label>Caudal de Riego l/s</label>
                                <Field component={NumberInput} name="caudalRiego" min={0} type="number" />
                            </Col>
                            <Col lg={4}></Col>
                        </Row>
                        <Row >
                            <Col lg={12} sm={12} className="resultadoBgGris  d-flex justify-content-around mb-2">
                                <li><b>Tiempo total de riego</b> {getHoursAndMinutes(TiempoRiegoTotal(programadorState))}</li>
                                <li><b>Tiempo por Ha Calculados</b> {getHoursAndMinutes(MinutosXHa(programadorState))}</li>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <label>Hora/s de riego</label>
                                <Field component={NumberInput} name="tiempoRiegoHora" min={0} type="number" placeHolder="horas" />
                            </Col>
                            <Col lg={4}>
                                <label>Minuto/s de riego</label>
                                <Field component={NumberInput} name="tiempoRiegoMinuto" max={60} min={0} type="number" placeHolder="minutos" />
                            </Col>

                            <Col lg={4}>
                                <label>Superficie con derecho de riego (ha):</label>
                                <Field component={NumberInput} name="superficieDerechoRiego" type="number" />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} sm={12} className="resultadoBgGris  d-flex justify-content-around">
                                <li><b>Tiempo/ha del cuadro de turnos: </b><br></br>{getHoursAndMinutes(MinutosXHaSegunTurno(programadorState))}</li>
                                <li><b>Sup. factible de regar: </b><br></br> {(SuperficieFactibleRegar(programadorState) || 0).toFixed(1) + " " + "ha"}</li>
                                <li><b>Tiempo recomendado por tapada: </b><br></br> {getHoursAndMinutes(TiempoPorTapada(programadorState))}</li>
                                <li><b>Unidades de riego recomendadas: </b><br></br> {NumeroTapadas(programadorState)}</li>
                            </Col>
                        </Row>

                    </>
                    )
                }}
            >
            </Form>}
        </div>)
}
function ValidHilerasOptimasForm(values) {
    return values.numeroTapadasSimultaneas && values.numeroHilerasTapadas
}
function Formulario4(props) {
    const programadorState = useSelector(state => state.programadorReducer);
    const dispatch = useDispatch();
    const timeopt = TiempoPorTapadaOpt(programadorState);
    const time = TiempoPorTapada(programadorState);
    const margenError = time * 0.15
    const relacionTapadas = NumeroTapadasOpt(programadorState) / NumeroTapadas(programadorState);
    useEffect(() => {
        props.setValid(true)
    }, [])
    return (
        <div>
            {programadorState && <Form
                initialValues={programadorState.extrasTipoSuelo}
                validation={(values, formprops) => {
                    dispatch({
                        type: reducerName,
                        noUpdate: true,
                        fn: (state, action) => ({
                            ...state,
                            extrasTipoSuelo: {
                                ...state.extrasTipoSuelo,
                                ...values
                            }
                        })
                    })
                    props.setInnerValid(ValidHilerasOptimasForm(values))
                }}
                components={(formprops) => {
                    return (
                        <>
                            <div className="main-icon"><i class="fa fa-star"></i></div>
                            <h1>Optimización</h1>
                            <Row>
                                <Col lg={2}></Col>
                                <Col lg={4}>
                                    <label>N° Tapadas simultáneas</label>
                                    <Field component={NumberInput} name="numeroTapadasSimultaneas" min={0} type="number" />
                                </Col>
                                <Col lg={4}>
                                    <label>N° Hileras por tapada</label>
                                    <Field component={NumberInput} name="numeroHilerasTapadas" min={0} type="number" />
                                </Col>
                                <Col lg={2}></Col>
                            </Row>
                            <Row>
                                <Col lg={12} sm={12} className="resultadoBgGris  d-flex justify-content-around">
                                    <li><b>Tiempo por Tapada</b> {Math.round(timeopt)} min
                                        <b style={{ color: "#dc3545" }}>
                                            {timeopt - time > margenError && " Disminuir el número de hileras o tapadas"}
                                            {timeopt - time < -margenError && " Aumentar el número de hileras o tapadas"}
                                        </b></li>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} sm={12} className="resultadoBgGris  d-flex justify-content-around">
                                    <li><b>Caudal por Hilera:</b> {Math.round((CaudalPorHilera(programadorState) || 0).toFixed(2)) + " " + "l/s"}</li>
                                    <li><b>Caudal por Surco:</b> {Math.round((CaudalPorSurco(programadorState) || 0).toFixed(2)) + " " + "l/s"}</li>
                                    <li><b>Caudal por Tapada:</b> {Math.round((CaudalPorTapada(programadorState) || 0).toFixed(2)) + " " + "l/s"}</li>
                                    <li><b>N° Unidades de Riego:</b> {NumeroTapadasOpt(programadorState)}</li>
                                    <li><b>Sup. por Tapada:</b>  {(SuperficieTapadaOptima(programadorState) || 0) + " " + "m2"}</li>
                                </Col>
                            </Row>
                            <Row >
                                <Col lg={12} sm={12} className="resultadoBgGris  d-flex justify-content-around">
                                    <li><b>Sup. Unidad de Riego: </b>{(SuperficieUnidadriego(programadorState) || 0) + " " + "m2"}</li>
                                    <li><b>Tiempo total de Riego: </b>{getHoursAndMinutes(TiempoTotalRiego(programadorState))}</li>
                                    <li><b>Unidades de Riego Recomendadas: </b>{NumeroTapadas(programadorState)}</li>
                                    <li><b>Control Número Tapadas: </b> {
                                        console.log(relacionTapadas) ||
                                            relacionTapadas < 0.9 ? "Disminuir el número de hileras o tapadas"
                                            : relacionTapadas > 1.1 ? "Aumentar el número de hileras o tapadas"
                                                : "Número de tapadas correcto"
                                    }</li>
                                </Col>
                            </Row>
                            {programadorState.extrasTipoSuelo.numeroTapadasSimultaneas && programadorState.extrasTipoSuelo.numeroHilerasTapadas &&
                                <Row align="center" className="mt-3">
                                    <Col>
                                        <DibujoHileras
                                            hileras={programadorState.extrasTipoSuelo.numeroHilerasTapadas}
                                            tapadas={programadorState.extrasTipoSuelo.numeroTapadasSimultaneas} />
                                    </Col>
                                </Row>}


                        </>
                    )
                }}
            >
            </Form >}


        </div >)
}
function FormularioTipoSuelo(props) {
    const programadorState = useSelector(state => state.programadorReducer);
    const dispatch = useDispatch();
    return (<div>
        {programadorState && <Form
            initialValues={{
                ...programadorState,
                umbralRiego: programadorState.umbralRiego || 60
            }}
            validation={(values, formprops) => {
                dispatch({
                    type: "programadorReducer", fn: (state) => ({
                        ...state,
                        tipoSuelo: values.tipoSuelo,
                        tipoRiego: values.tipoRiego,
                        proporcionSueloMojado: values.proporcionSueloMojado,
                        umbralRiego: values.umbralRiego === "" ? 60 : values.umbralRiego,
                    })
                })

                if (values.tipoSuelo && values.tipoRiego && values.proporcionSueloMojado && values.umbralRiego) {
                    props.setValid(true);
                    props.setInnerValid(true);
                }
                else {
                    props.setValid(false);
                    props.setInnerValid(true);
                }
            }}
            components={(formprops) =>
                <div>
                    <div className="main-icon"><i class="fa fa-square"></i></div>
                    <h1>Características de la parcela *
                        {/* <button variant="primary" onClick={() => setModalShow(true)} className="btn-light info btn btn-secondary" >
                            <i className={"fa fa-info-circle "}></i>
                        </button>
                        <MyModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        /> */}
                    </h1>
                    <Row>
                        <Col lg={2}></Col>
                        <Col lg={4} md={6} sm={12}>
                            <Field component={SelectSearchTipoSuelo} name="tipoSuelo" />
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <label>Umbral de Riego *</label>
                            <Field component={NumberInput} name="umbralRiego" type="number" min={0} max={100} />
                        </Col>
                        <Col lg={2}></Col>
                    </Row>

                    <Row>
                        <Col lg={2}></Col>
                        <Col lg={4} md={6} sm={12}>
                            <Field
                                name={"tipoRiego"}
                                component={SelectSearchTipoRiego}
                                onChange={v => { formprops.change("proporcionSueloMojado", v.proporcionSueloMojado * 100); }}
                            />
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <label>Porcentaje de Suelo Mojado %</label><br />
                            <Field
                                component={"input"} name="proporcionSueloMojado" min={0} max={100} type="number"
                            />
                        </Col>
                    </Row>
                </div>
            }
        >
        </Form>}
        {programadorState.tipoSuelo && programadorState.kcs.length && <>
            <Row align="center" className=""> {/**col d-flex justify-content-around */}
                <Col lg={2}></Col>
                <Col lg={8} className="resultadoBgGris  d-flex justify-content-around">
                    <li>
                        <b>Humedad Útil WC: </b>{Math.round(programadorState.tipoSuelo?.densidad * 10 * programadorState.kcs[0].profundidadRadicular) + " " + "mm"}
                    </li>
                    <li>
                        <b>Humedad Útil Según el tipo de riego:</b> {Math.round(programadorState.tipoSuelo?.densidad * 10 * programadorState.kcs[0].profundidadRadicular * programadorState.proporcionSueloMojado / 100) + " " + "mm"}
                    </li>
                </Col>
                <Col lg={2}></Col>
            </Row>
        </>}
        {programadorState.proporcionSueloMojado && programadorState.tipoSuelo && <Row align="center">
            <Col><Grafico proporcion={programadorState.proporcionSueloMojado / 100} cultivo={programadorState.tipoCultivo} suelo={programadorState.tipoSuelo} /></Col>
        </Row>}

    </div>)
}

export default function (props) {
    const programadorState = useSelector(state => state.programadorReducer);
    const history = useHistory();
    const params = useParams();
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = React.useState(false);
    const [valid, setValid] = useState(false);
    const [page, setPage] = useState(params.subpage || 0);
    const [components, setComponents] = useState([]);
    const pages = 4;
    function goNext() {
        if (page == pages) return;
        setPage(page * 1 + 1);

        history.push(`/${props.page}/${(page * 1 + 1)}/`);
    }
    function goFowards() {
        if (page == 0) return;
        setPage(page * 1 - 1);

        history.push(`/${props.page}/${(page * 1 - 1)}/`);
    }
    function submit() {
        dispatch((programadorState))
    }
    function goTo(n) {
        if (page === n) return
        setPage(n);
        history.push(`/${props.page}/${(n)}/`);
    }
    function MyModal(props) {
        return (
            <Modal
                size="md"
                {...props}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <p>
                        Determinar el cultivo para el cual se requiere conocer su demanda de agua.
                        La elección del cultivo nos permite conocer el kc (coeficiente del cultivo) del mismo.
                        Este parámetro representa la relación que existe entre la Evapotranspiración de Referencia (Eto)
                        y la Evapotranspiración del Cultivo (Etc).
                        Existe la posibilidad de que, si el usuario quiere cargar un nuevo cultivo,
                        el mismo deberá introducir los diferentes valores de Kc de cultivo y la profundidad radicular que el mismo posea.
                        Dicha carga se deberá realizar en la sección Otro cultivo.

                    </p>
                </Modal.Body>
            </Modal>
        );
    }
    useEffect(() => {
        if (programadorState.tipoRiego?.id == 3) {
            setComponents([FormularioTipoSuelo, FormDemandaRiegoDefinido, SueloSurcoSinDesagueHilerasForm, SueloSurcoSinDesagueTiemposForm, Formulario4])

        }
        else {
            setComponents([FormularioTipoSuelo])
            setPage(0);
        }
    }, [programadorState.tipoRiego?.id])
    const PageComponent = components[page] || ((props) => <h2>No hay datos</h2>)
    return (
        <div>
            {<PageComponent {...props} setInnerValid={setValid} />}
            {components.length > 1 && <><hr></hr><Row align="center" className="d-flex justify-content-around subSteps">

                <Col lg={1}></Col>
                <Col lg={2}>{page > 0 && <button className="btn btn-primary" onClick={goFowards}><i class="fa fa-arrow-left"></i> Anterior</button>}</Col>
                <Col lg={6}>
                    {/* <progress min="0" max={pages} value={page} /> */}
                    <ul className="lstSubSteps">
                        <li><button type="button" onClick={() => goTo(0)} >
                            <i class="fa fa-map-marker"></i></button></li>
                        <li><button type="button" onClick={() => goTo(1)} disabled={!programadorState.tipoRiego === 3}><i class="fa fa-calendar"></i></button></li>
                        <li><button type="button" onClick={() => goTo(2)} disabled={!programadorState.fechaUltimoRiego}><i class="fa fa-globe"></i></button></li>
                        <li><button type="button" onClick={() => goTo(3)} disabled={!programadorState.extrasTipoSuelo?.largoHileras}><i class="fa fa-history"></i></button></li>
                        <li><button type="button" onClick={() => goTo(4)} disabled={!programadorState.extrasTipoSuelo?.largoHileras} ><i class="fa fa-star"></i></button></li>
                    </ul>
                    <div class="arrow-down"></div>
                </Col>
                <Col lg={2} >{page != components.length - 1 && <button className="btn btn-primary" onClick={goNext} disabled={!valid}>Siguiente <i class="fa fa-arrow-right"></i></button>}</Col>
                <Col lg={1}></Col>
                {/*xs={{ order: 1 }}  lg={{ span:2, order:12 }}*/}
            </Row>

            </>}

        </div>
    )
}
