import React from 'react'
import { useSelector } from 'react-redux';
import "moment/locale/es";
import GraficoGenerico from "src/app/util/graficoGenerico/GraficoGenerico";
import {Column} from "devextreme-react/data-grid" 

export default function (props) {

    const programadorState = useSelector(state => state.programadorReducer);
    const lista1 = programadorState.resultado['datosXDia'];
    const lista2 = programadorState.resultado['datosXMes'];
    const customizeLabel = (e) => {   
        return `${e.valueText} mm`
    }
    return (
        <div >
            <GraficoGenerico
                lista={[
                    { datos: lista2, name: "Mes" },
                    { datos: lista1, name: "Día" }
                    
                ]}
                customizeText={customizeLabel}
                argumentField="fechaSimple"
                mostrar
                type="bar"
                series={[{ name: "Etc", valueField: "etc", color:"#A4D7E1" }, { name: "Etc Período", valueField: "periodoEtc", color:"#F2AAAA" }]}
                renderGridRow={() => [
                    <Column caption="Fecha" dataField="fechaSimple" />,
                    <Column caption="Etc (mm)" dataField="etc" alignment="left" cellRender={(row)=> { 
                        return row.data.etc?
                      
                            <label >{ Math.round(row.data.etc*10)/10 }</label>    
                    
                        : ""
                    }}
                    />,
                    <Column caption="Periodo Etc (mm)" dataField="periodoEtc" alignment="left"
                    cellRender={(row)=> { 
                        return row.data.periodoEtc?
                      
                            <label >{ Math.round(row.data.periodoEtc*10)/10 }</label>    
                    
                        : ""
                    }}/>
                ]}
            />
        </div>
    )
}