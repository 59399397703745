import React, { useEffect,useState} from "react";
import { useDispatch } from "react-redux";
import SelectSearch from "src/app/util/SelectSearch/SelectSearch";
import { buscarInspeccion } from "src/app/reducers/inspeccionReducer";

export default function (props){
  const dispatch=useDispatch();
  return (
    <SelectSearch
      popupButton
      pageSize={10}
      input={props.input}
      keyId={"id"}
      label="Inspección"
      getName={(item)=>item.id+"-"+item.nombre}
      getOptions={async (string, page)=>{
        const result=await dispatch(buscarInspeccion(page,10,{"nombre.contains":string},true))
        return {
          ...result,
          data:result.inspeccion,
        };
      }}
      rescueOption={async (obj)=>{
        const result=await dispatch(buscarInspeccion(0,1,{"id.equals":obj.id},true))
      return result.inspeccion[0];
      }}
      placeHolder="Seleccione una Inspeccíon"
    />
  )
}