import React, { useState } from "react";
import { Row, Col, Button, Container } from 'react-bootstrap'
import MultiStepForm from "./MultiStepForm";
import './styleForm.css'

export default function (props) {


    return (
        <Container fluid className="bg-form">
            <div className="main-container">
                <MultiStepForm />
            </div>
        </Container>
    )
}