import React, { useEffect,useState} from "react";
import { useDispatch } from "react-redux";
import { buscarTipoSuelo } from "src/app/reducers/tipoSueloReducer";
import SelectSearch from "src/app/util/SelectSearch/SelectSearch";


export default function (props){
  const dispatch=useDispatch();
  return (
    <SelectSearch
      input={props.input}
      keyId={"id"}
      label="Tipo de Suelo *"
      getName={(item)=>item.id+"-"+item.nombre}
      getOptions={async (string)=>{
        const result=await dispatch(buscarTipoSuelo(0,10,{"nombre.contains":string},true))
        return result.tipoSuelo;
      }}
      rescueOption={async (obj)=>{
        if (obj.Nombre && obj.id)return obj;
        const result=await dispatch(buscarTipoSuelo  (0,1,{"id.equals":obj.id},true))
        return result.tipoSuelo[0]                    
      }}
      placeHolder="Seleccione"
    />
  )
}