import React, { useEffect,useState} from "react";
import { useDispatch } from "react-redux";
import {buscarTipoCultivo} from "src/app/reducers/tipoDeCultivoReducer";
import SelectSearch from "src/app/util/SelectSearch/SelectSearch";

export default function (props){
  const dispatch=useDispatch();
  return (
    <SelectSearch
      input={props.input}
      keyId={"id"}
      label="Tipo Cultivo"
      getName={(item)=>item.id+"-"+item.nombre}
      getOptions={async (string)=>{
        const result=await dispatch(buscarTipoCultivo(0,10,{"nombre.contains":string},true))
        return result.tipoCultivo;
      }}
      rescueOption={async (obj)=>{
        return obj;
      }}
      placeHolder="Seleccione un Tipo Cultivo"
    />
  )
}