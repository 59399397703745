import React from "react";
import SelectSearch from "react-select-search";
import "./Select.css";

export default function ComboMesSelect(props) {  
    const {
    input: { value, name },
  } = props;

  const selectedOption = (value, name);

  function handleChange(name) {
    props.input.onChange(name);
  }

  const ComboMesSelect = [
    { value: 1, name: "Enero" },
    { value: 2, name: "Febrero" },
    { value: 3, name: "Marzo" },
    { value: 4, name: "Abril" },
    { value: 5, name: "Mayo" },
    { value: 6, name: "Junio" },
    { value: 7, name: "Julio" },
    { value: 8, name: "Agosto" },
    { value: 9, name: "Septiembre" },
    { value: 10, name: "Octubre" },
    { value: 11, name: "Noviembre" },
    { value: 12, name: "Diciembre" },
  ];

  return (
    <div className="form-group">
      {/* <label forname="mes">{props.caption}</label> */}
      <SelectSearch
        options={ComboMesSelect}
        onChange={(value) => handleChange(value)}
        value={selectedOption ? selectedOption.name : ""}
        search
        placeholder="Seleccionar"
      />
    </div>
  );
}
