import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import NumberInput from "src/app/util/numberInput/NumberInput";

const months = [
    {
        name: "Agosto",
        mesIndex: 7
    },
    {
        name: "Septiembre",
        mesIndex: 8
    },
    {
        name: "Octubre",
        mesIndex: 9
    },
    {
        name: "Noviembre",
        mesIndex: 10
    },
    {
        name: "Diciembre",
        mesIndex: 11
    },

    {
        name: "Enero",
        mesIndex: 0
    },
    {
        name: "Febrero",
        mesIndex: 1
    },
    {
        name: "Marzo",
        mesIndex: 2
    },
    {
        name: "Abril",
        mesIndex: 3
    },
    {
        name: "Mayo",
        mesIndex: 4
    },
    {
        name: "Junio",
        mesIndex: 5
    },
    {
        name: "Julio",
        mesIndex: 6
    }
];

export default function InputRiegoMeses(props) {
    const [refreshs, setRefreshs] = useState(0)
    const derecho = props.programador.portentajeDerecho || 100;
    const cultivo = props.programador.portentajeCultivo || 100;
    useEffect(() => {
        if (!props.input.value) {
            props.input.onChange(months.map((e, i) => ({ intervalo: 0, lamina: 0, mes: e, mesIndex: i, inputIntervalo: 0, inputLamina: 0 })));//default value
        }
        else {

            props.input.onChange(props.input.value.map(e => ({
                ...e,
                lamina: e.inputLamina / derecho / cultivo * 100 * 100 || 0,
                intervalo: e.inputIntervalo / cultivo * 100 || 0,
            })))
        }
    }, [derecho, cultivo]);
    const change = () => {

        props.input.onChange([...props.input.value]);//force change event
        setRefreshs(refreshs + 1)
    }
    return (
        <>
            <Row><Col className="resultadoBgGris">
              
                <Row>
                    <Col lg={2}>
                        Mes
                    </Col>

                    <Col lg={5} align="center">
                        <label style={{ textAlign: "center", margin: "0 auto", display: "block" }} className="mb-2">
                        {props.programador.riegoConPozo ?"Datos del riego":"Datos del cuadro de turno"}
                        </label>

                        <Row>
                            <Col lg={5} style={{textAlign:"left"}}> Frecuencia de riego (días)</Col>
                            <Col lg={4} style={{textAlign:"left"}}>  Lámina (mm)</Col>
                            <Col lg={2} style={{textAlign:"left"}}> ( m3 )</Col>
                        </Row>
                    </Col>
                    <Col lg={5} align="center">
                        <label style={{ textAlign: "center", margin: "0 auto", display: "block" }} className="mb-2">Datos resultantes</label>

                        <Row>
                            <Col lg={5} align="center">  Frecuencia Aplicada (días)</Col>
                            <Col lg={4} align="center">  Lámina Aplicada (mm) </Col>
                            <Col lg={2} style={{textAlign:"left"}}> ( m3 )</Col>
                        </Row>
                    </Col>

                </Row>
                <Row><Col><hr /></Col></Row>
                {props.input.value && months.map((mes, i) => {
                    const value = props.input.value[mes.mesIndex];

                    return <Row key={i}>
                        <Col lg={2}>
                            {mes.name}
                        </Col>
                        <Col lg={2}>
                            <NumberInput
                                type="number"
                                min={0}
                                input={{
                                    value: parseInt(value.inputIntervalo) || "",
                                    onChange: (v) => {
                                        value.intervalo = v / cultivo * 100;
                                        value.inputIntervalo = v;
                                        change();
                                    }
                                }}
                            />
                        </Col>
                        <Col lg={2}>
                            <NumberInput
                                type="number"
                                min={0}
                                input={{
                                    value: parseInt(value.inputLamina) || "",
                                    onChange: (v) => {
                                        value.lamina = v / derecho / cultivo * 100 * 100;
                                        value.inputLamina = v;
                                        change();
                                    }
                                }}
                            />
                        </Col>
                        <Col lg={1}>
                        <p>{parseInt(value.inputLamina) * 10}</p>
                        </Col>
                        <Col align="center">
                            {Math.ceil(value.intervalo)}
                        </Col>
                        <Col align="center">
                            {Math.ceil(value.lamina)}
                        </Col>
                        <Col lg={1}>
                        <p>{Math.ceil(value.lamina) * 10}</p>
                        </Col>
                    </Row>

                })}
            </Col></Row>
        </>
    )
}