import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Modal } from 'react-bootstrap'
import Form, { Field } from "src/app/util/form/form.jsx"
import { setPrecipitacion } from "src/app/reducers/programadorReducer";
import SelectSearchPrecipitacion from "../precipitaciones/SelectSearchPrecipitacion";
import GraficoPrecipitacion from "../graficos/GraficoPrecipitacion";

export default function (props) {
    const programadorState = useSelector(state => state.programadorReducer);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false)
    const [modalShow, setModalShow] = React.useState(false);

    function MyModal(props) {
        return (
            <Modal
                size="md"
                {...props}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <p>
                    Estimar el Tipo de Precipitación que se presenta en la zona del cultivo, 
                    para conocer el aporte del agua de lluvia a la necesidad de riego. 
                    El sistema permite caracterizar las precipitaciones efectivas a partir 
                    la frecuencia de ocurrencia histórica. Se clasifican cuatro situaciones 
                    diferentes: Alto, Bajo, Medio y Nulo.
                    </p>
                </Modal.Body>
            </Modal>
        );
    }
    return (
        <div>
            {programadorState && <Form
                onSubmit={(values) => {
                    if (values.precipitacion) dispatch(setPrecipitacion(values.precipitacion, programadorState.entidadUbicacion.cuenca || programadorState.estacionesMeteorologicas[0]?.cuenca))
                }}
                changeAndSubmit
                validation={(values, formprops) => {
                    if (values.precipitacion) {
                        props.setValid(true)
                    }
                    else {
                        props.setValid(false)
                    }
                }}
                saveIn={"calculadoraFormPrecipitacion"}
                components={(formprops) =>
                    <div>
                        <div className="main-icon"><i class="fa fa-cloud"></i></div>
                        <h1>Precipitación *
                            <button variant="primary" onClick={() => setModalShow(true)} className="btn-light info btn btn-secondary" >
                                <i className={"fa fa-info-circle "}></i>
                            </button>
                            <MyModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                            />
                        </h1>
                        <Row>
                            <Col></Col>
                            <Col lg={4}>
                                <Field
                                    name={`precipitacion`}
                                    component={SelectSearchPrecipitacion}
                                />
                            </Col>
                            <Col></Col>
                        </Row>

                        <button class={show ? "btn btn-outline-primary btn-sm btn-show" : "btn btn-primary btn-sm btn-show"} onClick={() => setShow(show ? false : true)}>
                            {show ? "Ocultar Gráfico" : "Mostrar Gráfico"}
                        </button>
                        {show ? <GraficoPrecipitacion /> : null}

                    </div>
                }
            >
            </Form>}
        </div>
    )
}