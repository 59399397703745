import React, {useState} from 'react'
import DatePicker, { registerLocale } from "react-datepicker"
import moment from "moment";
import momenttimezone from "moment-timezone/builds/moment-timezone-with-data";
import es from "date-fns/locale/es";
import "moment/locale/es";
import "react-datepicker/dist/react-datepicker.css"
import { useEffect } from 'react';
registerLocale("es", es);


const DatePickerHistorico = ({ input, name, label, fecha, value,meta:{ touched, error}, ...props}) => {
    function getDate(){
       
            const x = new Date(input.value);
            if (x*1)return x;
            else return new Date();
       
    }
    useEffect(()=>{
        input.onChange(getDate())
    },[])
    return (
        <div className="form-group">
        <label forname={name}>{label}</label> <br />
        <DatePicker           
            name="fecha"            
            //name={fecha}
            selected={getDate()}
            //withPortal
            onChange={(input1) => {

                input.onChange(input1) 
            }}           
            //timeInputLabel="Hora/min:"
            dateFormat="dd/MM"
            //showTimeInput
            value={!value ? (new Date()): (new Date(value)).toLocaleString()}
            locale="es"
            {...props}
        />
          <div className="text-danger" style={{ marginBottom: "20px" }}>
             <p className="error-txt">{error &&  error[0]=="-" && error.substring(1) || touched && touched || error && error}</p>
        </div>
        </div>
    );
}

export default DatePickerHistorico
  