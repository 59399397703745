import React from 'react'
import { useSelector } from 'react-redux';
import "moment/locale/es";
import GraficoGenerico from "src/app/util/graficoGenerico/GraficoGenerico";
import {Column} from "devextreme-react/data-grid" 

export default function (props){

    const programadorState = useSelector(state => state.programadorReducer);
    const lista1 = programadorState.resultado['datosXDia'];
    const lista2 = programadorState.resultado['datosXMes'];
    const customizeLabel = (e) => {   
        return `${e.valueText} mm`
    }
    return (
        <div >
            
                <GraficoGenerico
                lista={[
                    {datos: lista2, name:"Mes"},
                    {datos: lista1, name: "Día"}
                ]}
                mostrar
                customizeText={customizeLabel}
                argumentField="fechaSimple"
                type="bar"
                series={[{name:"Eto Anual", valueField:"eto", color:"#A4D7E1"}, {name:"Eto Período", valueField:"periodoEto", color:"#F2AAAA"}]}
                renderGridRow={ ()=>[
                    <Column caption="Fecha" dataField="fechaSimple"/>,
                    <Column caption="Eto Anual (mm)" dataField="eto" alignment="left" cellRender={(row)=> { 
                        return row.data.eto ?
                           <label>{Math.round(row.data.eto*10)/10}</label>
                        : ""
                    }}
                    />,
                    <Column caption="Periodo Eto (mm)" dataField="periodoEto" alignment="left"
                    cellRender={(row)=> { 
                      return row.data.periodoEto?
                    
                          <label >{ Math.round(row.data.periodoEto*10)/10 }</label>    
                  
                      : ""
                  }}
                    />
                ]}
                />  
               
        </div>
    )
}
