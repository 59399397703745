import React, { useEffect, useState } from "react";
import { Col, Row, Modal, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "src/app/util/datepicker/datePicker";
import Form, { Field } from "src/app/util/form/form.jsx"
import NumberInput from "src/app/util/numberInput/NumberInput";
import FrecuenciaRiegoInput, { variable, constante, automatizado } from "../frecuenciaRiego/input";
import InputRiegoAutomatizado from "../frecuenciaRiego/InputRiegoAutomatizado";
import InputRiegoMeses from "../frecuenciaRiego/InputRiegoMeses";
import InputRiegoMesesConstante from "../frecuenciaRiego/InputRiegoMesesConstante";
import './resultadoStyle.css'
import checkbox from "src/app/util/checkboxInput/checkbox";
const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];

function renderCustomHeaderDatePikcer(props) {
    return (
        <Row >
            <Col>
                <button type="button" className="btn" onClick={props.decreaseMonth} disabled={props.date.getMonth() == 0}>
                    <i class="fa fa-chevron-left" aria-hidden="true"></i>
                </button>
            </Col>
            <Col>
                <button className="btn" type="button" disabled>
                    <b>{months[props.date.getMonth()]}</b>
                </button>
            </Col>
            <Col>
                <button type="button" className="btn" onClick={props.increaseMonth} disabled={props.date.getMonth() == 11}>
                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                </button>
            </Col>
        </Row>
    )
}
export default function (props) {
    const programadorState = useSelector(state => state.programadorReducer);
    const [frecuencia, setFrecuencia] = useState(programadorState.tipoFrecuenciaRiego)
    const dispatch = useDispatch();
    return (
        <div>
            {programadorState && <Form
                initialValues={programadorState}
                //saveIn={"calculadoraFormTipoSuelo"}
                validation={(values, formprops) => {
                    //portentajeDerecho
                    if (values.RiegoMeses && values.porcentajePerfilInicio ) {
                        props.setValid(true)
                    }
                    else {
                        props.setValid(false)
                    }
                    if (values.superficieCultivada>0 && values.superficieDerecho>0){
                        values.portentajeDerecho=values.superficieCultivada/values.superficieDerecho*100;
                    }
                    else {
                        values.portentajeDerecho=0;
                    }
                    dispatch({
                        type: "programadorReducer", fn: (state) => ({

                            ...state,
                            ...values,
                        })
                    })
                }}

                components={(formprops) =>
                    <div>
                        <div className="main-icon"><i class="fa fa-calendar"></i></div>
                        <h1>Calendario de Riego</h1>
                        <Row className="justify-content-center">
                            
                            <Col lg="6">
                                <label>Tipo de Frecuencia Riego</label>
                                <Field component={FrecuenciaRiegoInput} name="tipoFrecuenciaRiego" />
                            </Col>
                            <Col lg="6">
                                <label>(%) Porcentaje de agua disponible al inicio de temporada </label>
                                <Field component={NumberInput} name="porcentajePerfilInicio" type="number" min={0} max={100}/>
                            </Col>
                           
                        </Row>
                       
                            {programadorState.tipoFrecuenciaRiego == variable ?
                                <>
                                    <Row>
                                    <Col sm="auto">
                                       Riego exclusivo con pozo
                                    </Col>
                                    <Col sm="auto"> 
                                        <Field 
                                            name="riegoConPozo"  
                                            component={checkbox}
                                            onChange={(value)=>{
                                                    if (value){
                                                        formprops.change("superficieDerecho",programadorState.superficieCultivada)
                                                        formprops.change("portentajeCultivo",100)
                                                    }
                                            }}
                                        
                                        />
                                        </Col>
                                    </Row>
                                    <Row className="mt-1">
                                        
                                        <Col lg="3">
                                        <label>{programadorState.riegoConPozo?"Superficie a irrigar(ha)":"Superficie Cultivada (ha)" }</label>
                                            <Field name="superficieCultivada"  component={NumberInput} type="number" max="100" min="0"  />
                                        </Col>
                                       {!programadorState.riegoConPozo &&   <Col lg="3">
                                        <label>Superficie con derecho (ha)</label>
                                            <Field name="superficieDerecho"  component={NumberInput} type="number" max="100" min="0"  />
                                        </Col>}
                                        {!programadorState.riegoConPozo && 
                                            <Col lg="6">
                                            <label>Porcentaje de Superficie Cultivada que se riega por turno (%)</label>
                                                <Field name="portentajeCultivo" component={NumberInput} type="number" max="100" min="0" /><br />
                                            </Col>
                                        }
                                        
                                        
                                    </Row>
                                    <Field name="RiegoMeses" component={InputRiegoMeses} programador={programadorState} />

                                </>
                                : programadorState.tipoFrecuenciaRiego == constante ?
                                    <>
                                        <Row>
                                        <Col sm="auto">
                                        Riego exclusivo con pozo
                                        </Col>
                                        <Col sm="auto"> 
                                            <Field 
                                                name="riegoConPozo"  
                                                component={checkbox}
                                                onChange={(value)=>{
                                                        if (value){
                                                            formprops.change("superficieDerecho",programadorState.superficieCultivada)
                                                            formprops.change("portentajeCultivo",100)
                                                        }
                                                }}
                                            
                                            />
                                            </Col>
                                        </Row>
                                        <Row className="mt-1">
                                       
                                        <Col lg="3">
                                        <label>{programadorState.riegoConPozo?"Superficie a irrigar(ha)":"Superficie Cultivada (ha)" }</label>
                                            <Field name="superficieCultivada"  component={NumberInput} type="number" max="100" min="0"  />
                                        </Col>
                                        {!programadorState.riegoConPozo &&   <Col lg="3">
                                        <label>Superficie con derecho (ha)</label>
                                            <Field name="superficieDerecho"  component={NumberInput} type="number" max="100" min="0"  />
                                        </Col>}
                                        {!programadorState.riegoConPozo && 
                                            <Col lg="6">
                                            <label>Porcentaje de Superficie Cultivada que se riega por turno (%)</label>
                                                <Field name="portentajeCultivo" component={NumberInput} type="number" max="100" min="0" /><br />
                                            </Col>
                                        }
                                        
                                        </Row>
                                        <Row className="">
                                        <Col lg="4">                                         
                                            <Field name="fechaCortaFin" label="Inicio Temporada de riego" component={DatePicker}
                                                renderCustomHeader={renderCustomHeaderDatePikcer} 
                                                disabled={programadorState.fuenteExtra}/><br />
                                        </Col>
                                        <Col lg="4">                                            
                                            <Field name="fechaCortaInicio"  label="Fin Temporada de riego" component={DatePicker}
                                                renderCustomHeader={renderCustomHeaderDatePikcer} 
                                                disabled={programadorState.fuenteExtra}/>
                                        </Col>
                                                                                
                                        <Col lg="4" className="checkAporte">
                                            <label>Aporte complementario en época de corta</label>
                                            <Field type="checkbox" name="fuenteExtra" component={"input"} />
                                        </Col>
                                       
                                        
                                        </Row>
                                        <Field name="RiegoMeses" component={InputRiegoMesesConstante} programador={programadorState} />
                                    </>
                                    : null}

                            {
                                programadorState.tipoFrecuenciaRiego == automatizado ?
                                    <>
                                        <Row className="">
                                        <Col lg="4">                                               
                                                <Field name="fechaCortaFin" label="Fecha Fin de Corta"  component={DatePicker}
                                                    renderCustomHeader={renderCustomHeaderDatePikcer} 
                                                    disabled={programadorState.fuenteExtra}/><br />
                                            </Col>
                                            
                                            <Col lg="4">
                                               
                                                <Field name="fechaCortaInicio" label="Fecha Inicio de Corta" component={DatePicker}
                                                    renderCustomHeader={renderCustomHeaderDatePikcer} 
                                                    disabled={programadorState.fuenteExtra}/>
                                            </Col>
                                            
                                            <Col lg="4" className="checkAporte">
                                                <label>Aporte complementario en época de corta</label>
                                                <Field type="checkbox" name="fuenteExtra" component={"input"} />
                                            </Col>
                                            
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Field name="RiegoMeses" component={InputRiegoAutomatizado} programador={programadorState} />
                                            </Col>
                                        </Row>
                                    </>
                                    : null
                            }
                        
                    </div>
                }
            >
            </Form>}
        </div>
    )
}
