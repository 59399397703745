import React from 'react'
import { useSelector } from 'react-redux';
import "moment/locale/es";
import GraficoGenerico from "src/app/util/graficoGenerico/GraficoGenerico";
import {Column} from "devextreme-react/data-grid" 

export default function (props){

    const programadorState = useSelector(state => state.programadorReducer);
    const lista1 = programadorState.resultado['datosXDia'];
    const lista2 = programadorState.resultado['datosXMes'];
    const customizeLabel = (e) => {   
        return `${e.valueText} mm`
    }
   console.log(lista2)
  
    return (
        <div >               
                <GraficoGenerico
                 lista={[
                    {datos: lista2, name:"Mes"},
                    {datos:lista1, name: "Día"} 
                ]}
                argumentField="fechaSimple"
                type="bar"
                customizeText={customizeLabel}
                mostrar={!props.mostrar}
                series={[{name:"Eto", valueField:"eto", color:"#A4D7E1"}]}
                renderGridRow={ ()=>[           
                    <Column caption="Fecha" dataField="fechaSimple"/>,
                    <Column
                    caption="Eto (mm)" dataField="eto" alignment="left" cellRender={(row)=> { 
                        return row.data.eto?
                      
                            <label >{ (row.data.eto) }</label>   
                           
                     
                        : ""
                    }}
                    />
                ]}
                />  
        </div>
    )
}
