import React from 'react'
import { useSelector } from 'react-redux';
import "moment/locale/es";
import GraficoGenerico from "src/app/util/graficoGenerico/GraficoGenerico";
import {Column} from "devextreme-react/data-grid" 

export default function (props){

    const programadorState = useSelector(state => state.programadorReducer);
    const lista1 = programadorState.resultado['datosXMes'];

    const customizeLabel = (e) => {   
        return `${e.valueText} mm`
    }

    return (
        <div >
                <GraficoGenerico
                lista={[
                     {datos: lista1, name:"Mes"}
                   
                ]}
                mostrar
                customizeText={customizeLabel}
                argumentField="fechaSimple"
                type="bar"
                series={[{name:"Precipitaciones", valueField:"precipitacion", color:"#A4D7E1"}, { name: "Precipitacíon Período", valueField: "periodoPrecipitacion", color:"#F2AAAA" }]}
                renderGridRow={() => [
                    <Column caption="Fecha" dataField="fechaSimple" />,
                    <Column
                        caption="Precipitaciones (mm)" dataField="precipitacion" alignment="left" cellRender={(row) => {
                            return row.data.precipitacion ? 
                        
                            <label>{Math.round(row.data.precipitacion*10)/10}</label>
                            : ""
                        }}
                    />,
                    <Column caption="Periodo Precipitacíon (mm)" dataField="periodoPrecipitacion" alignment="left"
                    cellRender={(row)=> { 
                        return row.data.periodoPrecipitacion?
                            <label >{ Math.round(row.data.periodoPrecipitacion*10)/10 }</label>    
                        : ""
                    }}
                    />
                ]}
                />  
        </div>
    )
}