import axios from "axios";
import { SET_ERROR } from "./errorReducer";
import { objToQuery } from "../util/lib";
export const reducerName="tipoSueloReducer";
export default function (state = INITIAL_STATE, action){
    if (reducerName!=action.type) return state;
    const newstate=action.fn(state,action);
    if (newstate)return newstate;
    return {...state};
}
const INITIAL_STATE = {
    totalPages:0,
    currentPage:0,
    tipoCultivo:null,
    state:{criteria:{}},
};
const example=undefined;
const apiUrl = "/services/servicioshidricos/api/public";
export const buscarTipoSuelo = (page,size,searchProps,withOutDispatch) => async (dispatch) => {
    const obj = objToQuery({
        page: page,
        size: size,
        ...searchProps,
    });
    try {
        const res=await axios.get(`${apiUrl}/tipo-de-suelos${obj}`)
        let cantTotal = parseInt(res.headers["x-total-count"]);
        const result={
            tipoSuelo: example || res.data,
            currentPage: page,
            totalPages: Math.ceil(cantTotal / size),
        };
        
        if (withOutDispatch){
            return result
        }
        else {
            dispatch({
                type: reducerName,
                payload:result,
                fn: (state,action) => ({
                    ...state,
                    ...action.payload
                })
            })   
        }

    }  
    catch(error){
        console.error(error)
        if (error.response){ 
            dispatch({ type: SET_ERROR, payload: error.response });
        }
        throw error;
    }
}


