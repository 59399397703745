import React,{useState,useEffect} from "react";
export const variable="variable";
export const constante="constante"
export const automatizado="automatizado"
export default function FrecuenciaRiegoInput(props){
    useEffect(()=>{
        if (!props.input.value){
            props.input.onChange(constante);//default value
        }
    },[props.input.value]);
    const onChange=(e)=>{
        const value=e.target.value;
        props.input.onChange(value);
    }
    return (
        <select value={props.input.value} onChange={onChange}>
            <option value={variable}>
                Variable
            </option>
            <option value={constante}>
                Fijo
            </option>
            <option value={automatizado}>
                Automatizado
            </option>
        </select>
    )
}