import React, { useState, useEffect } from "react";
import Tooltip from "../tooltips/tooltips"
import "../tooltips/tooltips.css"
import { ButtonGroup,Button} from "reactstrap";
//import { checkPermiso } from "./Permisos";
export default function (props){
    return (
        <ButtonGroup>
            { props.go.map((item,index)=>{ 
                const elem=typeof item=="function"?item(props.data,index):item;
                if (!elem)return null;
                if (elem.hidden)return null;
                //if (elem.permission && !checkPermiso(elem.permission))return null;
                if (elem.component){
                    return <elem.component data={props.data} key={index}/>
                }
                if (elem.tooltip){
                    return (
                    <Button 
                    type="button" 
                    key={index} 
                    data-toggle="tooltip" 
                    data-placement="left" 
                    title={elem.tooltip} 
                    onClick={props.waitFor && elem.waitEnd? props.waitFor(elem.go,props.data) : ()=>elem.go(props.data)}><i className={elem.icon} /></Button>
                        
                    )
                }
                else {
                    return (
                        <Button type="button" key={index} onClick={props.waitFor && elem.waitEnd? props.waitFor(elem.go,props.data) : ()=>elem.go(props.data)}>
                            <i className={elem.icon}/>
                        </Button>
                    )
                   
                }
            })}
        </ButtonGroup>
        
    )
}