import React, { useEffect,useState} from "react";
import { useDispatch } from "react-redux";
import SelectSearch from "src/app/util/SelectSearch/SelectSearch";
import {buscarDistrito} from "src/app/reducers/distritoReducer"
// for (const option of options){
//   for (let i=0;i<365;i++){
//     option.eto.push({
//       dia: new Date((new Date("01/01/2022"))*1 + i*24*60*60*1000 ),
//       eto: 1,
//     })
//   }
// }
export default function (props){
  const dispatch=useDispatch();
  return (
    <SelectSearch
      popupButton
      pageSize={10}
      input={props.input}
      keyId={"id"}
      label="Distrito"
      getName={(item)=>item.id+"-"+item.nombre}
      getOptions={async (string,page)=>{
        const result=await dispatch(buscarDistrito(page,10,{"nombre.contains":string},true))
        //return result.distrito;
        return {
          ...result,
          data:result.distrito,
        };
      }}
      rescueOption={async (obj)=>{
        const result=await dispatch(buscarDistrito(0,1,{"id.equals":obj.id},true))
        return result.distrito[0];
      }}
      placeHolder="Seleccione un Distrito"
    />
  )
}