import React, { useState } from "react";
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Row,
    Button
  } from 'reactstrap';
 import Criterial from  "../criterial/criterialInput.jsx"
 import  GenericForm,{Field} from "../form/form.jsx"
export default function Formulario(props){
    const criterialprops={};
    const formprops={};
    for (const propkey in props){
        const propvalue=props[propkey];
        const subprops=propkey.split("-");
        if (subprops.length>1 && subprops[0]=="criteria"){
            criterialprops[subprops[1]]=propvalue;
        }
        else {
            formprops[propkey]=propvalue;
        }
    }
    const {onSubmit,...otherformprops}=formprops
    return (
        <div>
        <GenericForm 
        onSubmit={(values)=>onSubmit(values.criteria)}
        {...otherformprops}
        components={()=>
            <Row>
            <Col className="mt-2" className="option-Emisiones">
                    <Field 
                     label="Ingresar"
                     name="criteria"
                     className="emision-form"
                     component={Criterial}
                     {...criterialprops}
                    />                       
            </Col>
            </Row>}     
            
                />
        </div>
    )
}
