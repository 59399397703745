import React,{useState,useEffect} from "react";
import { Col, Row } from "react-bootstrap";

const months = [
    {
        name: "Agosto",
        mesIndex: 7
    },
    {
        name: "Septiembre",
        mesIndex: 8
    },
    {
        name: "Octubre",
        mesIndex: 9
    },
    {
        name: "Noviembre",
        mesIndex: 10
    },
    {
        name: "Diciembre",
        mesIndex: 11
    },

    {
        name: "Enero",
        mesIndex: 0
    },
    {
        name: "Febrero",
        mesIndex: 1
    },
    {
        name: "Marzo",
        mesIndex: 2
    },
    {
        name: "Abril",
        mesIndex: 3
    },
    {
        name: "Mayo",
        mesIndex: 4
    },
    {
        name: "Junio",
        mesIndex: 5
    },
    {
        name: "Julio",
        mesIndex: 6
    }];

function getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
}
export default function InputRiegoAutomatizado(props){
    const [refreshs,setRefreshs]=useState(0)
    let cortainicio=new Date(props.programador.fechaCortaInicio);
    cortainicio.setFullYear(2024)
    let cortafin= new Date(props.programador.fechaCortaFin)
    cortafin.setFullYear(2024)
    useEffect(()=>{
        let laminaDisponible=props.programador.resultado.humedadUtil*props.programador.porcentajePerfilInicio/100;
        props.programador.resultado.datosXDia.forEach((d,i)=>{
            d.autoLaminaAplicada= 0;
            if (laminaDisponible-d.etc+d.precipitacion < props.programador.resultado.laminaEntreURyPMP && 
                (props.programador.fuenteExtra || 
                    (cortainicio<cortafin && (cortainicio > d.fecha || d.fecha > cortafin) || (cortafin < d.fecha && d.fecha < cortainicio)) )){
                d.autoHuboRiego=true;
                d.autoLaminaAplicada= props.programador.resultado.humedadUtil-laminaDisponible-d.precipitacion
                //console.log("Riego automatizado",d.autoLaminaAplicada,d.fecha,"disponibleInicioDia",laminaDisponible)
            }
            laminaDisponible=Math.min(laminaDisponible+d.autoLaminaAplicada-d.etc+d.precipitacion,props.programador.resultado.humedadUtil*1);
        })
        let a=[]
        props.programador.resultado.datosXDia.forEach((d)=>{
            const mes = new Date(new Date(d.fecha)).getMonth();
            const elem=a[mes]=a[mes] || {cantidadRiegos:0,lamina:0, ultimoRiego:new Date(a.fecha),intervalo:0}
            if (d.autoHuboRiego){
                //console.log(elem.lamina,elem.cantidadRiegos,d.autoLaminaAplicada)
                elem.lamina=(elem.lamina*elem.cantidadRiegos+d.autoLaminaAplicada)/(elem.cantidadRiegos+1);
                elem.cantidadRiegos++;
            }
        });
        props.input.onChange(a.map((e,index)=>({
            lamina:e.lamina,
            inputLamina:e.lamina,
            intervalo:e.cantidadRiegos && getDaysInMonth(2024,index+1)/e.cantidadRiegos,
            inputIntervalo:e.cantidadRiegos && getDaysInMonth(2024,index+1)/e.cantidadRiegos,
            mesIndex:index
        })))
    },[props.programador.fechaCortaInicio,props.programador.fechaCortaFin,props.programador.fuenteExtra,props.programador.porcentajePerfilInicio]);
    return (
        <>
        <Row><Col className="resultadoBgGris">
                <Row>
                    <Col lg="3">
                        Mes
                    </Col>
                    <Col lg="3" align="center">
                        Frecuencia de riego
                    </Col>
                    <Col lg="3" align="center">
                        Lámina (mm)
                    </Col>
                    <Col lg="3" align="center">
                        (m3)
                    </Col>
                </Row>
                <Row><Col><hr/></Col></Row>
                {props.input.value && months.map((mes,i)=>{
                    const value=props.input.value.find(e => e.mesIndex === mes.mesIndex);
                    
                    if (value)return <Row key={i}>
                            <Col lg="3">
                                {mes.name}
                            </Col>
                            <Col lg="3" align="center">
                               
                                    {parseInt(value.intervalo)}
                            </Col>
                            <Col lg="3" align="center">
                                    {parseInt(value.lamina)}
                            </Col>
                            <Col lg="3" align="center">
                                    {parseInt(value.lamina)*10}
                            </Col>
                        
                        </Row>
                 
                })}
           </Col></Row> 
        </>
    )
}