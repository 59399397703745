import { combineReducers } from 'redux';
import { reducer as reduxForm } from 'redux-form';
import errorReducer from './errorReducer';
import programadorReducer from './programadorReducer';
import estacionesReducer from './estacionesReducer';
import sistemaDeRiegoReducer from './sistemaDeRiegoReducer';
import distritoReducer from './distritoReducer';
import cuencaReducer from './cuencaReducer';
import departamentoReducer from './departamentoReducer';
import tipoDeCultivoReducer from './tipoDeCultivoReducer';
import usoCulturalReducer from './usoCulturalReducer';
export default combineReducers({
  form: reduxForm,
  errorReducer,
  programadorReducer,
  estacionesReducer,
  sistemaDeRiegoReducer,
  distritoReducer,
  departamentoReducer,
  cuencaReducer,
  tipoDeCultivoReducer,
  usoCulturalReducer,
});