import React, { useEffect,useState} from "react";
import { useDispatch } from "react-redux";
import SelectSearch from "src/app/util/SelectSearch/SelectSearch";
import {buscarDepartamento} from "src/app/reducers/departamentoReducer"

export default function (props){
  const dispatch=useDispatch();
  return (
    <SelectSearch
    popupButton
    pageSize={10}
    input={props.input}
    keyId={"id"}
    label="Departamento"
    getName={(item)=>item.id+"-"+item.nombre}
    getOptions={async (string, page)=>{
      const result=await dispatch(buscarDepartamento(page,10,{"nombre.contains":string},true))
      //return result.departamento;
      return {
        ...result,
        data:result.departamento,
      };
    }}
    rescueOption={async (obj)=>{
      const result=await dispatch(buscarDepartamento(0,1,{"id.equals":obj.id},true))
      return result.departamento[0];
    }}
    placeHolder="Seleccione un Departamento"
  />
  )
}