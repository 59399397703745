import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Button, Modal } from 'react-bootstrap'
import TablaResultado from '../graficos/TablaResultados'
import GraficoResultado from "./GraficoResultado";
import { setClean } from '../../reducers/programadorReducer'
import { useHistory } from "react-router-dom";
import 'src/styles.css'
import './resultadoStyle.css'

function Resultado(props) {
    const lista = props.lista
    const [mm, setMm] = useState(true)
    const [lm2, setlm2] = useState(true)
    const [m3, setm3] = useState(true)
    return (
        <div className="nN">
            <h6 className="mb-0">Requerimiento total de riego:</h6>

            {((
                (lista.totalPeriodo.nN)  + lista.totalPeriodo.perdidaLixiviacion +
                lista.totalPeriodo?.totalDefensaHelada +
                lista.totalPeriodo?.totalPrepTerre +
                lista.totalPeriodo?.totalLexiviacion +
                lista.totalPeriodo?.totalLavadoSuelo)).toFixed(0) * (mm ? 1 : m3 ? 10 : 1)}{" "}
            {mm ? "mm" : m3 ? "m3/ha" : "l/m2"}

            <h6 className="mb-0">Demanda Total:</h6>

            {((
                lista.totalPeriodo.etc + lista.totalPeriodo.perdidaLixiviacionSP +
                lista.totalPeriodo?.totalDefensaHeladaSP +
                lista.totalPeriodo?.totalPrepTerreSP +
                lista.totalPeriodo?.totalLexiviacionSP +
                lista.totalPeriodo?.totalLavadoSueloSP)).toFixed(0) * (mm ? 1 : m3 ? 10 : 1)}{" "}
            {mm ? "mm" : m3 ? "m3/ha" : "l/m2"}
            <br></br>
            {mm ?
                <Button type="button" className="btn btn-light" onClick={() => { setMm(!mm) }}>{mm && "Convertir a m3/ha"}</Button>
                :
                m3 ?

                    <Button type="button" className="btn btn-light" onClick={() => { setm3(!m3) }}>{m3 && "Convertir a l/m2"}</Button>

                    :
                    <Button type="button" className="btn btn-light" onClick={() => { setMm(!mm); setm3(!m3) }}>{lm2 && "Convertir a mm"}</Button>

            }
        </div>

    )
}

export default function (props) {
    const [clear, setClear] = useState(false);
    const history = useHistory()
    const dispatch = useDispatch()
    props.setValid(true)
    const programadorState = useSelector(state => state.programadorReducer);
    const estaciones = programadorState.estacionesMeteorologicas;
    const data = programadorState;
    const lista = programadorState.resultado;
    const tipoAnio = programadorState.precipitaciones;
    const removeLocalStorage = () => {
        dispatch(setClean())
            .then(() => {
                props.goTo(1);
            });
    }
    const resultTipoAnio = tipoAnio?.reduce(function (r, a) {
        return a.tipoAnio
    }, []);
    const [modalShow, setModalShow] = React.useState(false);

    function MyModal(props) {
        return (
            <Modal
                size="md"
                {...props}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <p>
                    Una vez cargado todos los parámetros requeridos, 
                    el sistema calculará la <b>Demanda total</b>, la cual 
                    constituye la necesidad del cultivo sin considerar 
                    el aporte de agua por la precipitación y los 
                    <b>Requerimientos de Riego</b>, donde si se tienen en cuenta 
                    los aportes por precipitación. También se mostrará un resumen 
                    de los datos cargados que fueron ingresados para llegar a 
                    dichos resultados y al igual que en los pasos anteriores es 
                    posible ver y descargar los datos en forma de grafico o de tabla, 
                    eligiendo en este caso si se quieren ver los correspondientes 
                    a demanda o requerimiento.
                    </p>
                </Modal.Body>
            </Modal>
        );
    }
    return (
        <div className="res">
            <Row>
                <Col lg={3} md={12} className="result-list">
                    <h2>RESULTADO
                        <button variant="primary" onClick={() => setModalShow(true)} className="btn-light info btn btn-secondary mb-0" >
                            <i className={"fa fa-info-circle "}></i>
                        </button>
                        <MyModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        />
                    </h2>
                    <Resultado lista={lista} />
                    <ul>
                        <li><strong>Ubicación: </strong>{data.tipoDeUbicacion?.nombre} - {data.tipoDeUbicacion?.nombre === "Departamento" && data.departamento?.Departamento?.nombre}
                            {data.tipoDeUbicacion?.nombre === "Distrito" && data.departamento?.Distrito?.nombre}
                            {data.tipoDeUbicacion?.nombre === "EM" && data.departamento?.EstaciónMeteorológica?.nombre}
                            {data.tipoDeUbicacion?.nombre === "Cuenca" && data.departamento?.Cuenca?.nombre}</li>
                        <li><strong>Estación correspondiente: </strong>{estaciones?.map(d => (
                            <div>{d.nombreEstacion}</div>
                        ))}
                        </li>
                        <li><strong>Fechas: </strong>{new Date(data.fechaInicio).toLocaleDateString(undefined, { month: "numeric", day: "numeric", })} - {new Date(data.fechaFin).toLocaleDateString(undefined, { month: "numeric", day: "numeric", })}</li>

                        <li><strong>Cultivo:</strong> {data.tipoCultivo?.nombre ? data.tipoCultivo?.nombre : "Personalizado"}</li>
                        <li><strong>Precipitación: </strong>{resultTipoAnio || "Ninguno"}</li>
                        <li><strong>Precip. Anual: </strong>{data.resultado.totalAnual.precipitacion + " " + "mm"}</li>
                        <li><strong>Precip. Período: </strong>{data.resultado.totalPeriodo.precipitacion + " " + "mm"}</li>
                        <li><strong>Sistema de riego:</strong> {data.tipoRiego?.descripcion}</li>
                        <li><strong>Eficiencia:</strong> {data.eficiencia * 100 + " " + "%"}</li>
                        <li><strong>Total Etc:</strong> {lista.totalPeriodo?.etc?.toFixed(0) + " " + "mm"}</li>
                        <li><strong>Total Eto:</strong> {lista.totalPeriodo?.eto?.toFixed(0) + " " + "mm"}</li>
                        <li>{lista.totalPeriodo?.totalDefensaHelada ? <><strong>Lámina helada:</strong>{lista.totalPeriodo?.totalDefensaHelada + " " + "mm"}</> : ""}</li>
                        <li>{lista.totalPeriodo?.totalPrepTerre ? <><strong>Lámina Prep. terreno:</strong> {lista.totalPeriodo?.totalPrepTerre + " " + "mm"}</> : ""}</li>
                        <li>{lista.totalPeriodo?.totalLavadoSuelo ? <><strong>Lámina lavado:</strong> {lista.totalPeriodo?.totalLavadoSuelo + " " + "mm"}</> : ""}</li>
                        <li>{lista.totalPeriodo?.totalLexiviacion ? <><strong>Lixiviación:</strong> {lista.totalPeriodo?.totalLexiviacionSP + " " + "mm"} </> : ""}</li>
                        <li>{lista.totalPeriodo?.totalLexiviacion ? <><strong>Lixiviación Con Precipitación:</strong> {lista.totalPeriodo?.totalLexiviacion + " " + "mm"} </> : ""}</li>

                    </ul>
                    <button type="button" className="btn btn-success" onClick={removeLocalStorage}>Volver a empezar</button>
                </Col>
                <Col lg={9} md={12}>
                    <GraficoResultado />
                </Col>
            </Row>


        </div>
    )
}
