import React, { useState,useEffect } from "react";
import { FormGroup } from "reactstrap";
export default function NumberInput(props){
    return <FormGroup>
        
        {props.caption && <label>{props.caption}</label>}
        <input 
        {...props}
        className="form-control"
        value={props.input.value==undefined?"":props.input.value}
        onKeyDown={(e)=>{
            if (e.key=="e" || e.key=="-" || e.key=="+"){
                e.preventDefault()
                return false;
            }
        }}
        onChange={(e)=>{
            if ((e.target.value * 1).toString()=="NaN") {
                
                e.preventDefault()
                console.log("invalid number")
                return false;
            }
            else if (props.min>(e.target.value * 1)){
                e.preventDefault()
                console.log("invalid number")
                return false;
            }
            else if (props.max<(e.target.value * 1)){
                e.preventDefault()
                console.log("invalid number")
                return false;
            }
            else{ 
                props.input.onChange(e.target.value)
            
            }
        }}
    />
    </FormGroup>
}