import React, { useState, useSelector } from 'react'
import GraficoGenerico from "src/app/util/graficoGenerico/GraficoGenerico";
const data = {
  Humedad: {
    title: "Abatimiento de humedad del suelo",
    series: [
      { name: "Humedad Util", valueField: "humedadUtil", type: "area", color: "#C69B7B" },
      { name: "Lámina Aplicada (mm)", valueField: "anualLaminaDisponible", type: "area", color: "#1363DF" },
      { name: "URyPMP", valueField: "laminaEntreURyPMP", type: "line", color: "#FF9F29" },
      { name: "Percolación (mm)", valueField: "percolacion", type: "area", color: "#47B5FF" },
    ]
  },
  Eficiencia: {
    title: "Eficiencia",
    series: [
      { name: "Eficiencia", valueField: "eficiencia", type: "scatter", color: "#9ED2C6" },
    ]
  },
  Acumulado: {
    title: "Demanda y Déficit acumulado",
    series: [
      { name: "Demanda", valueField: "etcAcumulada", type: "area", color: "#63c2de" },
      { name: "Déficit", valueField: "deficitAcumulada", type: "area", color: "#f86c6b" },

    ]
  }
}
const GraficoResultado2 = (props) => {
  const lista1 = props.programadorState.resultado.datosXDia;
  const [select, setSelect] = useState("Humedad");
  const options = [
    { datos: lista1, name: "Humedad" },
    { datos: lista1, name: "Eficiencia" },
    { datos: lista1, name: "Acumulado" }
  ]
  const metadataOption = data[select];
  return (
    <div>
      <GraficoGenerico
        lista={options}
        argumentField={"fechaSimple"}
        //valueField="valueField"
        onChangeSelect={(value) => {
          setSelect(options[value].name)
        }}
        title={metadataOption.title}
        series={metadataOption.series}
      >
      </GraficoGenerico>
    </div>
  )
}

export default GraficoResultado2