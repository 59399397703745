import React, { useEffect,useState} from "react";
import { useDispatch } from "react-redux";
import {buscarUsoCultural} from "src/app/reducers/usoCulturalReducer";
import SelectSearch from "src/app/util/SelectSearch/SelectSearch";
import {Col} from 'react-bootstrap'

export default function (props){
  const dispatch=useDispatch();
  return (
    <div>
      <SelectSearch
        input={props.input}
        keyId={"id"}
        label="Tipo De Uso"
        getName={(item)=>item.id+"-"+item.nombre}
        getOptions={async (string)=>{
          const criteria=props.criteria ||  {};
          const result=await dispatch(buscarUsoCultural(0,10,{"nombre.contains":string,...criteria},true))
          return result.usoCultural;
        }}
        rescueOption={async (obj)=>{
          const result=await dispatch(buscarUsoCultural(0,1,{"id.equals":obj.id},true))
          return result.usoCultural[0];
        }}
        placeHolder="Seleccione un Tipo de uso"
        forceGetOptions={JSON.stringify(props.criteria)}
      />
    </div>
  )
}